import IWarehouse from './IWarehouse';
import IWarehouseEligibleLoan from './IWarehouseEligibleLoan';
import IWarehouseLoan from './IWarehouseLoan';
import IWarehouseLoanTransaction from './IWarehouseLoanTransaction';
import IWarehouseParameter from './IWarehouseParameter';
import IWarehouseTransaction from './IWarehouseTransaction';
import WarehouseTypeEnum from './WarehouseTypeEnum';
import IWarehouseProposedSale from './IWarehouseProposedSale';
import IWarehousesPortfolioMetrics from './IWarehousesPortfolioMetrics';
import IWarehouseProposedSaleDestination from './IWarehouseProposedSaleDestination';
import WarehouseProposedSaleStatusEnum from './WarehouseProposedSaleStatusEnum';
import IWarehouseActiveReportLoan from './IWarehouseActiveReportLoan';
import LoanPurposeEnum from '~Api/Deal/LoanPurposeEnum';
import PropertyStateEnum from '~Api/Deal/PropertyStateEnum';
import RiskBandEnum from '~Api/Deal/RiskBandEnum';
import ZoneTypeEnum from '~Api/Deal/ZoneTypeEnum';
import { parseLoan } from '~Api/Loan/parsers';
import WarehouseParameterFormatEnum from './WarehouseParameterFormatEnum';
import WarehouseParameterLimitTypeEnum from './WarehouseParameterLimitTypeEnum';
import ILoanBookForecastDay from './ILoanBookForecastDay';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseWarehouse(raw: any): IWarehouse {
    return {
        activeLoanCount: raw.activeLoanCount,
        name: raw.name,
        reservedApplications: raw.reservedApplications,
        reservedPurchases: raw.reservedPurchases,
        reservedSales: raw.reservedSales,
        retainedContingency: raw.retainedContingency,
        retainedFunds: raw.retainedFunds,
        totalBalanceInterest: raw.totalBalanceInterest,
        totalBalancePrepaidInterest: raw.totalBalancePrepaidInterest,
        totalBalancePrincipal: raw.totalBalancePrincipal,
        totalCapital: raw.totalCapital,
        totalReserved: raw.totalReserved,
        type: raw.type as WarehouseTypeEnum,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseWarehouseEligibleLoan(raw: any): IWarehouseEligibleLoan {
    return {
        ineligibility: raw.ineligibility,
        isEligible: raw.isEligible,
        loan: parseLoan(raw.loan),
        loanUuid: raw.loanUuid,
        warehouseLoans: raw.warehouseLoans.map(parseWarehouseLoan),
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseWarehouseLoan(raw: any): IWarehouseLoan {
    return {
        balanceInterest: raw.balanceInterest,
        balancePrincipal: raw.balancePrincipal,
        loan: raw.loan ? parseLoan(raw.loan) : null,
        loanUuid: raw.loanUuid,
        uuid: raw.uuid,
        warehouseUuid: raw.warehouseUuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseWarehouseParameter(raw: any): IWarehouseParameter {
    return {
        actual: raw.actual,
        format: raw.format as WarehouseParameterFormatEnum,
        limit: raw.limit,
        limitType: raw.limitType as WarehouseParameterLimitTypeEnum || WarehouseParameterLimitTypeEnum.Maximum,
        name: raw.name,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseWarehouseLoanTransaction(raw: any): IWarehouseLoanTransaction {
    return {
        amount: raw.amount,
        amountInterest: raw.amountInterest,
        amountPrincipal: raw.amountPrincipal,
        description: raw.description,
        loanCode: raw.loanCode,
        loanUuid: raw.loanUuid,
        transactionTime: raw.transactionTime,
        uuid: raw.uuid,
        warehouseLoanUuid: raw.warehouseLoanUuid,
        warehouseUuid: raw.warehouseUuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseWarehouseProposedSale(raw: any): IWarehouseProposedSale {
    const warehouseProposedSaleDestinations: IWarehouseProposedSaleDestination[] = raw.warehouseProposedSaleDestinations.map(parseWarehouseProposedSaleDestination);

    return {
        sourceWarehouseLoan: raw.sourceWarehouseLoan,
        status: raw.approvalStatus as WarehouseProposedSaleStatusEnum,
        transactionTime: raw.transactionTime,
        uuid: raw.uuid,
        warehouseProposedSaleDestinations,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseWarehouseProposedSaleDestination(raw: any): IWarehouseProposedSaleDestination {
    return {
        amount: raw.amount,
        uuid: raw.uuid,
        warehouseProposedSaleUuid: raw.warehouseProposedSaleUuid,
        warehouseUuid: raw.warehouseUuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseWarehouseActiveReportLoan(raw: any): IWarehouseActiveReportLoan {
    return {
        amount: raw.amount,
        applicationUuid: raw.applicationUuid,
        extensionNumber: raw.extensionNumber,
        interestRate: raw.interestRate,
        loanPurpose: raw.loanPurpose as LoanPurposeEnum,
        loanUuid: raw.loanUuid,
        lvr: raw.lvr,
        propertyCategory: raw.propertyCategory as RiskBandEnum,
        propertyLocation: raw.propertyLocation as PropertyStateEnum,
        propertyType: raw.propertyType as ZoneTypeEnum,
        termMonths: raw.termMonths,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseWarehouseTransaction(raw: any): IWarehouseTransaction {
    return {
        aNotes: raw.aNotes,
        amountPrincipal: raw.amountPrincipal,
        capacityLimit: raw.capacityLimit,
        description: raw.description,
        interestRate: raw.interestRate,
        prefund: raw.prefund,
        principalDraw: raw.principalDraw,
        sellerNotes: raw.sellerNotes,
        transactionTime: raw.transactionTime,
        uuid: raw.uuid,
        xNotes: raw.xNotes,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseLoanBookForecastDay(raw: any): ILoanBookForecastDay {
    return {
        date: raw.date,
        discharges: raw.discharges,
        dischargesBooked: raw.dischargesBooked,
        loanBook: raw.loanBook,
        loanBookDischargesBooked: raw.loanBookDischargesBooked,
        settlements: raw.settlements,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseWarehousesPortfolioMetrics(raw: any): IWarehousesPortfolioMetrics {
    return {
        actualYield: raw.actualYield,
        availableFunds: raw.availableFunds,
        costOfFunds: raw.costOfFunds,
        loanBookPerforming: raw.loanBookPerforming,
        optimalYield: raw.optimalYield,
        retainedContingency: raw.retainedContingency,
        retainedFunds: raw.retainedFunds,
        totalAmountRemaining: raw.totalAmountRemaining,
        totalFunds: raw.totalFunds,
        totalLoanBook: raw.totalLoanBook,
        totalWeightedInterestRateValueActual: raw.totalWeightedInterestRateValueActual,
        totalWeightedInterestRateValueOptimal: raw.totalWeightedInterestRateValueOptimal,
        totalWeightedLvrValue: raw.totalWeightedLvrValue,
        warehouseType: raw.warehouseType,
        warehouseUuid: raw.warehouseUuid,
        weightedLvr: raw.weightedLvr,
    };
}
