import { Alert, Button, Descriptions, Dropdown, Form, MenuProps, Modal, Select, Space, Spin, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IApplication from '~Api/Application/IApplication';
import {
    applicationGetAction,
    applicationSolicitorInstructionsSentTimeAction,
    applicationValueSetAction,
} from '~Applications/actions';
import { applicationSelector } from '~Applications/selectors';
import { IGlobalState } from '~reducer';
import { solicitorsListAction } from '~Solicitors/actions';
import ISolicitor from '~Solicitors/ISolicitor';
import { solicitorsSelector } from '~Solicitors/selectors';
import DatePicker from '~UI/DatePicker';
import TimePicker from '~UI/TimePicker';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';

interface IDefaultedValues {
    instructionsSentDateTime: string;
}

interface IState {
    applicationSolicitorUuid: string;
    dirtyFields: IDictionary<boolean>;
    instructionsSentDateTime: string;
    isApplicationSolicitorModalOpen: boolean;
    isSendInstructionsModalOpen: boolean;
}

interface IMatch {
    applicationUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    application: IApplication;
    solicitors: IDictionary<ISolicitor>;
}

interface IPropsDispatch {
    applicationGet: () => void;
    applicationSolicitorInstructionsSentTime: (instructionsSentTime: string) => void;
    applicationValueSet: (key: keyof IApplication, value: boolean|number|string) => void;
    solicitorsList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class SolicitorInstructions extends React.Component<Props> {
    public state: IState = {
        applicationSolicitorUuid: null,
        dirtyFields: {},
        instructionsSentDateTime: null,
        isApplicationSolicitorModalOpen: true,
        isSendInstructionsModalOpen: false,
    };

    constructor(props: Props) {
        super(props);

        this.getDefaultedValues = this.getDefaultedValues.bind(this);

        this.onChangeApplicationSolicitor = this.onChangeApplicationSolicitor.bind(this);
        this.onClickApplicationSolicitor = this.onClickApplicationSolicitor.bind(this);
        this.onClickApplicationSolicitorCancel = this.onClickApplicationSolicitorCancel.bind(this);
        this.onClickApplicationSolicitorOk = this.onClickApplicationSolicitorOk.bind(this);

        this.onChangeSendInstructionsDate = this.onChangeSendInstructionsDate.bind(this);
        this.onChangeSendInstructionsTime = this.onChangeSendInstructionsTime.bind(this);
        this.onClickSendInstructions = this.onClickSendInstructions.bind(this);
        this.onClickSendInstructionsCancel = this.onClickSendInstructionsCancel.bind(this);
        this.onClickSendInstructionsOk = this.onClickSendInstructionsOk.bind(this);
    }

    public componentDidMount() {
        const { application, solicitors } = this.props;

        if (!application) {
            this.props.applicationGet();
        }

        if (!solicitors) {
            this.props.solicitorsList();
        }
    }

    public render(): JSX.Element {
        const {
            application,
            match,
            solicitors,
        } = this.props;
        const {
            applicationSolicitorUuid,
            isApplicationSolicitorModalOpen,
            isSendInstructionsModalOpen,
        } = this.state;

        if (!application || !solicitors) {
            return (
                <Layout applicationUuid={match.params.applicationUuid} section='solicitor-instructions'>
                    <Spin/>
                </Layout>
            );
        }

        if (!application.solicitorUuid) {
            return (
                <Layout applicationUuid={match.params.applicationUuid} section='solicitor-instructions'>
                    <Typography.Title level={2}>Solicitors Instructions</Typography.Title>
                    <p>Please choose a solicitor.</p>

                    <Modal
                        destroyOnClose={true}
                        okText='Confirm'
                        onCancel={this.onClickApplicationSolicitorCancel}
                        onOk={this.onClickApplicationSolicitorOk}
                        open={isApplicationSolicitorModalOpen}
                        title='Application Solicitor'
                        wrapClassName='solicitor-modal'
                    >
                        <Form.Item label='Application Solicitor' className='application-solicitor'>
                            <Select onChange={this.onChangeApplicationSolicitor} value={applicationSolicitorUuid}>
                                {_.map(solicitors, (loopSolicitor: ISolicitor) => (
                                    <Select.Option key={loopSolicitor.uuid} value={loopSolicitor.uuid}>{loopSolicitor.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Modal>
                </Layout>
            );
        }

        const { instructionsSentDateTime } = this.getDefaultedValues();

        const solicitor: ISolicitor = solicitors[application.solicitorUuid];
        const instructionsSentDateTimeDayJs: Dayjs = instructionsSentDateTime ? dayjs(instructionsSentDateTime) : null;

        const actionsMenu: MenuProps = {
            items: [
                {
                    key: 'download-documents',
                    label: <a href={`${process.env.API_HOST}/applications/${application.uuid}/condition-documents/zip`} rel='noreferrer' target='_blank'>Download Documents</a>,
                },
            ],
        };
        const sendInstructionsButton: JSX.Element = !application.isExtension ? (
            <Dropdown.Button onClick={this.onClickSendInstructions} menu={actionsMenu}>Send Instructions</Dropdown.Button>
        ) : (
            <Button onClick={this.onClickSendInstructions}>Send Instructions</Button>
        );

        return (
            <Layout applicationUuid={match.params.applicationUuid} section='solicitor-instructions'>
                <Space className='actions'>
                    {instructionsSentDateTimeDayJs && <span>Instructions Sent: {instructionsSentDateTimeDayJs.format('DD/MM/YYYY H:mm')}</span>}
                    {sendInstructionsButton}
                </Space>
                <Typography.Title level={2}>Solicitors Instructions</Typography.Title>
                <Descriptions size='small' column={3} bordered={true} layout='vertical'>
                    <Descriptions.Item label='Solicitor Name'>{solicitor.name}</Descriptions.Item>
                    <Descriptions.Item label='Email'>{solicitor.email}</Descriptions.Item>
                    <Descriptions.Item label='Phone'>{solicitor.phone}</Descriptions.Item>
                </Descriptions>

                <Modal
                    destroyOnClose={true}
                    okText='Send Instructions'
                    onCancel={this.onClickSendInstructionsCancel}
                    onOk={this.onClickSendInstructionsOk}
                    open={isSendInstructionsModalOpen}
                    title='Send Instructions'
                    wrapClassName='solicitor-send-instructions-modal'
                >
                    <Alert
                        description='This feature is for recording that instructions have been sent, it does not send the instructions for you.'
                        message='Please Note'
                        showIcon={true}
                        type='info'
                    />
                    <Form.Item className='instructions-sent' label='Instructions Sent'>
                        <Space>
                            <DatePicker onChange={this.onChangeSendInstructionsDate} value={instructionsSentDateTimeDayJs || undefined} format='DD/MM/YYYY' />
                            <TimePicker onChange={this.onChangeSendInstructionsTime} value={instructionsSentDateTimeDayJs || undefined} format='H:mm' />
                        </Space>
                    </Form.Item>
                </Modal>
            </Layout>
        );
    }

    private getDefaultedValues(): IDefaultedValues {
        const { application } = this.props;
        const { dirtyFields, instructionsSentDateTime } = this.state;

        return {
            instructionsSentDateTime: dirtyFields.instructionsSentDateTime ? instructionsSentDateTime : application.solicitorInstructionsSentTime,
        };
    }

    private onChangeApplicationSolicitor(value: string) {
        this.setState({
            applicationSolicitorUuid: value,
        });
    }

    private onClickApplicationSolicitor() {
        this.setState({
            isApplicationSolicitorModalOpen: true,
        });
    }

    private onClickApplicationSolicitorCancel() {
        this.setState({
            isApplicationSolicitorModalOpen: false,
        });
    }

    private onClickApplicationSolicitorOk() {
        const { applicationSolicitorUuid } = this.state;

        this.props.applicationValueSet('solicitorUuid', applicationSolicitorUuid);
        this.onClickApplicationSolicitorCancel();
    }

    private onChangeSendInstructionsDate(date: Dayjs) {
        const { dirtyFields, instructionsSentDateTime } = this.state;

        this.setState({
            dirtyFields: {
                ...dirtyFields,
                instructionsSentDateTime: true,
            },
            instructionsSentDateTime: dayjs(`${date.format('YYYY-MM-DD')} ${dayjs(instructionsSentDateTime || undefined).format('HH:mm')}`).format(),
        });
    }

    private onChangeSendInstructionsTime(time: Dayjs) {
        const { dirtyFields, instructionsSentDateTime } = this.state;

        this.setState({
            dirtyFields: {
                ...dirtyFields,
                instructionsSentDateTime: true,
            },
            instructionsSentDateTime: dayjs(`${dayjs(instructionsSentDateTime || undefined).format('YYYY-MM-DD')} ${time.format('HH:mm')}`).format(),
        });
    }

    private onClickSendInstructions() {
        this.setState({
            isSendInstructionsModalOpen: true,
        });
    }

    private onClickSendInstructionsCancel() {
        this.setState({
            isSendInstructionsModalOpen: false,
        });
    }

    private onClickSendInstructionsOk() {
        const { instructionsSentDateTime } = this.getDefaultedValues();

        this.props.applicationSolicitorInstructionsSentTime(instructionsSentDateTime);
        this.onClickSendInstructionsCancel();
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        application: applicationSelector(state, ownProps.match.params.applicationUuid),
        solicitors: solicitorsSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        applicationGet: () => dispatch(applicationGetAction(ownProps.match.params.applicationUuid)),
        applicationSolicitorInstructionsSentTime: (instructionsSentTime: string) => dispatch(applicationSolicitorInstructionsSentTimeAction(ownProps.match.params.applicationUuid, instructionsSentTime)),
        applicationValueSet: (key: keyof IApplication, value: boolean|number|string) => dispatch(applicationValueSetAction(ownProps.match.params.applicationUuid, key, value)),
        solicitorsList: () => dispatch(solicitorsListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SolicitorInstructions);
