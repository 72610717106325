import IApplicationWarehouse from '~Api/Application/IApplicationWarehouse';
import IApplication from './IApplication';
import IApplicationDisbursement from './IApplicationDisbursement';
import IApplicationFee from './IApplicationFee';
import IBorrower from './IBorrower';
import IConditionalCondition from './IConditionalCondition';
import INote from './INote';
import ICondition from './ICondition';
import IConditionDocument from './IConditionDocument';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformApplication(application: IApplication): any {
    return {
        brokerCommissionDollars: application.brokerCommissionDollars,
        brokerCommissionFormat: application.brokerCommissionFormat,
        brokerCommissionPercentage: application.brokerCommissionPercentage,
        codeType: application.codeType,
        creditNotes: application.creditNotes,
        disbursalType: application.disbursalType,
        dischargeInterestType: application.dischargeInterestType,
        interestPaymentMethod: application.interestPaymentMethod,
        internalComments: application.internalComments,
        loanPurpose: application.loanPurpose,
        loanSynopsis: application.loanSynopsis,
        mortgageType: application.mortgageType,
        partnerCommissionDollars: application.partnerCommissionDollars,
        partnerCommissionFormat: application.partnerCommissionFormat,
        partnerCommissionPercentage: application.partnerCommissionPercentage,
        primaryBorrowerUuid: application.primaryBorrowerUuid,
        repaymentStrategy: application.repaymentStrategy,
        secondaryRepaymentStrategy: application.secondaryRepaymentStrategy,
        solicitorInstructionsSentTime: application.solicitorInstructionsSentTime,
        solicitorUuid: application.solicitorUuid,
        termMonthsMinimum: application.termMonthsMinimum,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformApplicationBorrower(borrower: IBorrower): any {
    return {
        creditScore: borrower.creditScore,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformApplicationCondition(condition: ICondition): any {
    return {
        name: condition.name,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformApplicationConditionDocument(conditionDocument: IConditionDocument): any {
    return {
        applicationPropertyUuid: conditionDocument.applicationPropertyUuid,
        applicationUuid: conditionDocument.applicationUuid,
        borrowerUuid: conditionDocument.borrowerUuid,
        conditionType: conditionDocument.conditionType,
        conditionUuid: conditionDocument.conditionUuid,
        documentUuid: conditionDocument.documentUuid,
        propertyUuid: conditionDocument.dealPropertyUuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformApplicationDisbursement(applicationDisbursement: IApplicationDisbursement): any {
    return {
        amount: applicationDisbursement.amount,
        description: applicationDisbursement.description,
        payeeName: applicationDisbursement.payeeName,
        type: applicationDisbursement.type,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformApplicationConditionalCondition(condition: IConditionalCondition): any {
    return {
        name: condition.name,
        uuid: condition.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformApplicationFee(fee: IApplicationFee): any {
    return {
        amount: fee.amount,
        description: fee.description,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformApplicationNote(note: INote): any {
    return {
        note: note.note,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformApplicationWarehouse(applicationWarehouse: IApplicationWarehouse): any {
    return {
        amount: applicationWarehouse.amount,
        warehouseUuid: applicationWarehouse.warehouseUuid,
    };
}
