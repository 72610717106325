import { Button, Typography } from 'antd';
import React, { ReactElement } from 'react';
import Layout from './Layout';

export default function InvestmentsExport(): ReactElement {
    return (
        <Layout section='investments-export'>
            <Typography.Title level={2}>Investments Export</Typography.Title>
            <Button
                href={`${process.env.API_HOST}/investment-reports/export`}
                target='_blank'
                type='primary'
            >Download</Button>
        </Layout>
    );
}
