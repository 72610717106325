import { Action } from 'redux';
import CloseReasonEnum from '~Api/Deal/CloseReasonEnum';
import IDealBorrower from '~Api/Deal/IDealBorrower';
import IDocument from '~Api/Deal/IDocument';
import IHistory from '~Api/Deal/IHistory';
import INote from '~Api/Deal/INote';
import IPostcodeCategory from '~Api/Deal/IPostcodeCategory';
import IProperty from '~Api/Deal/IProperty';
import ISpPostcodeCategory from '~Api/Deal/ISpPostcodeCategory';
import DealsActionsEnum from './ActionsEnum';
import IAddressSearchResult from '~Api/AddressSearch/IAddressSearchResult';

export interface IDealAssignAction extends Action {
    administratorUuid: string;
    dealUuid: string;
}

export function dealAssignAction(dealUuid: string, administratorUuid: string): IDealAssignAction {
    return {
        administratorUuid,
        dealUuid,
        type: DealsActionsEnum.DealAssign,
    };
}

export interface IDealAssignProcessorAction extends Action {
    administratorUuid: string;
    dealUuid: string;
}

export function dealAssignProcessorAction(dealUuid: string, administratorUuid: string): IDealAssignProcessorAction {
    return {
        administratorUuid,
        dealUuid,
        type: DealsActionsEnum.DealAssignProcessor,
    };
}

export interface IDealBorrowerSendAction extends Action {
    dealBorrowerUuid: string;
}

export function dealBorrowerSendAction(dealBorrowerUuid: string): IDealBorrowerSendAction {
    return {
        dealBorrowerUuid,
        type: DealsActionsEnum.DealBorrowerSend,
    };
}

export interface IDealBorrowerSetAction extends Action {
    borrower: IDealBorrower;
}

export function dealBorrowerSetAction(borrower: IDealBorrower): IDealBorrowerSetAction {
    return {
        borrower,
        type: DealsActionsEnum.DealBorrowerSet,
    };
}

export interface IDealBorrowerValueSetAction extends Action {
    dealBorrowerUuid: string;
    key: keyof IDealBorrower;
    value: boolean|number|string;
}

export function dealBorrowerValueSetAction(dealBorrowerUuid: string, key: keyof IDealBorrower, value: boolean|number|string): IDealBorrowerValueSetAction {
    return {
        dealBorrowerUuid,
        key,
        type: DealsActionsEnum.DealBorrowerValueSet,
        value,
    };
}

export interface IDealCloseAction extends Action {
    dealUuid: string;
    note: string;
    reason: CloseReasonEnum;
}

export function dealCloseAction(dealUuid: string, reason: CloseReasonEnum, note: string): IDealCloseAction {
    return {
        dealUuid,
        note,
        reason,
        type: DealsActionsEnum.DealClose,
    };
}

export interface IDealDocumentsAddAction extends Action {
    file: File;
    dealUuid: string;
}

export function dealDocumentsAddAction(dealUuid: string, file: File): IDealDocumentsAddAction {
    return {
        dealUuid,
        file,
        type: DealsActionsEnum.DealDocumentsAdd,
    };
}

export interface IDealDocumentsListAction extends Action {
    dealUuid: string;
}

export function dealDocumentsListAction(dealUuid: string): IDealDocumentsListAction {
    return {
        dealUuid,
        type: DealsActionsEnum.DealDocumentsList,
    };
}

export interface IDealDocumentsSetAction extends Action {
    documents: IDocument[];
    dealUuid: string;
}

export function dealDocumentsSetAction(dealUuid: string, documents: IDocument[]): IDealDocumentsSetAction {
    return {
        dealUuid,
        documents,
        type: DealsActionsEnum.DealDocumentsSet,
    };
}

export interface IDealDocumentSetAction extends Action {
    document: IDocument;
    dealUuid: string;
}

export function dealDocumentSetAction(dealUuid: string, document: IDocument): IDealDocumentSetAction {
    return {
        dealUuid,
        document,
        type: DealsActionsEnum.DealDocumentSet,
    };
}

export interface IDealEstablishmentFeeMinimumAction extends Action {
    dealUuid: string;
    establishmentFeeDollarsMinimum: number;
    establishmentFeePercentageMinimum: number;
}

export function dealEstablishmentFeeMinimumAction(dealUuid: string, establishmentFeeDollarsMinimum: number, establishmentFeePercentageMinimum: number): IDealEstablishmentFeeMinimumAction {
    return {
        dealUuid,
        establishmentFeeDollarsMinimum,
        establishmentFeePercentageMinimum,
        type: DealsActionsEnum.DealEstablishmentFeeMinimum,
    };
}

export interface IDealFollowUpAction extends Action {
    dealUuid: string;
    sendEmail: boolean;
}

export function dealFollowUpAction(dealUuid: string, sendEmail: boolean): IDealFollowUpAction {
    return {
        dealUuid,
        sendEmail,
        type: DealsActionsEnum.DealFollowUp,
    };
}

export interface IDealFollowUpTimeAction extends Action {
    followUpTime: string;
    dealUuid: string;
    reason: string;
}

export function dealFollowUpTimeAction(dealUuid: string, followUpTime: string, reason: string): IDealFollowUpTimeAction {
    return {
        dealUuid,
        followUpTime,
        reason,
        type: DealsActionsEnum.DealFollowUpTime,
    };
}

export interface IDealHistoriesListAction extends Action {
    dealUuid: string;
}

export function dealHistoriesListAction(dealUuid: string): IDealHistoriesListAction {
    return {
        dealUuid,
        type: DealsActionsEnum.DealHistoriesList,
    };
}

export interface IDealHistoriesSetAction extends Action {
    histories: IHistory[];
    dealUuid: string;
}

export function dealHistoriesSetAction(dealUuid: string, histories: IHistory[]): IDealHistoriesSetAction {
    return {
        dealUuid,
        histories,
        type: DealsActionsEnum.DealHistoriesSet,
    };
}

export interface IDealInterestRateMinimumAction extends Action {
    dealUuid: string;
    interestRateMinimum: number;
}

export function dealInterestRateMinimumAction(dealUuid: string, interestRateMinimum: number): IDealInterestRateMinimumAction {
    return {
        dealUuid,
        interestRateMinimum,
        type: DealsActionsEnum.DealInterestRateMinimum,
    };
}

export interface IDealLegalFeesDollarsMinimumAction extends Action {
    dealUuid: string;
    legalFeesDollarsMinimum: number;
}

export function dealLegalFeesDollarsMinimumAction(dealUuid: string, legalFeesDollarsMinimum: number): IDealLegalFeesDollarsMinimumAction {
    return {
        dealUuid,
        legalFeesDollarsMinimum,
        type: DealsActionsEnum.DealLegalFeesDollarsMinimum,
    };
}

export interface IDealLvrMaximumAction extends Action {
    dealUuid: string;
    lvrMaximum: number;
}

export function dealLvrMaximumAction(dealUuid: string, lvrMaximum: number): IDealLvrMaximumAction {
    return {
        dealUuid,
        lvrMaximum,
        type: DealsActionsEnum.DealLvrMaximum,
    };
}

export interface IDealNotesAddAction extends Action {
    dealUuid: string;
    note: string;
}

export function dealNotesAddAction(dealUuid: string, note: string): IDealNotesAddAction {
    return {
        dealUuid,
        note,
        type: DealsActionsEnum.DealNotesAdd,
    };
}

export interface IDealNotesListAction extends Action {
    dealUuid: string;
}

export function dealNotesListAction(dealUuid: string): IDealNotesListAction {
    return {
        dealUuid,
        type: DealsActionsEnum.DealNotesList,
    };
}

export interface IDealNotesSetAction extends Action {
    dealUuid: string;
    notes: INote[];
}

export function dealNotesSetAction(dealUuid: string, notes: INote[]): IDealNotesSetAction {
    return {
        dealUuid,
        notes,
        type: DealsActionsEnum.DealNotesSet,
    };
}

export interface IDealNoteRemoveAction extends Action {
    dealUuid: string;
    noteUuid: string;
}

export function dealNoteRemoveAction(dealUuid: string, noteUuid: string): IDealNoteRemoveAction {
    return {
        dealUuid,
        noteUuid,
        type: DealsActionsEnum.DealNoteRemove,
    };
}

export interface IDealNoteSetAction extends Action {
    dealUuid: string;
    note: INote;
}

export function dealNoteSetAction(dealUuid: string, note: INote): IDealNoteSetAction {
    return {
        dealUuid,
        note,
        type: DealsActionsEnum.DealNoteSet,
    };
}

export interface IDealPropertiesAddAction extends Action {
    dealUuid: string;
    property: IProperty;
}

export function dealPropertiesAddAction(dealUuid: string, property: IProperty): IDealPropertiesAddAction {
    return {
        dealUuid,
        property,
        type: DealsActionsEnum.DealPropertiesAdd,
    };
}

export interface IDealPropertiesListAction extends Action {
    dealUuid: string;
}

export function dealPropertiesListAction(dealUuid: string): IDealPropertiesListAction {
    return {
        dealUuid,
        type: DealsActionsEnum.DealPropertiesList,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDealPropertiesPostcodeCategoriesListAction extends Action {}

export function dealPropertiesPostcodeCategoriesListAction(): IDealPropertiesPostcodeCategoriesListAction {
    return {
        type: DealsActionsEnum.DealPropertiesPostcodeCategoriesList,
    };
}

export interface IDealPropertiesPostcodeCategoriesSetAction extends Action {
    postcodeCategories: IPostcodeCategory[];
}

export function dealPropertiesPostcodeCategoriesSetAction(postcodeCategories: IPostcodeCategory[]): IDealPropertiesPostcodeCategoriesSetAction {
    return {
        postcodeCategories,
        type: DealsActionsEnum.DealPropertiesPostcodeCategoriesSet,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDealPropertiesSpPostcodeCategoriesListAction extends Action {}

export function dealPropertiesSpPostcodeCategoriesListAction(): IDealPropertiesSpPostcodeCategoriesListAction {
    return {
        type: DealsActionsEnum.DealPropertiesSpPostcodeCategoriesList,
    };
}

export interface IDealPropertiesSpPostcodeCategoriesSetAction extends Action {
    spPostcodeCategories: ISpPostcodeCategory[];
}

export function dealPropertiesSpPostcodeCategoriesSetAction(spPostcodeCategories: ISpPostcodeCategory[]): IDealPropertiesSpPostcodeCategoriesSetAction {
    return {
        spPostcodeCategories,
        type: DealsActionsEnum.DealPropertiesSpPostcodeCategoriesSet,
    };
}

export interface IDealPropertiesSetAction extends Action {
    dealUuid: string;
    properties: IProperty[];
}

export function dealPropertiesSetAction(dealUuid: string, properties: IProperty[]): IDealPropertiesSetAction {
    return {
        dealUuid,
        properties,
        type: DealsActionsEnum.DealPropertiesSet,
    };
}

export interface IDealPropertyDeleteAction extends Action {
    dealUuid: string;
    propertyUuid: string;
}

export function dealPropertyDeleteAction(dealUuid: string, propertyUuid: string): IDealPropertyDeleteAction {
    return {
        dealUuid,
        propertyUuid,
        type: DealsActionsEnum.DealPropertyDelete,
    };
}

export interface IDealPropertyGetAction extends Action {
    dealUuid: string;
    propertyUuid: string;
}

export function dealPropertyGetAction(dealUuid: string, propertyUuid: string): IDealPropertyGetAction {
    return {
        dealUuid,
        propertyUuid,
        type: DealsActionsEnum.DealPropertyGet,
    };
}

export interface IDealPropertyRemoveAction extends Action {
    dealUuid: string;
    propertyUuid: string;
}

export function dealPropertyRemoveAction(dealUuid: string, propertyUuid: string): IDealPropertyRemoveAction {
    return {
        dealUuid,
        propertyUuid,
        type: DealsActionsEnum.DealPropertyRemove,
    };
}

export interface IDealPropertySendAction extends Action {
    dealUuid: string;
    propertyUuid: string;
}

export function dealPropertySendAction(dealUuid: string, propertyUuid: string): IDealPropertySendAction {
    return {
        dealUuid,
        propertyUuid,
        type: DealsActionsEnum.DealPropertySend,
    };
}

export interface IDealPropertySetAction extends Action {
    dealUuid: string;
    property: IProperty;
}

export function dealPropertySetAction(dealUuid: string, property: IProperty): IDealPropertySetAction {
    return {
        dealUuid,
        property,
        type: DealsActionsEnum.DealPropertySet,
    };
}

export interface IDealPropertyValueSetAction extends Action {
    dealUuid: string;
    propertyUuid: string;
    key: keyof IProperty;
    value: boolean|number|string;
}

export function dealPropertyValueSetAction(dealUuid: string, propertyUuid: string, key: keyof IProperty, value: boolean|number|string): IDealPropertyValueSetAction {
    return {
        dealUuid,
        key,
        propertyUuid,
        type: DealsActionsEnum.DealPropertyValueSet,
        value,
    };
}

export interface IDealPropertyGnafAddressDetailAction extends Action {
    gnafAddressDetailPid: string;
    propertyUuid: string;
}

export function dealPropertyGnafAddressDetailAction(propertyUuid: string, gnafAddressDetailPid: string): IDealPropertyGnafAddressDetailAction {
    return {
        gnafAddressDetailPid,
        propertyUuid,
        type: DealsActionsEnum.DealPropertyGnafAddressDetailAction,
    };
}

type IDealPropertyGnafAddressDetailLoadingClearAction = Action<DealsActionsEnum.DealPropertyGnafAddressDetailLoadingClear>;

export function dealPropertyGnafAddressDetailLoadingClearAction(): IDealPropertyGnafAddressDetailLoadingClearAction {
    return {
        type: DealsActionsEnum.DealPropertyGnafAddressDetailLoadingClear,
    };
}

export interface IDealAddressSearchAction extends Action {
    keyword: string;
}

export function dealAddressSearchAction(keyword: string): IDealAddressSearchAction {
    return {
        keyword,
        type: DealsActionsEnum.DealAddressSearch,
    };
}

export interface IDealAddressSearchResultsSetAction extends Action {
    searchResults: IAddressSearchResult[];
}

export function dealAddressSearchResultsSetAction(searchResults: IAddressSearchResult[]): IDealAddressSearchResultsSetAction {
    return {
        searchResults,
        type: DealsActionsEnum.DealAddressSearchResultsSet,
    };
}

export type IDealAddressSearchResultsClearAction = Action<DealsActionsEnum.DealAddressSearchResultsClear>;

export function dealAddressSearchResultsClearAction(): IDealAddressSearchResultsClearAction {
    return {
        type: DealsActionsEnum.DealAddressSearchResultsClear,
    };
}

export interface IDealReopenAction extends Action {
    dealUuid: string;
}

export function dealReopenAction(dealUuid: string): IDealReopenAction {
    return {
        dealUuid,
        type: DealsActionsEnum.DealReopen,
    };
}

export interface IDealUnassignProcessorAction extends Action {
    administratorUuid: string;
    dealUuid: string;
}

export function dealUnassignProcessorAction(dealUuid: string, administratorUuid: string): IDealUnassignProcessorAction {
    return {
        administratorUuid,
        dealUuid,
        type: DealsActionsEnum.DealUnassignProcessor,
    };
}

export interface IDealUncontactableFollowUpAction extends Action {
    dealUuid: string;
    sendEmail: boolean;
}

export function dealUncontactableFollowUpAction(dealUuid: string, sendEmail: boolean): IDealUncontactableFollowUpAction {
    return {
        dealUuid,
        sendEmail,
        type: DealsActionsEnum.DealUncontactableFollowUp,
    };
}

export interface IDealQuoteApproveAction extends Action {
    quoteUuid: string;
}

export function dealQuoteApproveAction(quoteUuid: string): IDealQuoteApproveAction {
    return {
        quoteUuid,
        type: DealsActionsEnum.DealQuoteApprove,
    };
}

export interface IDealQuoteConvertScenarioAction extends Action {
    quoteUuid: string;
    sendEmail: boolean;
}

export function dealQuoteConvertScenarioAction(quoteUuid: string, sendEmail: boolean): IDealQuoteConvertScenarioAction {
    return {
        quoteUuid,
        sendEmail,
        type: DealsActionsEnum.DealQuoteConvertScenario,
    };
}

export interface IDealQuoteRejectAction extends Action {
    reason: string;
    quoteUuid: string;
}

export function dealQuoteRejectAction(quoteUuid: string, reason: string): IDealQuoteRejectAction {
    return {
        quoteUuid,
        reason,
        type: DealsActionsEnum.DealQuoteReject,
    };
}
