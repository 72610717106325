import { Button, Form, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { ReactElement, useCallback, useState } from 'react';
import Layout from '~Reports/Layout';
import DatePicker from '~UI/DatePicker';

export default function LoansDrawdownBalances(): ReactElement {
    const [asAtDate, setAsAtDate] = useState<string | null>(null);
    const [errors, setErrors] = useState<{ asAtDate?: string }>({});

    const validateAsAtDate: () => boolean = useCallback((): boolean => {
        let error: string | null = null;

        if (!asAtDate) {
            error = 'Please enter a date';
        }

        setErrors({ 'asAtDate': error });

        return !error;
    }, [asAtDate]);

    const onChangeAsAtDate: (date: Dayjs | null) => void = useCallback((date: Dayjs | null): void => {
        setAsAtDate(date?.format('YYYY-MM-DD') ?? null);
    }, []);

    const onClickDownload: () => void = useCallback((): void => {
        if (!validateAsAtDate()) {
            return;
        }

        window.open(
            `${process.env.API_HOST}/loans/download-drawdown-balances-report?asAtDate=${asAtDate}`,
            '_blank'
        );
    }, [asAtDate, validateAsAtDate]);

    return (
        <Layout section='loans-drawdown-balances'>
            <Typography.Title level={2}>Loans - Drawdown Balances</Typography.Title>
            <Form.Item
                help={errors.asAtDate}
                className='transaction-end-date'
                label='As At Date'
                validateStatus={errors.asAtDate && 'error'}
            >
                <DatePicker
                    picker='date'
                    format='DD/MM/YYYY'
                    onBlur={validateAsAtDate}
                    onChange={onChangeAsAtDate}
                    value={asAtDate ? dayjs(asAtDate) : null}
                />
            </Form.Item>
            <Form.Item>
                <Button onClick={onClickDownload} type='primary'>Download</Button>
            </Form.Item>
        </Layout>
    );
}