import { Action } from 'redux';
import IInvestmentTransaction from '~Api/Investment/IInvestmentTransaction';
import IInvestorAccountInvestmentTransaction from '~Api/Investor/IInvestorAccountInvestmentTransaction';
import IInvestorAccountTransaction from '~Api/Investor/IInvestorAccountTransaction';
import ILoanTransaction from '~Api/Loan/ILoanTransaction';
import IWarehouseLoanTransaction from '~Api/Warehouse/IWarehouseLoanTransaction';
import AccountsActionsEnum from './ActionsEnum';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IInvestorMovementsListAction extends Action {}

export function investorMovementsListAction(): IInvestorMovementsListAction {
    return {
        type: AccountsActionsEnum.InvestorMovementsList,
    };
}

export interface IInvestorMovementsSetAction extends Action {
    transactions: IInvestorAccountTransaction[];
}

export function investorMovementsSetAction(transactions: IInvestorAccountTransaction[]): IInvestorMovementsSetAction {
    return {
        transactions,
        type: AccountsActionsEnum.InvestorMovementsSet,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILoanPaymentsListAction extends Action {}

export function loanPaymentsListAction(): ILoanPaymentsListAction {
    return {
        type: AccountsActionsEnum.LoanPaymentsList,
    };
}

export interface ILoanPaymentsSetAction extends Action {
    loanPayments: ILoanTransaction[];
}

export function loanPaymentsSetAction(loanPayments: ILoanTransaction[]): ILoanPaymentsSetAction {
    return {
        loanPayments,
        type: AccountsActionsEnum.LoanPaymentsSet,
    };
}

export interface ILoanTransactionWarehouseLoanTransactionsListAction extends Action {
    loanTransactionUuid: string;
}

export function loanTransactionWarehouseLoanTransactionsListAction(loanTransactionUuid: string): ILoanTransactionWarehouseLoanTransactionsListAction {
    return {
        loanTransactionUuid,
        type: AccountsActionsEnum.LoanTransactionWarehouseLoanTransactionsList,
    };
}

export interface ILoanTransactionWarehouseLoanTransactionsSetAction extends Action {
    loanTransactionUuid: string;
    warehouseLoanTransactions: IWarehouseLoanTransaction[];
}

export function loanTransactionWarehouseLoanTransactionsSetAction(loanTransactionUuid: string, warehouseLoanTransactions: IWarehouseLoanTransaction[]): ILoanTransactionWarehouseLoanTransactionsSetAction {
    return {
        loanTransactionUuid,
        type: AccountsActionsEnum.LoanTransactionWarehouseLoanTransactionsSet,
        warehouseLoanTransactions,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPaymentsFromInvestorsListAction extends Action {}

export function paymentsFromInvestorsListAction(): IPaymentsFromInvestorsListAction {
    return {
        type: AccountsActionsEnum.PaymentsFromInvestorsList,
    };
}

export interface IPaymentsFromInvestorsSetAction extends Action {
    investorAccountInvestmentTransactions: IInvestorAccountInvestmentTransaction[];
}

export function paymentsFromInvestorsSetAction(investorAccountInvestmentTransactions: IInvestorAccountInvestmentTransaction[]): IPaymentsFromInvestorsSetAction {
    return {
        investorAccountInvestmentTransactions,
        type: AccountsActionsEnum.PaymentsFromInvestorsSet,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPaymentsToInvestorsListAction extends Action {}

export function paymentsToInvestorsListAction(): IPaymentsToInvestorsListAction {
    return {
        type: AccountsActionsEnum.PaymentsToInvestorsList,
    };
}

export interface IPaymentsToInvestorsSetAction extends Action {
    investmentTransactions: IInvestmentTransaction[];
}

export function paymentsToInvestorsSetAction(investmentTransactions: IInvestmentTransaction[]): IPaymentsToInvestorsSetAction {
    return {
        investmentTransactions,
        type: AccountsActionsEnum.PaymentsToInvestorsSet,
    };
}

export interface IWarehouseMovementsListAction extends Action {
    endDate: string;
    startDate: string;
}

export function warehouseMovementsListAction(startDate: string, endDate: string): IWarehouseMovementsListAction {
    return {
        endDate,
        startDate,
        type: AccountsActionsEnum.WarehouseMovementsList,
    };
}

export interface IWarehouseMovementsSetAction extends Action {
    endDate: string;
    startDate: string;
    transactions: IWarehouseLoanTransaction[];
}

export function warehouseMovementsSetAction(startDate: string, endDate: string, transactions: IWarehouseLoanTransaction[]): IWarehouseMovementsSetAction {
    return {
        endDate,
        startDate,
        transactions,
        type: AccountsActionsEnum.WarehouseMovementsSet,
    };
}
