import { Button, Space, Spin, Table, Typography } from 'antd';
import { ColumnType, TableRowSelection } from 'antd/lib/table/interface';
import { CloudDownloadOutlined } from '@ant-design/icons';
import _ from 'lodash';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import IApplication from '~Api/Application/IApplication';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import IBorrower from '~Api/Application/IBorrower';
import ConditionTypeEnum from '~Api/Application/ConditionTypeEnum';
import ICondition from '~Api/Application/ICondition';
import IConditionDocument from '~Api/Application/IConditionDocument';
import { IDictionary } from '~utilities/IDictionary';
import { IGlobalState } from '~reducer';
import {
    applicationConditionDocumentsDownloadAction,
    applicationConditionDocumentsListAction,
    applicationConditionsListAction,
    applicationGetAction,
} from '~Applications/actions';
import {
    applicationBorrowersSelector,
    applicationConditionDocumentsUpdatingSolicitorAccessSelector,
    applicationConditionsSelector,
    applicationDownloadableConditionDocumentsSelector,
    applicationPropertiesSelector,
    applicationSelector,
} from '~Applications/selectors';
import Layout from './Layout';
import {
    conditionLabels,
    getApplicationConditions,
    getBorrowerConditions,
    getPropertyConditions,
} from '~Applications/utilities';

enum SolicitorDownloadsRowTypeEnum {
    Header = 'HEADER',
    Category = 'CATEGORY',
    File = 'FILE',
}

const bytesToKilobytes: (bytes: number) => number = (bytes: number) => {
    return bytes / 1024;
};

interface IRow {
    children?: IRow[];
    conditionDocument?: IConditionDocument;
    label: string;
    uid: string;
    rowType: SolicitorDownloadsRowTypeEnum;
    bytes: number;
    fileCount: number;
}

export default function SolicitorDownloads(): ReactElement {
    const { applicationUuid } = useParams<{applicationUuid: string}>();

    const [selectedUuidKeys, setSelectedUuidKeys] = useState<IDictionary<boolean>>(null);

    const application: IApplication = useSelector((state: IGlobalState) => applicationSelector(state, applicationUuid));
    const applicationProperties: IDictionary<IApplicationProperty> = useSelector((state: IGlobalState) => applicationPropertiesSelector(state, applicationUuid));
    const borrowers: IBorrower[] = useSelector((state: IGlobalState) => applicationBorrowersSelector(state, applicationUuid));
    const conditionDocuments: IDictionary<IConditionDocument> = useSelector((state: IGlobalState) => applicationDownloadableConditionDocumentsSelector(state, applicationUuid));
    const updatingSolicitorAccess: boolean = useSelector(applicationConditionDocumentsUpdatingSolicitorAccessSelector);
    const conditions: IDictionary<ICondition> = useSelector((state: IGlobalState) => applicationConditionsSelector(state, applicationUuid));

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!application) {
            dispatch(applicationGetAction(applicationUuid));
        }
    }, [application, applicationUuid, dispatch]);

    useEffect(() => {
        if (!conditions) {
            dispatch(applicationConditionsListAction(applicationUuid));
        }
    }, [applicationUuid, conditions, dispatch]);

    useEffect(() => {
        if (!conditionDocuments) {
            dispatch(applicationConditionDocumentsListAction(applicationUuid));
        }
    }, [applicationUuid, conditionDocuments, dispatch]);

    const summaryRow: (data: readonly IRow[]) => JSX.Element = useCallback((data: readonly IRow[]) => {
        let totalFileCount: number = 0;
        let totalSize: number = 0;
        _.each(data, (row: IRow) => {
            totalFileCount += row.fileCount;
            totalSize += row.bytes;
        });

        return (
            <Table.Summary.Row>
                <Table.Summary.Cell index={0}>&nbsp;</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={2}>{totalFileCount}</Table.Summary.Cell>
                <Table.Summary.Cell index={3}>{_.round(bytesToKilobytes(totalSize))}</Table.Summary.Cell>
            </Table.Summary.Row>
        );
    }, []);

    const onChangeSelect: (keys: React.Key[], selectedRows: IRow[]) => void = useCallback((keys: React.Key[], selectedRows: IRow[]) => {
        const selectedUuidKeys: IDictionary<boolean> = {};
        _.each(selectedRows, (row: IRow) => {
            if (row.rowType !== SolicitorDownloadsRowTypeEnum.File) {
                return;
            }

            selectedUuidKeys[row.conditionDocument.uuid] = true;
        });

        setSelectedUuidKeys(selectedUuidKeys);
    }, []);

    let selectedRowKeys: IDictionary<boolean> = {};

    if (null !== selectedUuidKeys) {
        selectedRowKeys = selectedUuidKeys;
    } else {
        _.each(conditionDocuments, (conditionDocument: IConditionDocument) => {
            if (conditionDocument.solicitorAccess) {
                selectedRowKeys[conditionDocument.uuid] = true;
            }
        });
    }

    const onClickDownload: () => void = useCallback(() => {
        dispatch(applicationConditionDocumentsDownloadAction(applicationUuid, _.keys(selectedRowKeys)));
    }, [applicationUuid, dispatch, selectedRowKeys]);

    if (!application || !applicationProperties || !borrowers || !conditionDocuments || !conditions) {
        return (
            <Layout applicationUuid={applicationUuid} section='solitor-downloads'>
                <Typography.Title level={2}>Solicitor Downloads</Typography.Title>
                <Spin/>
            </Layout>
        );
    }

    const conditionDocumentsByConditionType: IDictionary<IDictionary<IConditionDocument[]>> = {};

    _.forEach(conditionDocuments, (conditionDocument: IConditionDocument) => {
        /** Assume custom condition on no condition type */
        const key: string | ConditionTypeEnum = conditionDocument.conditionType || conditionDocument.conditionUuid;
        const uuid: string = conditionDocument.borrowerUuid || conditionDocument.dealPropertyUuid || conditionDocument.dealUuid;

        if (!conditionDocumentsByConditionType[uuid]) {
            conditionDocumentsByConditionType[uuid] = {};
        }
        if (!conditionDocumentsByConditionType[uuid][key]) {
            conditionDocumentsByConditionType[uuid][key] = [];
        }

        conditionDocumentsByConditionType[uuid][key].push(conditionDocument);
    });

    const parentRows: IRow[] = [];
    const parentApplicationConditionsRow: IRow = {
        bytes: 0,
        children: [],
        fileCount: 0,
        label: 'Application',
        rowType: SolicitorDownloadsRowTypeEnum.Header,
        uid: `application-${application.uuid}`,
    };

    const addChildren: (parentRow: IRow, conditionDocumentRow: IRow, conditionDocuments: IConditionDocument[]) => void = (parentRow: IRow, conditionDocumentRow: IRow, conditionDocuments: IConditionDocument[]) => {
        _.each(conditionDocuments, (conditionDocument: IConditionDocument) => {
            conditionDocumentRow.children.push({
                bytes: conditionDocument.document.bytes,
                conditionDocument: conditionDocument,
                fileCount: 1,
                label: conditionDocument.document.filename,
                rowType: SolicitorDownloadsRowTypeEnum.File,
                uid: conditionDocument.uuid,
            });

            if (selectedRowKeys[conditionDocument.uuid]) {
                parentRow.bytes += conditionDocument.document.bytes;
                conditionDocumentRow.bytes += conditionDocument.document.bytes;
                parentRow.fileCount += 1;
                conditionDocumentRow.fileCount += 1;
            }
        });
    };

    const applicationConditionTypes: ConditionTypeEnum[] = getApplicationConditions(application);

    _.each(applicationConditionTypes, (conditionType: ConditionTypeEnum) => {
        if (!conditionDocumentsByConditionType[application.dealUuid][conditionType]) {
            return;
        }

        const conditionDocumentRow: IRow = {
            bytes: 0,
            children: [],
            fileCount: 0,
            label: conditionLabels[conditionType],
            rowType: SolicitorDownloadsRowTypeEnum.Category,
            uid: `application-${application.uuid}-condition-${conditionType}`,
        };

        addChildren(parentApplicationConditionsRow, conditionDocumentRow, conditionDocumentsByConditionType[application.dealUuid][conditionType]);

        parentApplicationConditionsRow.children.push(conditionDocumentRow);
    });

    _.each(conditions, (condition: ICondition) => {
        if (!conditionDocumentsByConditionType[application.dealUuid][condition.uuid]) {
            return;
        }

        const conditionDocumentRow: IRow = {
            bytes: 0,
            children: [],
            fileCount: 0,
            label: conditions[condition.uuid].name,
            rowType: SolicitorDownloadsRowTypeEnum.Category,
            uid: `application-${condition.uuid}-custom-condition`,
        };

        addChildren(parentApplicationConditionsRow, conditionDocumentRow, conditionDocumentsByConditionType[application.dealUuid][condition.uuid]);

        parentApplicationConditionsRow.children.push(conditionDocumentRow);
    });

    parentRows.push(parentApplicationConditionsRow);

    _.each(borrowers, (borrower: IBorrower) => {
        const parentBorrowersRow: IRow = {
            bytes: 0,
            children: [],
            fileCount: 0,
            label: borrower.dealBorrower.formattedName,
            rowType: SolicitorDownloadsRowTypeEnum.Header,
            uid: `borrower-${borrower.uuid}`,
        };

        const conditionTypes: ConditionTypeEnum[] = getBorrowerConditions(application, borrower);

        _.each(conditionTypes, (conditionType: ConditionTypeEnum) => {
            if (!conditionDocumentsByConditionType[borrower.uuid] || !conditionDocumentsByConditionType[borrower.uuid][conditionType]) {
                return;
            }

            const conditionDocumentRow: IRow = {
                bytes: 0,
                children: [],
                fileCount: 0,
                label: conditionLabels[conditionType],
                rowType: SolicitorDownloadsRowTypeEnum.Category,
                uid: `borrower-${borrower.uuid}-condition-${conditionType}`,
            };

            addChildren(parentBorrowersRow, conditionDocumentRow, conditionDocumentsByConditionType[borrower.uuid][conditionType]);

            parentBorrowersRow.children.push(conditionDocumentRow);
        });

        parentRows.push(parentBorrowersRow);
    });

    _.each(applicationProperties, (applicationProperty: IApplicationProperty) => {
        const conditionTypes: ConditionTypeEnum[] = getPropertyConditions(application, applicationProperty);

        const parentApplicationPropertiesRow: IRow = {
            bytes: 0,
            children: [],
            fileCount: 0,
            label: applicationProperty.dealProperty.streetAddress,
            rowType: SolicitorDownloadsRowTypeEnum.Header,
            uid: `property-${applicationProperty.uuid}`,
        };

        _.each(conditionTypes, (conditionType: ConditionTypeEnum) => {
            if (!conditionDocumentsByConditionType[applicationProperty.dealPropertyUuid] || !conditionDocumentsByConditionType[applicationProperty.dealPropertyUuid][conditionType]) {
                return;
            }

            const conditionDocumentRow: IRow = {
                bytes: 0,
                children: [],
                fileCount: 0,
                label: conditionLabels[conditionType],
                rowType: SolicitorDownloadsRowTypeEnum.Category,
                uid: `property-${applicationProperty.uuid}-condition-${conditionType}`,
            };

            addChildren(parentApplicationPropertiesRow, conditionDocumentRow, conditionDocumentsByConditionType[applicationProperty.dealPropertyUuid][conditionType]);

            parentApplicationPropertiesRow.children.push(conditionDocumentRow);
        });

        parentRows.push(parentApplicationPropertiesRow);
    });

    const columns: ColumnType<IRow>[] = [
        {
            dataIndex: 'label',
            render: (label: string, row: IRow) => {
                if (row.rowType !== SolicitorDownloadsRowTypeEnum.File) {
                    return label;
                }

                return (
                    <Space>
                        <span>{label}</span>
                        <Button
                            href={`${process.env.API_HOST}/deal-documents/${row.conditionDocument.documentUuid}/download`}
                            target='_blank'
                            type='link'
                            className='document approved'
                        >
                            <CloudDownloadOutlined/>
                        </Button>
                    </Space>
                );
            },
            title: 'Condition',
        },
        {
            dataIndex: 'fileCount',
            sorter: (a: IRow, b: IRow) => a.fileCount - b.fileCount,
            title: 'File Count',
            width: '20%',
        },
        {
            dataIndex: 'bytes',
            render: (bytes: number) => _.round(bytesToKilobytes(bytes)),
            sorter: (a: IRow, b: IRow) => a.bytes - b.bytes,
            title: 'File Size (KB)',
            width: '20%',
        },
    ];

    const rowSelection: TableRowSelection<IRow> = {
        checkStrictly: false,
        onChange: onChangeSelect,
        selectedRowKeys: _.keys(selectedRowKeys),
    };

    return (
        <Layout applicationUuid={applicationUuid} section='solicitor-downloads'>
            <Typography.Title level={2}>Solicitor Downloads</Typography.Title>
            <Table
                columns={columns}
                dataSource={parentRows}
                pagination={false}
                rowKey='uid'
                rowSelection={rowSelection}
                scroll={{ x: 1, y: 800 }}
                size='middle'
                summary={summaryRow}
            />
            <Button className='download-button' loading={updatingSolicitorAccess} onClick={onClickDownload}>
                Download Documents
            </Button>
        </Layout>
    );
}
