import { Breadcrumb, Layout, Spin, Table, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import _ from 'lodash';
import React, { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IWarehousesPortfolioMetrics from '~Api/Warehouse/IWarehousesPortfolioMetrics';
import WarehouseTypeEnum from '~Api/Warehouse/WarehouseTypeEnum';
import { IDictionary } from '~utilities/IDictionary';
import { currencyFormatter, percentageFormatter } from '~utilities/formatters';
import { warehousesPortfolioDashboardListAction } from './actions';
import { warehousesPortfolioDashboardSelector } from './selectors';

enum PortfolioMetricEnum {
    TotalFunds = 'TOTAL_FUNDS',
    TotalLoanBook = 'TOTAL_LOAN_BOOK',
    LoanBookPerforming = 'LOAN_BOOK_PERFORMING',
    LoanBookNonPerforming = 'LOAN_BOOK_NON_PERFORMING',
    LoanBookNonPerformingPercentage = 'LOAN_BOOK_NON_PERFORMING_PERCENTAGE',
    TotalRetained = 'TOTAL_RETAINED',
    AvailableFunds = 'AVAILABLE_FUNDS',
    UtilisationRate = 'UTILISATION_RATE',
    WeightedLvr = 'WEIGHTED_LVR',
    CostOfFunds = 'COST_OF_FUNDS',
    ActualYield = 'ACTUAL_YIELD',
    NimPerformingPercentage = 'NIM_PERFORMING_PERCENTAGE',
    NimPerformingValue = 'NIM_PERFORMING_VALUE',
    OptimalYield = 'OPTIMAL_YIELD',
    NimTotalPercentage = 'NIM_TOTAL_PERCENTAGE',
    NimTotalValue = 'NIM_TOTAL_VALUE',
}

const metricsLabels: IDictionary<string> = {
    [PortfolioMetricEnum.ActualYield]: 'Cash Yield',
    [PortfolioMetricEnum.AvailableFunds]: 'Available Funds',
    [PortfolioMetricEnum.CostOfFunds]: 'Cost of Funds',
    [PortfolioMetricEnum.LoanBookNonPerforming]: 'Loan Book (Non-Performing)',
    [PortfolioMetricEnum.LoanBookNonPerformingPercentage]: 'Loan Book (Non-Performing) %',
    [PortfolioMetricEnum.LoanBookPerforming]: 'Loan Book (Performing)',
    [PortfolioMetricEnum.NimPerformingPercentage]: 'Cash NIM',
    [PortfolioMetricEnum.NimPerformingValue]: 'Cash Profit / Loss',
    [PortfolioMetricEnum.NimTotalPercentage]: 'Contract NIM',
    [PortfolioMetricEnum.NimTotalValue]: 'Contract Profit / Loss',
    [PortfolioMetricEnum.OptimalYield]: 'Contract Yield',
    [PortfolioMetricEnum.TotalFunds]: 'Total Funds',
    [PortfolioMetricEnum.TotalLoanBook]: 'Loan Book (Total)',
    [PortfolioMetricEnum.TotalRetained]: 'Total Retained',
    [PortfolioMetricEnum.UtilisationRate]: 'Utilisation Rate',
    [PortfolioMetricEnum.WeightedLvr]: 'Weighted LVR',
};

interface IRestructuredMetrics {
    metric: PortfolioMetricEnum;
    warehouses: IDictionary<number>;
    total: number;
}

export default function PortfolioDashboard(): ReactElement {
    const warehousesPortfolioMetrics: IDictionary<IWarehousesPortfolioMetrics> = useSelector(warehousesPortfolioDashboardSelector);

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        dispatch(warehousesPortfolioDashboardListAction());
    }, [
        dispatch,
    ]);

    const rowFormatter: (row: IRestructuredMetrics, value: number) => string = useCallback((row: IRestructuredMetrics, value: number) => {
        switch (row.metric) {
            case PortfolioMetricEnum.TotalFunds:
            case PortfolioMetricEnum.TotalLoanBook:
            case PortfolioMetricEnum.LoanBookPerforming:
            case PortfolioMetricEnum.LoanBookNonPerforming:
            case PortfolioMetricEnum.TotalRetained:
            case PortfolioMetricEnum.AvailableFunds:
            case PortfolioMetricEnum.NimPerformingValue:
            case PortfolioMetricEnum.NimTotalValue:
                return currencyFormatter.format(value);
            case PortfolioMetricEnum.CostOfFunds:
            case PortfolioMetricEnum.WeightedLvr:
            case PortfolioMetricEnum.ActualYield:
            case PortfolioMetricEnum.OptimalYield:
                return percentageFormatter.format(value / 100);
            case PortfolioMetricEnum.LoanBookNonPerformingPercentage:
            case PortfolioMetricEnum.UtilisationRate:
            case PortfolioMetricEnum.NimPerformingPercentage:
            case PortfolioMetricEnum.NimTotalPercentage:
                return percentageFormatter.format(value);
        }
    }, []);

    if (!warehousesPortfolioMetrics) {
        return (
            <Layout className='warehouses'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to='/warehouses'>Warehouses</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>Portfolio Dashboard</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Typography.Title level={2}>Warehouses Portfolio Dashboard</Typography.Title>
                        <Spin/>
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }

    const restructuredPortfolioMetrics: IDictionary<IRestructuredMetrics> = {};
    _.each(PortfolioMetricEnum, (metric: PortfolioMetricEnum) => {
        restructuredPortfolioMetrics[metric] = {
            metric: metric,
            total: 0,
            warehouses: {
                [WarehouseTypeEnum.Fin]: 0,
                [WarehouseTypeEnum.Fit]: 0,
                [WarehouseTypeEnum.Fnom]: 0,
                [WarehouseTypeEnum.Fwt1]: 0,
                [WarehouseTypeEnum.Fwt2]: 0,
            },
        };
    });

    let totalGroupTotalFunds: number = 0;
    let totalGroupLoanBookTotal: number = 0;
    let totalGroupLoanBookNonPerforming: number = 0;
    let totalGroupCostOfFundsValue: number = 0;
    let totalGroupWeightedLvrValue: number = 0;
    let totalGroupLoansTotalAmountRemaining: number = 0;
    let totalGroupTotalWeightedInterestRateValueActual: number = 0;
    let totalGroupTotalWeightedInterestRateValueOptimal: number = 0;
    let totalGroupCostableFunds: number = 0;

    _.each(warehousesPortfolioMetrics, (metrics: IWarehousesPortfolioMetrics) => {
        // Total Funds
        restructuredPortfolioMetrics[PortfolioMetricEnum.TotalFunds].warehouses[metrics.warehouseType] = metrics.totalFunds;
        totalGroupTotalFunds += metrics.totalFunds;

        // Loan Books
        const loanBookNonPerforming: number = metrics.totalLoanBook - metrics.loanBookPerforming;
        const loanBookNonPerformingPercentage: number = (loanBookNonPerforming / metrics.totalLoanBook) || 0;

        restructuredPortfolioMetrics[PortfolioMetricEnum.TotalLoanBook].warehouses[metrics.warehouseType] = metrics.totalLoanBook;
        totalGroupLoanBookTotal += metrics.totalLoanBook;

        restructuredPortfolioMetrics[PortfolioMetricEnum.LoanBookPerforming].warehouses[metrics.warehouseType] = metrics.loanBookPerforming;
        restructuredPortfolioMetrics[PortfolioMetricEnum.LoanBookPerforming].total += metrics.loanBookPerforming;

        restructuredPortfolioMetrics[PortfolioMetricEnum.LoanBookNonPerforming].warehouses[metrics.warehouseType] = loanBookNonPerforming;
        totalGroupLoanBookNonPerforming += loanBookNonPerforming;

        restructuredPortfolioMetrics[PortfolioMetricEnum.LoanBookNonPerformingPercentage].warehouses[metrics.warehouseType] = loanBookNonPerformingPercentage;

        // Total Retained
        restructuredPortfolioMetrics[PortfolioMetricEnum.TotalRetained].warehouses[metrics.warehouseType] = metrics.retainedFunds + metrics.retainedContingency;
        restructuredPortfolioMetrics[PortfolioMetricEnum.TotalRetained].total += metrics.retainedFunds + metrics.retainedContingency;

        // Available Funds
        restructuredPortfolioMetrics[PortfolioMetricEnum.AvailableFunds].warehouses[metrics.warehouseType] = metrics.availableFunds;
        restructuredPortfolioMetrics[PortfolioMetricEnum.AvailableFunds].total += metrics.availableFunds;

        // Utilisation Rate
        const utilisationRate: number = (metrics.totalLoanBook * -1 / metrics.totalFunds) || 0;
        restructuredPortfolioMetrics[PortfolioMetricEnum.UtilisationRate].warehouses[metrics.warehouseType] = utilisationRate;

        // Weighted LVR
        restructuredPortfolioMetrics[PortfolioMetricEnum.WeightedLvr].warehouses[metrics.warehouseType] = metrics.weightedLvr;
        totalGroupWeightedLvrValue += metrics.totalWeightedLvrValue;
        totalGroupLoansTotalAmountRemaining += metrics.totalAmountRemaining;

        // Cost of Funds
        restructuredPortfolioMetrics[PortfolioMetricEnum.CostOfFunds].warehouses[metrics.warehouseType] = metrics.costOfFunds;

        // These are the funds to which costs can be applied, for FIT we apply only to the loan book total,
        // for all other warehouses we apply to the total funds
        const costableFunds: number = WarehouseTypeEnum.Fit === metrics.warehouseType ? metrics.totalLoanBook * -1 : metrics.totalFunds;
        totalGroupCostableFunds += costableFunds;

        const costOfFundsValue: number = metrics.costOfFunds / 100 * costableFunds;
        totalGroupCostOfFundsValue += costOfFundsValue;

        // Cash Metrics
        restructuredPortfolioMetrics[PortfolioMetricEnum.ActualYield].warehouses[metrics.warehouseType] = metrics.actualYield;
        totalGroupTotalWeightedInterestRateValueActual += metrics.totalWeightedInterestRateValueActual;

        const cashNimValue: number = (metrics.actualYield / 100) * Math.abs(metrics.totalLoanBook) - costOfFundsValue;
        restructuredPortfolioMetrics[PortfolioMetricEnum.NimPerformingValue].warehouses[metrics.warehouseType] = cashNimValue;

        const cashNimPercentage: number = (cashNimValue / metrics.totalLoanBook * -1) || 0;
        restructuredPortfolioMetrics[PortfolioMetricEnum.NimPerformingPercentage].warehouses[metrics.warehouseType] = cashNimPercentage;

        // Contract Metrics
        restructuredPortfolioMetrics[PortfolioMetricEnum.OptimalYield].warehouses[metrics.warehouseType] = metrics.optimalYield;
        totalGroupTotalWeightedInterestRateValueOptimal += metrics.totalWeightedInterestRateValueOptimal;

        const contractNimValue: number = (metrics.optimalYield / 100) * Math.abs(metrics.totalLoanBook) - costOfFundsValue;
        restructuredPortfolioMetrics[PortfolioMetricEnum.NimTotalValue].warehouses[metrics.warehouseType] = contractNimValue;

        const contractNimPercentage: number = (contractNimValue / metrics.totalLoanBook * -1) || 0;
        restructuredPortfolioMetrics[PortfolioMetricEnum.NimTotalPercentage].warehouses[metrics.warehouseType] = contractNimPercentage;
    });

    restructuredPortfolioMetrics[PortfolioMetricEnum.TotalFunds].total = totalGroupTotalFunds;
    restructuredPortfolioMetrics[PortfolioMetricEnum.TotalLoanBook].total = totalGroupLoanBookTotal;
    restructuredPortfolioMetrics[PortfolioMetricEnum.LoanBookNonPerformingPercentage].total = (totalGroupLoanBookNonPerforming / totalGroupLoanBookTotal) || 0;
    restructuredPortfolioMetrics[PortfolioMetricEnum.LoanBookNonPerforming].total = totalGroupLoanBookNonPerforming;
    restructuredPortfolioMetrics[PortfolioMetricEnum.UtilisationRate].total = (totalGroupLoanBookTotal * -1 / totalGroupTotalFunds);
    restructuredPortfolioMetrics[PortfolioMetricEnum.WeightedLvr].total = totalGroupWeightedLvrValue / totalGroupLoansTotalAmountRemaining;
    restructuredPortfolioMetrics[PortfolioMetricEnum.CostOfFunds].total = totalGroupCostOfFundsValue / totalGroupCostableFunds * 100;
    restructuredPortfolioMetrics[PortfolioMetricEnum.ActualYield].total = totalGroupTotalWeightedInterestRateValueActual / totalGroupLoansTotalAmountRemaining;
    restructuredPortfolioMetrics[PortfolioMetricEnum.NimPerformingValue].total = (restructuredPortfolioMetrics[PortfolioMetricEnum.ActualYield].total / 100) * totalGroupLoanBookTotal * -1 - totalGroupCostOfFundsValue;
    restructuredPortfolioMetrics[PortfolioMetricEnum.NimPerformingPercentage].total = (restructuredPortfolioMetrics[PortfolioMetricEnum.NimPerformingValue].total / totalGroupLoanBookTotal * -1) || 0;
    restructuredPortfolioMetrics[PortfolioMetricEnum.OptimalYield].total = totalGroupTotalWeightedInterestRateValueOptimal / totalGroupLoansTotalAmountRemaining;
    restructuredPortfolioMetrics[PortfolioMetricEnum.NimTotalValue].total = (restructuredPortfolioMetrics[PortfolioMetricEnum.OptimalYield].total / 100) * totalGroupLoanBookTotal * -1 - totalGroupCostOfFundsValue;
    restructuredPortfolioMetrics[PortfolioMetricEnum.NimTotalPercentage].total = (restructuredPortfolioMetrics[PortfolioMetricEnum.NimTotalValue].total / totalGroupLoanBookTotal * -1) || 0;

    const columns: ColumnType<IRestructuredMetrics>[] = [
        {
            className: 'metric-name',
            dataIndex: 'metric',
            render: (metric: PortfolioMetricEnum) => metricsLabels[metric],
            title: '',
        },
        {
            align: 'right',
            render: (row: IRestructuredMetrics) => rowFormatter(row, row.warehouses[WarehouseTypeEnum.Fit]),
            title: WarehouseTypeEnum.Fit,
            width: '14%',
        },
        {
            align: 'right',
            render: (row: IRestructuredMetrics) => rowFormatter(row, row.warehouses[WarehouseTypeEnum.Fin]),
            title: WarehouseTypeEnum.Fin,
            width: '14%',
        },
        {
            align: 'right',
            render: (row: IRestructuredMetrics) => rowFormatter(row, row.warehouses[WarehouseTypeEnum.Fnom]),
            title: WarehouseTypeEnum.Fnom,
            width: '14%',
        },
        {
            align: 'right',
            render: (row: IRestructuredMetrics) => rowFormatter(row, row.warehouses[WarehouseTypeEnum.Fwt1]),
            title: WarehouseTypeEnum.Fwt1,
            width: '14%',
        },
        {
            align: 'right',
            render: (row: IRestructuredMetrics) => rowFormatter(row, row.warehouses[WarehouseTypeEnum.Fwt2]),
            title: WarehouseTypeEnum.Fwt2,
            width: '14%',
        },
        {
            align: 'right',
            render: (row: IRestructuredMetrics) => rowFormatter(row, row.total),
            title: 'Total Group',
            width: '14%',
        },
    ];
    return (
        <Layout className='warehouses'>
            <Breadcrumb className='breadcrumb'>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item><Link to='/warehouses'>Warehouses</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Portfolio Dashboard</Breadcrumb.Item>
            </Breadcrumb>
            <Layout className='content-wrapper'>
                <Layout.Content className='content'>
                    <Typography.Title level={2}>Warehouses Portfolio Dashboard</Typography.Title>
                    <Table
                        columns={columns}
                        className='portfolio-dashboard'
                        dataSource={_.values(restructuredPortfolioMetrics)}
                        pagination={false}
                        rowKey='metric'
                        size='middle'
                    />
                </Layout.Content>
            </Layout>
        </Layout>
    );
}
