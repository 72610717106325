import { all, call, put, takeEvery } from '@redux-saga/core/effects';
import IInvestmentTransaction from '~Api/Investment/IInvestmentTransaction';
import { parseInvestmentTransaction } from '~Api/Investment/parsers';
import IInvestorAccountInvestmentTransaction from '~Api/Investor/IInvestorAccountInvestmentTransaction';
import IInvestorAccountTransaction from '~Api/Investor/IInvestorAccountTransaction';
import {
    parseInvestorAccountInvestmentTransaction,
    parseInvestorAccountTransaction,
} from '~Api/Investor/parsers';
import ILoanTransaction from '~Api/Loan/ILoanTransaction';
import { parseLoanTransaction } from '~Api/Loan/parsers';
import IWarehouseLoanTransaction from '~Api/Warehouse/IWarehouseLoanTransaction';
import { parseWarehouseLoanTransaction } from '~Api/Warehouse/parsers';
import { IFetchResponse } from '~utilities/fetch';
import {
    IInvestorMovementsListAction,
    ILoanPaymentsListAction,
    ILoanTransactionWarehouseLoanTransactionsListAction,
    IPaymentsFromInvestorsListAction,
    IPaymentsToInvestorsListAction,
    IWarehouseMovementsListAction,
    investorMovementsSetAction,
    loanPaymentsSetAction,
    loanTransactionWarehouseLoanTransactionsSetAction,
    paymentsFromInvestorsSetAction,
    paymentsToInvestorsSetAction,
    warehouseMovementsSetAction,
} from './actions';
import AccountsActionsEnum from './ActionsEnum';
import {
    investmentTransactionsRecentRequest,
    investorAccountInvestmentTransactionsRecentInvestmentsRequest,
    investorAccountTransactionsRecentTransfersRequest,
    loanTransactionWarehouseTransactionsRequest,
    loanTransactionsRecentPaymentsRequest,
    warehouseLoanTransactionsMovementReportRequest,
} from './requests';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* investorMovementsList(action: IInvestorMovementsListAction): Iterator<unknown> {
    const investorAccountTransactionsRecentTransfersResponse: IFetchResponse = yield call(investorAccountTransactionsRecentTransfersRequest);
    const investorAccountTransactions: IInvestorAccountTransaction[] = yield Promise.all(investorAccountTransactionsRecentTransfersResponse.body.map(parseInvestorAccountTransaction));
    yield put(investorMovementsSetAction(investorAccountTransactions));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* loanPaymentsList(action: ILoanPaymentsListAction): Iterator<unknown> {
    const loanTransactionsRecentPaymentsResponse: IFetchResponse = yield call(loanTransactionsRecentPaymentsRequest);
    const loanTransactions: ILoanTransaction[] = yield Promise.all(loanTransactionsRecentPaymentsResponse.body.map(parseLoanTransaction));
    yield put(loanPaymentsSetAction(loanTransactions));
}

function* loanTransactionWarehouseLoanTransactionsList(action: ILoanTransactionWarehouseLoanTransactionsListAction): Iterator<unknown> {
    const loanTransactionWarehouseLoanTransactionsListResponse: IFetchResponse = yield call(loanTransactionWarehouseTransactionsRequest, action.loanTransactionUuid);
    const warehouseLoanTransactions: IWarehouseLoanTransaction[] = yield Promise.all(loanTransactionWarehouseLoanTransactionsListResponse.body.map(parseWarehouseLoanTransaction));
    yield put(loanTransactionWarehouseLoanTransactionsSetAction(action.loanTransactionUuid, warehouseLoanTransactions));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* paymentsFromInvestorsList(action: IPaymentsFromInvestorsListAction): Iterator<unknown> {
    const investorAccountInvestmentTransactionsRecentResponse: IFetchResponse = yield call(investorAccountInvestmentTransactionsRecentInvestmentsRequest);
    const investorAccountInvestmentTransactions: IInvestorAccountInvestmentTransaction[] = yield Promise.all(investorAccountInvestmentTransactionsRecentResponse.body.map(parseInvestorAccountInvestmentTransaction));
    yield put(paymentsFromInvestorsSetAction(investorAccountInvestmentTransactions));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* paymentsToInvestorsList(action: IPaymentsToInvestorsListAction): Iterator<unknown> {
    const investmentTransactionsRecentResponse: IFetchResponse = yield call(investmentTransactionsRecentRequest);
    const investmentTransactions: IInvestmentTransaction[] = yield Promise.all(investmentTransactionsRecentResponse.body.map(parseInvestmentTransaction));
    yield put(paymentsToInvestorsSetAction(investmentTransactions));
}

function* warehouseMovementsList(action: IWarehouseMovementsListAction): Iterator<unknown> {
    const warehouseLoanTransactionsRecentPrincipalResponse: IFetchResponse = yield call(warehouseLoanTransactionsMovementReportRequest, action.startDate, action.endDate);
    const warehouseLoanTransactions: IWarehouseLoanTransaction[] = yield Promise.all(warehouseLoanTransactionsRecentPrincipalResponse.body.map(parseWarehouseLoanTransaction));
    yield put(warehouseMovementsSetAction(action.startDate, action.endDate, warehouseLoanTransactions));
}

export function* AccountsSagas(): Iterator<unknown> {
    yield all([
        takeEvery(AccountsActionsEnum.InvestorMovementsList, investorMovementsList),
        takeEvery(AccountsActionsEnum.LoanPaymentsList, loanPaymentsList),
        takeEvery(AccountsActionsEnum.LoanTransactionWarehouseLoanTransactionsList, loanTransactionWarehouseLoanTransactionsList),
        takeEvery(AccountsActionsEnum.PaymentsFromInvestorsList, paymentsFromInvestorsList),
        takeEvery(AccountsActionsEnum.PaymentsToInvestorsList, paymentsToInvestorsList),
        takeEvery(AccountsActionsEnum.WarehouseMovementsList, warehouseMovementsList),
    ]);
}
