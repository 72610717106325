import { Button, Typography } from 'antd';
import React, { ReactElement } from 'react';
import Layout from './Layout';

export default function LoansBorrowers(): ReactElement {
    return (
        <Layout section='loans-borrowers-export'>
            <Typography.Title level={2}>Loans - Borrowers</Typography.Title>
            <Button
                href={`${process.env.API_HOST}/loans/download-borrower-report`}
                target='_blank'
                type='primary'
            >Download</Button>
        </Layout>
    );
}
