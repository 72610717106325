import { Select } from 'antd';
import React, { ReactElement, useCallback, useState } from 'react';

interface IOption {
    label: string;
    value: string;
}

export default function SearchSelect(props: {
    defaultText?: string;
    loading: boolean;
    onSearch: (keyword: string) => void;
    onSelect: (value: IOption['value'], option: IOption) => void;
    onSearchClear: () => void;
    options: IOption[];
    value: string;
}): ReactElement {
    const {
        defaultText,
        loading,
        onSelect: parentOnSelect,
        onSearch: parentOnSearch,
        onSearchClear,
        options,
        value,
    } = props;
    // Flag to stop antd from resetting search(sending '') on first render
    const [ searchEntered, setSearchEntered ] = useState<boolean>(false);
    // Flag to stop antd from resetting search(sending '') on select
    const [ selected, setSelected ] = useState<boolean>(false);
    // Flag to Open/close the dropdown as antd has some default open settings on clear
    const [ open, setOpen ] = useState<boolean>(false);
    const [ showArrow, setShowArrow ] = useState<boolean>(false);
    // Search Text has initial state, but most state is self contained, simplifying overall implementation for now
    const [ searchText, setSearchText ] = useState<string>(value !== null ? defaultText : '');

    const onSelect: (value: string, option: IOption) => void = useCallback((value: string, option: IOption) => {
        parentOnSelect(value, option);
        setSearchText(option.label);
        onSearchClear();
        setOpen(false);
        setSelected(true);
        setShowArrow(false);
    }, [
        parentOnSelect,
        onSearchClear,
        setSearchText,
        setSelected,
    ]);

    const onSearch: (keyword: string) => void = useCallback((keyword: string) => {
        // Avoid antd empty string search on initialization and selection
        if (searchEntered && !(keyword === '' && selected)) {
            setShowArrow(true);
            setSearchText(keyword);
            setOpen(true);
            if (keyword) {
                parentOnSearch(keyword);
            }
        }

        // Reset on selection in the case of empty string search by user
        if (selected && keyword === '') {
            setSelected(false);
        }
    }, [
        parentOnSearch,
        searchEntered,
        selected,
        setSelected,
        setSearchText,
    ]);

    const onFocus: () => void = useCallback(() => {
        setSearchEntered(true);
    }, [
        setSearchEntered,
    ]);

    const onBlur: () => void = useCallback(() => {
        setSearchEntered(false);
        setSearchText(value !== null ? defaultText : '');
        onSearchClear();
        setOpen(false);
        setShowArrow(false);
    }, [
        defaultText,
        onSearchClear,
        setSearchEntered,
        setSearchText,
        value,
    ]);

    return (
        <Select
            filterOption={false}
            notFoundContent={null}
            showArrow={showArrow}
            showSearch={true}
            autoClearSearchValue={false}
            open={open}
            onBlur={onBlur}
            onFocus={onFocus}
            onSearch={onSearch}
            onSelect={onSelect}
            options={options}
            searchValue={searchText}
            loading={loading}
        />
    );
}
