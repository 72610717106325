import _ from 'lodash';
import FetchResponseError from './FetchResponseError';
import iconv from 'iconv-lite';
import { verifyToken } from './jwt';
import { doLogout } from '~Auth/sagas';

export interface IFetchResponse {
    body: any;
    headers: Headers;
    status: number;
}

type ParametersType = Record<string, boolean | string | number | string[]>;

function parameteriseUrl(url: string, parameters: ParametersType): string {
    const uriParts: string[] = [];

    _.keys(parameters).forEach((key: string) => {
        if (null === parameters[key]) {
            return;
        }

        if (typeof parameters[key] === 'object') {
            // Create PHP compatible array parameters
            (parameters[key] as string[]).forEach((value: string) => {
                uriParts.push(`${encodeURIComponent(key)}[]=${encodeURIComponent(value)}`);
            });
        } else if (typeof parameters[key] === 'boolean') {
            uriParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(parameters[key] ? 1 : 0)}`);
        } else {
            // Regular parameters
            uriParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(parameters[key] as any)}`);
        }
    });

    return 0 === uriParts.length ? url : url + '?' + uriParts.join('&');
}

// Please note we always verify the token on requests - DO NOT use the decoded token in local storage
export async function fetchRequest(url: string, init?: RequestInit): Promise<Response> {
    const token: string = localStorage.getItem('token');

    const headers: any = init && init.headers ? { ...init.headers } : {};

    if (token) {
        try {
            verifyToken(token);
        } catch (e: any) {
            switch (e.name) {
                case 'TokenExpiredError':
                    doLogout();
                    (document as any).location = '/login';
            }

            throw e;
        }

        headers['Authorization'] = `Bearer ${token}`;
    }

    // tslint:disable-next-line: no-string-literal
    headers['Accept'] = 'application/json';
    if (!headers['Content-Type']) {
        headers['Content-Type'] = 'application/json';
    }

    return fetch(`${process.env.API_HOST}${url}`, {
        ...init,
        headers,
    }).then((response: Response): Promise<any> => {
        return response.json().then((jsonResponse: any) => {
            if (![200, 422].includes(response.status)) {
                throw new FetchResponseError(response.status);
            }

            return {
                body: jsonResponse,
                headers: response.headers,
                status: response.status,
            };
        });
    });
}

export async function fetchGet(url: string, parameters?: ParametersType): Promise<Response> {
    return fetchRequest(parameteriseUrl(url, parameters), {
        method: 'GET',
    });
}

export async function fetchDelete(url: string): Promise<Response> {
    return fetchRequest(url, {
        method: 'DELETE',
    });
}

export async function fetchPost(url: string, body?: any, parameters?: ParametersType): Promise<Response> {
    return fetchRequest(parameteriseUrl(url, parameters), {
        body: JSON.stringify(body),
        method: 'POST',
    });
}

export async function fetchPostFile(url: string, body: File): Promise<Response> {
    return fetchRequest(url, {
        body,
        headers: {
            'Content-Type': body.type,
            // Convert to ISO 8859-1 to remove unusual characters
            'Filename': iconv.encode(body.name, 'iso-8859-1').toString(),
        },
        method: 'POST',
    });
}

export async function fetchPut(url: string, body?: any): Promise<Response> {
    return fetchRequest(url, {
        body: JSON.stringify(body),
        method: 'PUT',
    });
}
