import { Alert, Button, Modal, Space, Spin, Table, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link, match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import roundTo from 'round-to';
import IApplication from '~Api/Application/IApplication';
import IApplicationWarehouse from '~Api/Application/IApplicationWarehouse';
import WorkflowStatusEnum from '~Api/Application/WorkflowStatusEnum';
import IWarehouse from '~Api/Warehouse/IWarehouse';
import WarehouseTypeEnum from '~Api/Warehouse/WarehouseTypeEnum';
import {
    applicationGetAction,
    applicationWarehouseDeleteAction,
    applicationWarehousesListAction,
} from '~Applications/actions';
import WarehouseAddModal from '~Applications/Application/WarehouseAddModal';
import WarehouseEditModal from '~Applications/Application/WarehouseEditModal';
import {
    applicationSelector,
    applicationWarehousesSelector,
} from '~Applications/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { warehousesListAction } from '~Warehouses/actions';
import { warehousesSelector } from '~Warehouses/selectors';
import Layout from './Layout';
import { currencyFormatter } from '~utilities/formatters';

interface IState {
    editApplicationWarehouseUuid: string;
    isAddModalOpen: boolean;
}

interface IMatch {
    applicationUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    application: IApplication;
    applicationWarehouses: IDictionary<IApplicationWarehouse>;
    warehouses: IDictionary<IWarehouse>;
}

interface IPropsDispatch {
    applicationGet: () => void;
    applicationWarehouseDelete: (uuid: string) => void;
    applicationWarehousesList: () => void;
    warehousesList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Warehouses extends React.Component<Props> {
    public state: IState = {
        editApplicationWarehouseUuid: null,
        isAddModalOpen: false,
    };

    constructor(props: Props) {
        super(props);

        this.onClickAdd = this.onClickAdd.bind(this);
        this.onClickEdit = this.onClickEdit.bind(this);
        this.onCloseAddModal = this.onCloseAddModal.bind(this);
        this.onCloseEditModal = this.onCloseEditModal.bind(this);
    }

    public componentDidMount(): void {
        const { application, warehouses } = this.props;

        this.props.applicationWarehousesList();

        if (!application) {
            this.props.applicationGet();
        }

        if (!warehouses) {
            this.props.warehousesList();
        }
    }

    public render(): JSX.Element {
        const { application, applicationWarehouses, match, warehouses } = this.props;
        const { editApplicationWarehouseUuid, isAddModalOpen } = this.state;

        if (!applicationWarehouses || !application || !warehouses) {
            return (
                <Layout applicationUuid={match.params.applicationUuid} section='warehouses'>
                    <Typography.Title level={2}>Warehouses</Typography.Title>
                    <Spin />
                </Layout>
            );
        }

        const applicationIsSettled: boolean = application.workflowStatus === WorkflowStatusEnum.Warehoused;

        const columns: ColumnType<IApplicationWarehouse>[] = [
            {
                defaultSortOrder: 'ascend',
                render: (applicationWarehouse: IApplicationWarehouse) => <Link to={`/warehouses/${applicationWarehouse.warehouseUuid}/loans`}>{warehouses[applicationWarehouse.warehouseUuid].name}</Link>,
                title: 'Warehouse',
            },
            {
                dataIndex: 'amount',
                render: (amount: number) => currencyFormatter.format(amount),
                title: 'Amount',
                width: '15%',
            },
        ];

        if (!applicationIsSettled) {
            columns.push({
                render: (applicationWarehouse: IApplicationWarehouse) => {
                    const onClickEdit: () => void = () => this.onClickEdit(applicationWarehouse.uuid);

                    const onClickDelete: () => void = () => {
                        Modal.confirm({
                            content: 'Are you sure you want to delete this warehouse?',
                            okText: 'Delete',
                            okType: 'danger',
                            onOk: () => {
                                this.props.applicationWarehouseDelete(applicationWarehouse.uuid);
                            },
                            title: 'Delete',
                        });
                    };

                    return (
                        <Space>
                            <Button onClick={onClickEdit}>Edit</Button>
                            <Button danger={true} onClick={onClickDelete}>Delete</Button>
                        </Space>
                    );
                },
                title: 'Actions',
                width: '15%',
            });
        }

        const addBlock: JSX.Element = !applicationIsSettled && (
            <Button onClick={this.onClickAdd}>Add Warehouse</Button>
        );

        const alerts: JSX.Element[] = [];

        const applicationWarehouseCount: number = _.size(applicationWarehouses);

        let applicationWarehousesTotalAmount: number = 0;
        let applicationHasPrivateWarehouse: boolean = false;
        let applicationHasFwt1Warehouse: boolean = false;

        _.each(applicationWarehouses, (applicationWarehouse: IApplicationWarehouse) => {
            const warehouse: IWarehouse = warehouses[applicationWarehouse.warehouseUuid];

            applicationWarehousesTotalAmount += applicationWarehouse.amount;

            if (warehouse.type === WarehouseTypeEnum.Private) {
                applicationHasPrivateWarehouse = true;
            } else if (warehouse.type === WarehouseTypeEnum.Fwt1) {
                applicationHasFwt1Warehouse = true;
            }
        });

        if (applicationHasPrivateWarehouse && applicationWarehouseCount > 1) {
            alerts.push(<Alert key='alert-private-warehouse' message='Only one warehouse can be selected when using private funding' type='error' />);
        }

        if (applicationHasFwt1Warehouse && applicationWarehouseCount > 1) {
            alerts.push(<Alert key='alert-fwt1-warehouse' message='Only one warehouse can be selected when using FWT1' type='error' />);
        }

        if (roundTo(applicationWarehousesTotalAmount, 2) !== roundTo(application.loanAmount, 2)) {
            alerts.push(<Alert key='alert-warehouse-total' message={`Warehouses total ${currencyFormatter.format(applicationWarehousesTotalAmount)} does not match application amount ${currencyFormatter.format(application.loanAmount)}`} type='error' />);
        }

        const offerToSellBlock: JSX.Element = !applicationIsSettled && !!applicationHasFwt1Warehouse && (
            <Button
                href={`${process.env.API_HOST}/applications/${application.uuid}/download-offer-to-sell`}
                target='_blank'
            >
                Download Offer To Sell
            </Button>
        );

        return (
            <Layout applicationUuid={match.params.applicationUuid} section='warehouses'>
                <Space className='actions'>
                    {addBlock}
                    {offerToSellBlock}
                </Space>
                <Typography.Title level={2}>Warehouses</Typography.Title>
                <>{alerts}</>
                <Table
                    columns={columns}
                    dataSource={_.values(applicationWarehouses)}
                    pagination={false}
                    rowKey='uuid'
                    size='middle'
                />
                <WarehouseEditModal
                    applicationUuid={application.uuid}
                    applicationWarehouseUuid={editApplicationWarehouseUuid}
                    isOpen={!!editApplicationWarehouseUuid}
                    onClose={this.onCloseEditModal}
                />
                <WarehouseAddModal
                    applicationUuid={application.uuid}
                    isOpen={isAddModalOpen}
                    onClose={this.onCloseAddModal}
                />
            </Layout>
        );
    }

    private onClickAdd(): void {
        this.setState({
            isAddModalOpen: true,
        });
    }

    private onClickEdit(editApplicationWarehouseUuid: string): void {
        this.setState({
            editApplicationWarehouseUuid,
        });
    }

    private onCloseAddModal(): void {
        this.setState({
            isAddModalOpen: false,
        });
    }

    private onCloseEditModal(): void {
        this.setState({
            editApplicationWarehouseUuid: null,
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        application: applicationSelector(state, ownProps.match.params.applicationUuid),
        applicationWarehouses: applicationWarehousesSelector(state, ownProps.match.params.applicationUuid),
        warehouses: warehousesSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        applicationGet: () => dispatch(applicationGetAction(ownProps.match.params.applicationUuid)),
        applicationWarehouseDelete: (uuid: string) => dispatch(applicationWarehouseDeleteAction(uuid)),
        applicationWarehousesList: () => dispatch(applicationWarehousesListAction(ownProps.match.params.applicationUuid)),
        warehousesList: () => dispatch(warehousesListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Warehouses);
