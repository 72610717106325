import { Breadcrumb, Dropdown, Layout, MenuProps, Modal, Spin, Table, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import dayjs from 'dayjs';
import _ from 'lodash';
import { leadsPendingQuotesSelector } from './selectors';
import { administratorsSelector } from '~Administrators/selectors';
import { dealQuoteApproveAction } from '~Deals/actions';
import { administratorsListAction } from '~Administrators/actions';
import { leadsPendingQuotesListAction } from './actions';
import IAdministrator from '~Api/Administrator/IAdministrator';
import IDeal from '~Api/Deal/IDeal';
import { IDictionary } from '~utilities/IDictionary';
import IQuote from '~Api/Deal/IQuote';
import QuoteRejectModal from './Lead/QuoteRejectModal';
import {
    currencyFormatter,
    percentageFormatter,
} from '~utilities/formatters';

export default function PendingQuotes(): ReactElement {
    const [rejectQuoteUuid, setRejectQuoteUuid] = useState<string>(null);

    const administrators: IDictionary<IAdministrator> = useSelector(administratorsSelector);
    const pendingQuotes: IDictionary<IQuote> = useSelector(leadsPendingQuotesSelector);

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!administrators) {
            dispatch(administratorsListAction());
        }
    }, [
        administrators,
        dispatch,
    ]);

    useEffect(() => {
        if (!pendingQuotes) {
            dispatch(leadsPendingQuotesListAction());
        }
    }, [
        dispatch,
        pendingQuotes,
    ]);

    if (!administrators || !pendingQuotes) {
        return (
            <Layout className='leads-pending-quotes'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item><a href='/'>Home</a></Breadcrumb.Item>
                    <Breadcrumb.Item><Link to='/leads'></Link></Breadcrumb.Item>
                    <Breadcrumb.Item>Pending Scenarios</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Typography.Title level={2}>Pending Scenarios</Typography.Title>
                        <Spin/>
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }

    const pendingQuoteAdministrators: IDictionary<IAdministrator> = {};
    _.each(pendingQuotes, (pendingQuote: IQuote) => {
        pendingQuoteAdministrators[pendingQuote.administratorUuid] = administrators[pendingQuote.administratorUuid];
    });

    const columns: ColumnType<IQuote>[] = [
        {
            dataIndex: 'code',
            fixed: 'left',
            render: (code: string, quote: IQuote) => <a href={`${process.env.API_HOST}/deal-quotes/${quote.uuid}/pdf`} target='_blank' rel='noreferrer'>{code}</a>,
            title: 'Code',
            width: 150,
        },
        {
            dataIndex: ['deal'],
            fixed: 'left',
            render: (deal: IDeal) => <Link to={`/leads/${deal.uuid}`}>{deal.code}</Link>,
            title: 'Lead',
            width: 150,
        },
        {
            dataIndex: ['deal', 'formattedName'],
            title: 'Name',
            width: 300,
        },
        {
            dataIndex: 'administratorUuid',
            filters: _.values(pendingQuoteAdministrators).map((administrator: IAdministrator) => ({
                text: administrator.name,
                value: administrator.uuid,
            })),
            onFilter: (value: string | number | boolean, quote: IQuote) => quote.administratorUuid === value,
            render: (administratorUuid: string) => administrators[administratorUuid].name,
            title: 'Assignee',
            width: 200,
        },
        {
            dataIndex: 'loanAmount',
            render: (loanAmount: number) => currencyFormatter.format(loanAmount),
            title: 'Amount',
            width: 150,
        },
        {
            dataIndex: 'termMonths',
            render: (termMonths: number) => `${termMonths} months`,
            title: 'Term',
            width: 150,
        },
        {
            dataIndex: 'lvr',
            render: (lvrRate: number) => percentageFormatter.format(lvrRate / 100),
            title: 'LVR',
            width: 100,
        },
        {
            dataIndex: 'interestRate',
            render: (interestRate: number) => percentageFormatter.format(interestRate / 100),
            title: 'Rate',
            width: 100,
        },
        {
            dataIndex: 'createdTime',
            defaultSortOrder: 'descend',
            render: (createdTime: string) => dayjs(createdTime).format('DD/MM/YYYY HH:mm'),
            sorter: (a: IQuote, b: IQuote) => dayjs(a.createdTime) > dayjs(b.createdTime) ? 1 : -1,
            title: 'Date',
            width: 160,
        },
        {
            fixed: 'right',
            render: (pendingQuote: IQuote) => {
                const onClickApprove: () => void = () => {
                    Modal.confirm({
                        content: 'Are you sure you want to approve this quote?',
                        okText: 'Approve',
                        onOk: () => {
                            dispatch(dealQuoteApproveAction(pendingQuote.uuid));
                        },
                        title: 'Approve Quote',
                    });
                };
                const onCancelRejectQuote: () => void = () => {
                    setRejectQuoteUuid(null);
                };

                const actionsMenu: MenuProps = {
                    items: [
                        {
                            key: 'reject',
                            label: 'Reject',
                            onClick: () => {
                                setRejectQuoteUuid(pendingQuote.uuid);
                            },
                        },
                    ],
                };

                return (
                    <>
                        <Dropdown.Button onClick={onClickApprove} type='primary' menu={actionsMenu}>Approve</Dropdown.Button>
                        <QuoteRejectModal
                            isOpen={rejectQuoteUuid === pendingQuote.uuid}
                            onCancel={onCancelRejectQuote}
                            quoteUuid={rejectQuoteUuid}
                        />
                    </>
                );
            },
            width: 160,
        },
    ];

    return (
        <Layout className='leads-pending-quotes'>
            <Breadcrumb className='breadcrumb'>
                <Breadcrumb.Item><a href='/'>Home</a></Breadcrumb.Item>
                <Breadcrumb.Item><Link to='/leads'>Leads</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Pending Scenarios</Breadcrumb.Item>
            </Breadcrumb>
            <Layout className='content-wrapper'>
                <Layout.Content className='content'>
                    <Typography.Title level={2}>Pending Scenarios</Typography.Title>
                    <Table
                        columns={columns}
                        dataSource={_.values(pendingQuotes)}
                        pagination={false}
                        rowKey='uuid'
                        scroll={{ x: 1620 }}
                    />
                </Layout.Content>
            </Layout>
        </Layout>
    );
}