import { CloudDownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Modal, Spin, Table, Tooltip, Typography, Upload } from 'antd';
import { ColumnType } from 'antd/lib/table';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IDocument from '~Api/ReferralPartner/IDocument';
import IReferralPartner from '~Api/ReferralPartner/IReferralPartner';
import { IGlobalState } from '~reducer';
import {
    referralPartnerDocumentDeleteAction,
    referralPartnerDocumentsAddAction,
    referralPartnerDocumentsListAction,
    referralPartnerGetAction,
} from '~ReferralPartners/actions';
import {
    referralPartnerDocumentsSelector,
    referralPartnerSelector,
} from '~ReferralPartners/selectors';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';

interface IMatch {
    uuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    documents: IDictionary<IDocument>;
    referralPartner: IReferralPartner;
}

interface IPropsDispatch {
    documentDelete: (documentUuid: string) => void;
    referralPartnerDocumentsAdd: (file: File) => void;
    referralPartnerDocumentsList: () => void;
    referralPartnerGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class DocumentsList extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.beforeUpload = this.beforeUpload.bind(this);
    }

    public componentDidMount(): void {
        const { documents, referralPartner } = this.props;

        if (!documents) {
            this.props.referralPartnerDocumentsList();
        }

        if (!referralPartner) {
            this.props.referralPartnerGet();
        }
    }

    public render(): JSX.Element {
        const { documents, referralPartner, match } = this.props;

        if (!documents || !referralPartner) {
            return (
                <Layout uuid={match.params.uuid} section='documents'>
                    <Typography.Title level={2}>Documents</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const columns: ColumnType<IDocument>[] = [
            {
                dataIndex: 'filename',
                title: 'Filename',
            },
            {
                render: (document: IDocument) => {
                    const onClickDelete: () => void = () => this.onClickDelete(document.uuid);

                    return (
                        <React.Fragment>
                            <Tooltip title='Download'>
                                <Button
                                    type='link'
                                    href={`${process.env.API_HOST}/referral-partners/${referralPartner.uuid}/documents/${document.uuid}/download`}
                                    target='_blank'
                                >
                                    <CloudDownloadOutlined/>
                                </Button>
                            </Tooltip>
                            <Tooltip title='Delete'>
                                <Button
                                    onClick={onClickDelete}
                                    type='link'
                                >
                                    <DeleteOutlined/>
                                </Button>
                            </Tooltip>
                        </React.Fragment>
                    );
                },
                title: 'Actions',
                width: '15%',
            },
        ];

        return (
            <Layout uuid={match.params.uuid} section='documents'>
                <Upload className='add-document' beforeUpload={this.beforeUpload}>
                    <Button>Add Document</Button>
                </Upload>
                <Typography.Title level={2}>Documents</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(documents)}
                    pagination={false}
                    rowKey='uuid'
                />
            </Layout>
        );
    }

    private beforeUpload(file: File): boolean {
        this.props.referralPartnerDocumentsAdd(file);

        return false;
    }

    private onClickDelete(documentUuid: string): void {
        Modal.confirm({
            content: 'Are you sure you want to delete this document?',
            okText: 'Delete',
            okType: 'danger',
            onOk: () => {
                this.props.documentDelete(documentUuid);
            },
            title: 'Delete',
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        documents: referralPartnerDocumentsSelector(state, ownProps.match.params.uuid),
        referralPartner: referralPartnerSelector(state, ownProps.match.params.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        documentDelete: (documentUuid: string) => dispatch(referralPartnerDocumentDeleteAction(ownProps.match.params.uuid, documentUuid)),
        referralPartnerDocumentsAdd: (file: File) => dispatch(referralPartnerDocumentsAddAction(ownProps.match.params.uuid, file)),
        referralPartnerDocumentsList: () => dispatch(referralPartnerDocumentsListAction(ownProps.match.params.uuid)),
        referralPartnerGet: () => dispatch(referralPartnerGetAction(ownProps.match.params.uuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DocumentsList);
