import { Button, Space, Spin, Table, Tabs, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import DisbursalTypeEnum from '~Api/Application/DisbursalTypeEnum';
import ILoan from '~Api/Loan/ILoan';
import ILoanTransaction from '~Api/Loan/ILoanTransaction';
import {
    loanGetAction,
    loanTransactionsListAction,
} from '~Loans/actions';
import {
    loanSelector,
    loanTransactionsSelector,
} from '~Loans/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';
import { currencyFormatter } from '~utilities/formatters';

interface ITransaction {
    amount: number;
    balance: number;
    description: string;
    transactionTime: string;
    uuid: string;
}

interface IMatch {
    uuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    loan: ILoan;
    transactions: IDictionary<ILoanTransaction>;
}

interface IPropsDispatch {
    loanGet: () => void;
    transactionsList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Transactions extends React.Component<Props> {
    public componentDidMount(): void {
        const { loan, transactions } = this.props;

        if (!loan) {
            this.props.loanGet();
        }

        if (!transactions) {
            this.props.transactionsList();
        }
    }

    public render(): JSX.Element {
        const { loan, match, transactions } = this.props;

        if (!loan || !transactions) {
            return (
                <Layout uuid={match.params.uuid} section='transactions'>
                    <Typography.Title level={2}>Transactions</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const statementColumns: ColumnType<ITransaction>[] = [
            {
                dataIndex: 'transactionTime',
                render: (transactionTime: string) => dayjs(transactionTime).format('D/M/YYYY'),
                title: 'Date',
                width: '10%',
            },
            {
                dataIndex: 'description',
                title: 'Description',
            },
            {
                align: 'right',
                dataIndex: 'amount',
                render: (amount: number) => amount < 0 ? currencyFormatter.format(amount) : null,
                title: 'Debit',
                width: '15%',
            },
            {
                align: 'right',
                dataIndex: 'amount',
                render: (amount: number) => amount > 0 ? currencyFormatter.format(amount) : null,
                title: 'Credit',
                width: '15%',
            },
            {
                align: 'right',
                dataIndex: 'balance',
                render: (balance: number) => currencyFormatter.format(balance),
                title: 'Balance',
                width: '15%',
            },
        ];

        let interestBalance: number = 0;
        let prepaidInterestBalance: number = 0;
        let principalBalance: number = 0;
        let retainedContingencyBalance: number = 0;
        let retainedFundsBalance: number = 0;
        const interestTransactions: ITransaction[] = [];
        const prepaidInterestTransactions: ITransaction[] = [];
        const principalTransactions: ITransaction[] = [];
        const cleanedTransactions: ITransaction[] = [];
        const retainedContingencyTransactions: ITransaction[] = [];
        const retainedFundsTransactions: ITransaction[] = [];

        let balance: number = 0;

        _.each(_.sortBy(transactions, ['transactionTime']), (loanTransaction: ILoanTransaction) => {
            if (loanTransaction.amountInterest !== 0 || loanTransaction.amountPrincipal !== 0) {
                balance += loanTransaction.amountInterest + loanTransaction.amountPrincipal;

                cleanedTransactions.push({
                    amount: loanTransaction.amountInterest + loanTransaction.amountPrincipal,
                    balance,
                    description: loanTransaction.description,
                    transactionTime: loanTransaction.transactionTime,
                    uuid: loanTransaction.uuid,
                });
            }

            if (loanTransaction.amountInterest !== 0) {
                interestBalance += loanTransaction.amountInterest;

                interestTransactions.push({
                    amount: loanTransaction.amountInterest,
                    balance: interestBalance,
                    description: loanTransaction.description,
                    transactionTime: loanTransaction.transactionTime,
                    uuid: loanTransaction.uuid,
                });
            }

            if (loanTransaction.amountPrepaidInterest !== 0) {
                prepaidInterestBalance += loanTransaction.amountPrepaidInterest;

                prepaidInterestTransactions.push({
                    amount: loanTransaction.amountPrepaidInterest,
                    balance: prepaidInterestBalance,
                    description: loanTransaction.description,
                    transactionTime: loanTransaction.transactionTime,
                    uuid: loanTransaction.uuid,
                });
            }

            if (loanTransaction.amountPrincipal !== 0) {
                principalBalance += loanTransaction.amountPrincipal;

                principalTransactions.push({
                    amount: loanTransaction.amountPrincipal,
                    balance: principalBalance,
                    description: loanTransaction.description,
                    transactionTime: loanTransaction.transactionTime,
                    uuid: loanTransaction.uuid,
                });
            }

            if (loanTransaction.retainedFunds !== 0 ) {
                retainedFundsBalance += loanTransaction.retainedFunds;

                retainedFundsTransactions.push({
                    amount: loanTransaction.retainedFunds,
                    balance: retainedFundsBalance,
                    description: loanTransaction.description,
                    transactionTime: loanTransaction.transactionTime,
                    uuid: loanTransaction.uuid,
                });
            }

            if (loanTransaction.retainedContingency !== 0 ) {
                retainedContingencyBalance += loanTransaction.retainedContingency;

                retainedContingencyTransactions.push({
                    amount: loanTransaction.retainedContingency,
                    balance: retainedContingencyBalance,
                    description: loanTransaction.description,
                    transactionTime: loanTransaction.transactionTime,
                    uuid: loanTransaction.uuid,
                });
            }
        });

        const columns: ColumnType<ITransaction>[] = [
            {
                dataIndex: 'transactionTime',
                defaultSortOrder: 'descend',
                render: (transactionTime: string) => dayjs(transactionTime).format('D/M/YYYY HH:mm'),
                sorter: (a: ITransaction, b: ITransaction) => {
                    return a.transactionTime > b.transactionTime ? 1 : -1;
                },
                title: 'Date',
                width: '15%',
            },
            {
                dataIndex: 'description',
                title: 'Description',
            },
            {
                dataIndex: 'amount',
                render: (amount: number) => currencyFormatter.format(amount),
                title: 'Amount',
                width: '15%',
            },
            {
                dataIndex: 'balance',
                render: (balance: number) => currencyFormatter.format(balance),
                title: 'Balance',
                width: '15%',
            },
        ];

        const statementButton: JSX.Element = (
            loan.paymentDateNext
            && dayjs(loan.paymentDateNext) > dayjs()
            && loan.amount === loan.amountRemaining
            && dayjs(loan.startDate) >= dayjs('2023-07-01')
        ) && (
            <Button
                href={`${process.env.API_HOST}/loans/${loan.uuid}/statement`}
                target='_blank'
            >
                Download Statement
            </Button>
        );

        const retainedFundsBlock: JSX.Element = loan.application.disbursalType === DisbursalTypeEnum.Drawdown && (
            <>
                <Tabs.TabPane key='retained-funds' tab='Retained Funds'>
                    <Table
                        columns={columns}
                        dataSource={retainedFundsTransactions}
                        pagination={false}
                        rowKey='uuid'
                        size='middle'
                    />
                </Tabs.TabPane>
                <Tabs.TabPane key='retained-contingency' tab='Retained Contingency'>
                    <Table
                        columns={columns}
                        dataSource={retainedContingencyTransactions}
                        pagination={false}
                        rowKey='uuid'
                        size='middle'
                    />
                </Tabs.TabPane>
            </>
        );

        return (
            <Layout uuid={match.params.uuid} section='transactions'>
                <Space className='actions'>
                    {statementButton}
                </Space>
                <Typography.Title level={2}>Transactions</Typography.Title>
                <Tabs defaultActiveKey='statement'>
                    <Tabs.TabPane key='statement' tab='Statement'>
                        <Table
                            columns={statementColumns}
                            dataSource={cleanedTransactions}
                            rowKey='uuid'
                            pagination={false}
                            size='middle'
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane key='principal' tab='Principal'>
                        <Table
                            columns={columns}
                            dataSource={principalTransactions}
                            pagination={false}
                            rowKey='uuid'
                            size='middle'
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane key='interest' tab='Interest'>
                        <Table
                            columns={columns}
                            dataSource={interestTransactions}
                            pagination={false}
                            rowKey='uuid'
                            size='middle'
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane key='prepaid-interest' tab='Prepaid Interest'>
                        <Table
                            columns={columns}
                            dataSource={prepaidInterestTransactions}
                            pagination={false}
                            rowKey='uuid'
                            size='middle'
                        />
                    </Tabs.TabPane>
                    {retainedFundsBlock}
                </Tabs>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        loan: loanSelector(state, ownProps.match.params.uuid),
        transactions: loanTransactionsSelector(state, ownProps.match.params.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        loanGet: () => dispatch(loanGetAction(ownProps.match.params.uuid)),
        transactionsList: () => dispatch(loanTransactionsListAction(ownProps.match.params.uuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Transactions);
