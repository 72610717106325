enum DealsActionsEnum {
    DealAssign = 'DEAL/ASSIGN',
    DealAssignProcessor = 'DEAL/ASSIGN_PROCESSOR',
    DealClose = 'DEAL/CLOSE',
    DealEstablishmentFeeMinimum = 'DEAL/ESTABLISHMENT_FEE_MINIMUM',
    DealFollowUp = 'DEAL/FOLLOW_UP',
    DealFollowUpTime = 'DEAL/FOLLOW_UP_TIME',
    DealInterestRateMinimum = 'DEAL/INTEREST_RATE_MINIMUM',
    DealLegalFeesDollarsMinimum = 'DEAL/LEGAL_FEES_DOLLARS_MINIMUM',
    DealLvrMaximum = 'DEAL/LVR_MAXIMUM',
    DealReopen = 'DEAL/REOPEN',
    DealUnassignProcessor = 'DEAL/UNASSIGN_PROCESSOR',
    DealUncontactableFollowUp = 'DEAL/UNCONTACTABLE_FOLLOW_UP',

    DealBorrowerSend = 'DEAL/BORROWER/SEND',
    DealBorrowerSet = 'DEAL/BORROWER/SET',
    DealBorrowerValueSet = 'DEAL/BORROWER/VALUE/SET',

    DealDocumentsAdd = 'DEAL/DOCUMENTS/ADD',
    DealDocumentsList = 'DEAL/DOCUMENTS/LIST',
    DealDocumentsSet = 'DEAL/DOCUMENTS/SET',

    DealDocumentSet = 'DEAL/DOCUMENT/SET',

    DealHistoriesList = 'DEAL/HISTORIES/LIST',
    DealHistoriesSet = 'DEAL/HISTORIES/SET',

    DealNotesAdd = 'DEAL/NOTES/ADD',
    DealNotesList = 'DEAL/NOTES/LIST',
    DealNotesSet = 'DEAL/NOTES/SET',

    DealNoteRemove = 'DEAL/NOTE/REMOVE',
    DealNoteSet = 'DEAL/NOTE/SET',

    DealPropertiesAdd = 'DEAL/PROPERTIES/ADD',
    DealPropertiesList = 'DEAL/PROPERTIES/LIST',
    DealPropertiesSet = 'DEAL/PROPERTIES/SET',

    DealPropertyDelete = 'DEAL/PROPERTY/DELETE',
    DealPropertyGet = 'DEAL/PROPERTY/GET',
    DealPropertyRemove = 'DEAL/PROPERTY/REMOVE',
    DealPropertySend = 'DEAL/PROPERTY/SEND',
    DealPropertySet = 'DEAL/PROPERTY/SET',
    DealPropertyValuationInspectionDate = 'DEAL/PROPERTY/VALUATION_INSPECTION_DATE',
    DealPropertyValueSet = 'DEAL/PROPERTY/VALUE/SET',
    DealPropertyGnafAddressDetailAction = 'DEAL/PROPERTY/GNAF_ADDRESS_DETAIL',
    DealPropertyGnafAddressDetailLoadingClear = 'DEAL/PROPERTY/GNAF_ADDRESS_DETAIL/LOADING_CLEAR',

    DealAddressSearch = 'DEAL/ADDRESS_SEARCH/SEARCH',
    DealAddressSearchResultsSet = 'DEAL/ADDRESS_SEARCH/SEARCH/RESULTS/SET',
    DealAddressSearchResultsClear = 'DEAL/ADDRESS_SEARCH/SEARCH/RESULTS/CLEAR',

    DealPropertiesPostcodeCategoriesList = 'DEAL/PROPERTIES/POSTCODE_CATEGORIES/LIST',
    DealPropertiesPostcodeCategoriesSet = 'DEAL/PROPERTIES/POSTCODE_CATEGORIES/SET',
    DealPropertiesSpPostcodeCategoriesList = 'DEAL/PROPERTIES/SP_POSTCODE_CATEGORIES/LIST',
    DealPropertiesSpPostcodeCategoriesSet = 'DEAL/PROPERTIES/SP_POSTCODE_CATEGORIES/SET',

    DealQuoteApprove = 'DEAL/QUOTE/APPROVE',
    DealQuoteConvertScenario = 'DEAL/QUOTE/CONVERT_SCENARIO',
    DealQuoteReject = 'DEAL/QUOTE/REJECT',
}

export default DealsActionsEnum;
