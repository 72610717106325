import { Button, Form, Input, InputNumber, Modal, Select, Tabs, Tooltip, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React, { ReactElement } from 'react';
import { AiOutlineArrowUp } from 'react-icons/ai';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AddressVersionEnum from '~Api/Deal/AddressVersionEnum';
import IProperty from '~Api/Deal/IProperty';
import OwnershipTypeEnum from '~Api/Deal/OwnershipTypeEnum';
import PropertyPurposeEnum from '~Api/Deal/PropertyPurposeEnum';
import PropertyStateEnum from '~Api/Deal/PropertyStateEnum';
import PropertyStreetTypeEnum from '~Api/Deal/PropertyStreetTypeEnum';
import propertyStreetTypeLabels from '~Api/Deal/PropertyStreetTypeLabels';
import StrataTypeEnum from '~Api/Deal/StrataTypeEnum';
import TenancyTypeEnum from '~Api/Deal/TenancyTypeEnum';
import ZoneTypeEnum from '~Api/Deal/ZoneTypeEnum';
import { IGlobalState } from '~reducer';
import DatePicker from '~UI/DatePicker';
import {
    dealAddressSearchAction,
    dealAddressSearchResultsClearAction,
    dealPropertyDeleteAction,
    dealPropertyGetAction,
    dealPropertyGnafAddressDetailAction,
    dealPropertyValueSetAction,
} from './actions';
import './deals.less';
import {
    dealPropertyAddressSearchResultsLoadingSelector,
    dealPropertyAddressSearchResultsSelector,
    dealPropertyGnafAddressDetailLoadingSelector,
    dealPropertySelector,
} from './selectors';
import IAddressSearchResult from '~Api/AddressSearch/IAddressSearchResult';
import { currencyFormatter } from '~utilities/formatters';
import SearchSelect from '~UI/SearchSelect';

interface IOption {
    label: string;
    value: string;
}

interface IProps {
    dealUuid: string;
    isOpen: boolean;
    onCancel: (event: React.MouseEvent) => void;
    onClickAddressVersionUpgradeButton: (event: React.MouseEvent) => void;
    propertyUuid: string;
}

interface IPropsSelector {
    addressSearchResults: IAddressSearchResult[];
    addressSearchResultsLoading: boolean;
    gnafAddressDetailLoading: boolean;
    property: IProperty;
}

interface IPropsDispatch {
    addressSearch: (keyword: string) => void;
    addressSearchClear: () => void;
    propertyDelete: () => void;
    propertyGet: () => void;
    propertyGnafAddressDetail: (gnafAddressDetailPid: string) => void;
    propertyValueSet: (key: keyof IProperty, value: any) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class PropertyEditModal extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onChangeCurrentDebt = this.onChangeCurrentDebt.bind(this);
        this.onChangeDwellingCount = this.onChangeDwellingCount.bind(this);
        this.onChangeEstimatedValue = this.onChangeEstimatedValue.bind(this);
        this.onChangeFolioNumber = this.onChangeFolioNumber.bind(this);
        this.onChangeInsuranceExpiryDate = this.onChangeInsuranceExpiryDate.bind(this);
        this.onChangeInsuranceReplacementValue = this.onChangeInsuranceReplacementValue.bind(this);
        this.onChangeLotNumber = this.onChangeLotNumber.bind(this);
        this.onChangeLotSizeSquareMetres = this.onChangeLotSizeSquareMetres.bind(this);
        this.onChangeOwnershipType = this.onChangeOwnershipType.bind(this);
        this.onChangePlanNumber = this.onChangePlanNumber.bind(this);
        this.onChangePostcode = this.onChangePostcode.bind(this);
        this.onChangePurpose = this.onChangePurpose.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.onChangeStrataType = this.onChangeStrataType.bind(this);
        this.onChangeStreetAddress = this.onChangeStreetAddress.bind(this);
        this.onChangeUnitNumber = this.onChangeUnitNumber.bind(this);
        this.onChangeStreetNumber = this.onChangeStreetNumber.bind(this);
        this.onChangeStreetName = this.onChangeStreetName.bind(this);
        this.onChangeStreetType = this.onChangeStreetType.bind(this);
        this.onChangeSuburb = this.onChangeSuburb.bind(this);
        this.onChangeTenancyType = this.onChangeTenancyType.bind(this);
        this.onChangeVolumeNumber = this.onChangeVolumeNumber.bind(this);
        this.onChangeZoneType = this.onChangeZoneType.bind(this);

        this.onClickManualEntry = this.onClickManualEntry.bind(this);
        this.onClickUseSearch = this.onClickUseSearch.bind(this);

        this.onClose = this.onClose.bind(this);

        this.onSearchClearAddress = this.onSearchClearAddress.bind(this);
        this.onSearchAddress = this.onSearchAddress.bind(this);
        this.onSelectAddress = this.onSelectAddress.bind(this);

        this.onClickDelete = this.onClickDelete.bind(this);
    }

    public render(): JSX.Element {
        const {
            addressSearchResults,
            addressSearchResultsLoading,
            gnafAddressDetailLoading,
            isOpen,
            property,
        } = this.props;

        if (!property) {
            return null;
        }

        const footer: JSX.Element = (
            <React.Fragment>
                <Button className='delete-property' danger={true} onClick={this.onClickDelete} type='text'>Delete</Button>
                <Button className='close' onClick={this.onClose}>Close</Button>
            </React.Fragment>
        );

        const options: IOption[] = addressSearchResults && _.map(addressSearchResults, (addressSearchResult: IAddressSearchResult) => ({ label: addressSearchResult.longFormat, value: addressSearchResult.gnafAddressDetailPid }));

        const streetAddressFormFields: ReactElement = property.addressVersion === AddressVersionEnum.GNAF_V1 ? (
            <>
                <Form.Item className='address-search' label='Address'>
                    <SearchSelect
                        defaultText={property.gnafAddressDetailPid && property.formattedAddress}
                        loading={addressSearchResultsLoading || gnafAddressDetailLoading}
                        onSearch={this.onSearchAddress}
                        onSelect={this.onSelectAddress}
                        options={options}
                        onSearchClear={this.onSearchClearAddress}
                        value={property.gnafAddressDetailPid}
                    />
                </Form.Item>
                <Button className='use-manual' type='link' onClick={this.onClickManualEntry}>Enter Manually</Button>
            </>
        ) : property.addressVersion === AddressVersionEnum.V2 ? (
            <React.Fragment>
                <Form.Item className='unit-number' label='Unit Number'>
                    <Input onChange={this.onChangeUnitNumber} value={property.unitNumber} />
                </Form.Item>
                <Form.Item className='street-number' label='Street Number'>
                    <Input onChange={this.onChangeStreetNumber} value={property.streetNumber} />
                </Form.Item>
                <Form.Item className='street-name' label='Street Name'>
                    <Input onChange={this.onChangeStreetName} value={property.streetName} />
                </Form.Item>
                <Form.Item className='street-type' label='Street Type'>
                    <Select
                        showSearch={true}
                        onChange={this.onChangeStreetType}
                        value={property.streetType}
                        filterOption={this.onStreetTypeFilterOption}
                    >
                        {_.keys(propertyStreetTypeLabels).map((code: string): JSX.Element => <Select.Option key={code} value={code}>{propertyStreetTypeLabels[code]}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item className='suburb' label='Suburb'>
                    <Input onChange={this.onChangeSuburb} value={property.suburb} />
                </Form.Item>
                <Form.Item className='state' label='State'>
                    <Select onChange={this.onChangeState} value={property.state}>
                        <Select.Option value={PropertyStateEnum.AustralianCapitalTerritory}>ACT</Select.Option>
                        <Select.Option value={PropertyStateEnum.NewSouthWales}>NSW</Select.Option>
                        <Select.Option value={PropertyStateEnum.NorthernTerritory}>NT</Select.Option>
                        <Select.Option value={PropertyStateEnum.Queensland}>QLD</Select.Option>
                        <Select.Option value={PropertyStateEnum.SouthAustralia}>SA</Select.Option>
                        <Select.Option value={PropertyStateEnum.Tasmania}>TAS</Select.Option>
                        <Select.Option value={PropertyStateEnum.Victoria}>VIC</Select.Option>
                        <Select.Option value={PropertyStateEnum.WesternAustralia}>WA</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item className='postcode' label='Postcode'>
                    <Input onChange={this.onChangePostcode} value={property.postcode} />
                </Form.Item>
                <Button className='use-search' type='link' onClick={this.onClickUseSearch}>Use Search</Button>
            </React.Fragment>
        ) : (
            <React.Fragment>
                <Form.Item className='street-address' label='Street Address'>
                    <Input onChange={this.onChangeStreetAddress} value={property.streetAddress} />
                    <Tooltip placement='top' title='Upgrade Address to V2'>
                        <Button className='upgrade-address-version' onClick={this.props.onClickAddressVersionUpgradeButton}><AiOutlineArrowUp/></Button>
                    </Tooltip>
                </Form.Item>
                <Form.Item className='suburb' label='Suburb'>
                    <Input onChange={this.onChangeSuburb} value={property.suburb} />
                </Form.Item>
                <Form.Item className='state' label='State'>
                    <Select onChange={this.onChangeState} value={property.state}>
                        <Select.Option value={PropertyStateEnum.AustralianCapitalTerritory}>ACT</Select.Option>
                        <Select.Option value={PropertyStateEnum.NewSouthWales}>NSW</Select.Option>
                        <Select.Option value={PropertyStateEnum.NorthernTerritory}>NT</Select.Option>
                        <Select.Option value={PropertyStateEnum.Queensland}>QLD</Select.Option>
                        <Select.Option value={PropertyStateEnum.SouthAustralia}>SA</Select.Option>
                        <Select.Option value={PropertyStateEnum.Tasmania}>TAS</Select.Option>
                        <Select.Option value={PropertyStateEnum.Victoria}>VIC</Select.Option>
                        <Select.Option value={PropertyStateEnum.WesternAustralia}>WA</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item className='postcode' label='Postcode'>
                    <Input onChange={this.onChangePostcode} value={property.postcode} />
                </Form.Item>
            </React.Fragment>
        );

        return (
            <Modal
                footer={footer}
                okText='Close'
                onCancel={this.onClose}
                open={isOpen}
                title='Edit Property'
                width={640}
                wrapClassName='deal-property-edit-modal'
            >
                <Tabs defaultActiveKey='address' type='card'>
                    <Tabs.TabPane tab={<Typography>Address</Typography>} key='address'>
                        {streetAddressFormFields}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<Typography>Details</Typography>} key='details'>
                        <Form.Item className='estimated-value' extra={currencyFormatter.format(property.estimatedValue)} label='Estimated Value'>
                            <Input addonBefore='$' min={0} onChange={this.onChangeEstimatedValue} type='number' value={property.estimatedValue} />
                        </Form.Item>
                        <Form.Item className='current-debt' extra={currencyFormatter.format(property.currentDebt)} label='Current Debt'>
                            <Input addonBefore='$' min={0} onChange={this.onChangeCurrentDebt} type='number' value={property.currentDebt} />
                        </Form.Item>
                        <Form.Item className='zone-type' label='Zoning'>
                            <Select onChange={this.onChangeZoneType} value={property.zoneType}>
                                <Select.Option value={ZoneTypeEnum.ResidentialHouse}>Residential - House</Select.Option>
                                <Select.Option value={ZoneTypeEnum.ResidentialTownhouse}>Residential - Townhouse / Villa</Select.Option>
                                <Select.Option value={ZoneTypeEnum.ResidentialUnit}>Residential - Unit / Apartment</Select.Option>
                                <Select.Option value={ZoneTypeEnum.ResidentialLand}>Residential - Land</Select.Option>
                                <Select.Option value={ZoneTypeEnum.CommercialOffice}>Commercial - Office</Select.Option>
                                <Select.Option value={ZoneTypeEnum.CommercialRetail}>Commercial - Retail</Select.Option>
                                <Select.Option value={ZoneTypeEnum.CommercialIndustrial}>Commercial - Industrial</Select.Option>
                                <Select.Option value={ZoneTypeEnum.CommercialLand}>Commercial - Land</Select.Option>
                                <Select.Option value={ZoneTypeEnum.RuralResidential}>Rural - Residential</Select.Option>
                                <Select.Option value={ZoneTypeEnum.RuralLand}>Rural - Land</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item className='purpose' label='Purpose'>
                            <Select onChange={this.onChangePurpose} value={property.purpose}>
                                <Select.Option value={PropertyPurposeEnum.Develop}>Develop</Select.Option>
                                <Select.Option value={PropertyPurposeEnum.Purchase}>Purchase</Select.Option>
                                <Select.Option value={PropertyPurposeEnum.PurchaseDevelop}>Purchase and Develop</Select.Option>
                                <Select.Option value={PropertyPurposeEnum.Refinance}>Refinance</Select.Option>
                                <Select.Option value={PropertyPurposeEnum.Security}>Security</Select.Option>
                                <Select.Option value={PropertyPurposeEnum.Sell}>Sell</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item className='strata-type' label='Strata Type'>
                            <Select onChange={this.onChangeStrataType} value={property.strataType}>
                                <Select.Option value={StrataTypeEnum.None}>None</Select.Option>
                                <Select.Option value={StrataTypeEnum.Strata}>Strata Title</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item className='tenancy-type' label='Tenancy Type'>
                            <Select onChange={this.onChangeTenancyType} value={property.tenancyType}>
                                <Select.Option value={TenancyTypeEnum.None}>None</Select.Option>
                                <Select.Option value={TenancyTypeEnum.Tenanted}>Tenanted</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item className='ownership-type' label='Ownership Type'>
                            <Select onChange={this.onChangeOwnershipType} value={property.ownershipType}>
                                <Select.Option value={OwnershipTypeEnum.CrownLeasehold}>Crown Leasehold</Select.Option>
                                <Select.Option value={OwnershipTypeEnum.Freehold}>Freehold</Select.Option>
                                <Select.Option value={OwnershipTypeEnum.Leasehold}>Leasehold</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item className='lot-size-square-metres' label='Lot Size'>
                            <Input addonAfter='m&sup2;' min={0} onChange={this.onChangeLotSizeSquareMetres} type='number' value={property.lotSizeSquareMetres} />
                        </Form.Item>
                        <Form.Item className='dwelling-count' label='Number of Dwellings'>
                            <InputNumber min={0} onChange={this.onChangeDwellingCount} value={property.dwellingCount} />
                        </Form.Item>
                        <Form.Item label='Insurance Expiry Date' className='insurance-expiry-date'>
                            <DatePicker onChange={this.onChangeInsuranceExpiryDate} format='DD/MM/YYYY' value={property.insuranceExpiryDate ? dayjs(property.insuranceExpiryDate) : null} />
                        </Form.Item>
                        <Form.Item className='insurance-replacement-value' extra={currencyFormatter.format(property.insuranceReplacementValue)} label='Insurance Replacement Value'>
                            <Input addonBefore='$' min={0} onChange={this.onChangeInsuranceReplacementValue} type='number' value={property.insuranceReplacementValue} />
                        </Form.Item>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<Typography>Title Information</Typography>} key='title-information'>
                        <Form.Item className='folio-number' label='Folio Number'>
                            <Input onChange={this.onChangeFolioNumber} value={property.folioNumber} />
                        </Form.Item>
                        <Form.Item className='lot-number' label='Lot Number'>
                            <Input onChange={this.onChangeLotNumber} value={property.lotNumber} />
                        </Form.Item>
                        <Form.Item className='plan-number' label='Plan Number'>
                            <Input onChange={this.onChangePlanNumber} value={property.planNumber} />
                        </Form.Item>
                        <Form.Item className='volume-number' label='Volume Number'>
                            <Input onChange={this.onChangeVolumeNumber} value={property.volumeNumber} />
                        </Form.Item>
                    </Tabs.TabPane>
                </Tabs>
            </Modal>
        );
    }

    private onChangeCurrentDebt(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.propertyValueSet('currentDebt', event.target.value ? Number(event.target.value) : null);
    }

    private onChangeDwellingCount(value: number): void {
        this.props.propertyValueSet('dwellingCount', value);
    }

    private onChangeEstimatedValue(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.propertyValueSet('estimatedValue', event.target.value ? Number(event.target.value) : null);
    }

    private onChangeFolioNumber(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.propertyValueSet('folioNumber', event.target.value);
    }

    private onChangeInsuranceExpiryDate(date: Dayjs): void {
        this.props.propertyValueSet('insuranceExpiryDate', date ? date.format('YYYY-MM-DD') : null);
    }

    private onChangeInsuranceReplacementValue(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.propertyValueSet('insuranceReplacementValue', event.target.value ? event.target.valueAsNumber : null);
    }

    private onChangeLotNumber(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.propertyValueSet('lotNumber', event.target.value);
    }

    private onChangeLotSizeSquareMetres(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.propertyValueSet('lotSizeSquareMetres', event.target.value ? event.target.valueAsNumber : null);
    }

    private onChangeOwnershipType(value: OwnershipTypeEnum): void {
        this.props.propertyValueSet('ownershipType', value);
    }

    private onChangePlanNumber(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.propertyValueSet('planNumber', event.target.value);
    }

    private onChangePostcode(event: React.ChangeEvent<HTMLInputElement>): void {
        if (/[^0-9]/.test(event.target.value) || event.target.value.trim().length > 4) {
            return;
        }

        this.props.propertyValueSet('postcode', event.target.value);
    }

    private onChangePurpose(value: PropertyPurposeEnum): void {
        this.props.propertyValueSet('purpose', value);
    }

    private onChangeState(value: PropertyStateEnum): void {
        this.props.propertyValueSet('state', value);
    }

    private onChangeStrataType(value: StrataTypeEnum): void {
        this.props.propertyValueSet('strataType', value);
    }

    private onChangeStreetAddress(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.propertyValueSet('streetAddress', event.target.value);
    }

    private onChangeUnitNumber(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.propertyValueSet('unitNumber', event.target.value);
    }

    private onChangeStreetNumber(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.propertyValueSet('streetNumber', event.target.value);
    }

    private onChangeStreetName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.propertyValueSet('streetName', event.target.value);
    }

    private onStreetTypeFilterOption(input: string, option: any): boolean {
        return option.children.toString().toLocaleLowerCase().includes(input.toLocaleLowerCase());
    }

    private onChangeStreetType(value: PropertyStreetTypeEnum): void {
        this.props.propertyValueSet('streetType', value);
    }

    private onChangeSuburb(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.propertyValueSet('suburb', event.target.value);
    }

    private onChangeTenancyType(value: TenancyTypeEnum): void {
        this.props.propertyValueSet('tenancyType', value);
    }

    private onChangeVolumeNumber(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.propertyValueSet('volumeNumber', event.target.value);
    }

    private onChangeZoneType(value: ZoneTypeEnum): void {
        this.props.propertyValueSet('zoneType', value);
    }

    private onClickDelete(): void {
        Modal.confirm({
            content: 'Are you sure you want to delete this property?',
            okText: 'Delete',
            okType: 'danger',
            onOk: () => {
                this.props.propertyDelete();
            },
            title: 'Delete',
        });
    }

    private onClickManualEntry(): void {
        this.props.propertyValueSet('unitNumber', null);
        this.props.propertyValueSet('streetNumber', null);
        this.props.propertyValueSet('streetType', null);
        this.props.propertyValueSet('streetName', null);
        this.props.propertyValueSet('suburb', null);
        this.props.propertyValueSet('state', null);
        this.props.propertyValueSet('postcode', null);
        this.props.propertyValueSet('gnafAddressDetailPid', null);
        this.props.propertyValueSet('addressVersion', AddressVersionEnum.V2);
    }

    private onClickUseSearch(): void {
        this.props.propertyValueSet('unitNumber', null);
        this.props.propertyValueSet('streetNumber', null);
        this.props.propertyValueSet('streetType', null);
        this.props.propertyValueSet('streetName', null);
        this.props.propertyValueSet('suburb', null);
        this.props.propertyValueSet('state', null);
        this.props.propertyValueSet('postcode', null);
        this.props.propertyValueSet('addressVersion', AddressVersionEnum.GNAF_V1);
    }

    private onClose(e: React.MouseEvent<HTMLElement>): void {
        this.props.addressSearchClear();

        this.props.onCancel(e);
    }

    private onSearchClearAddress(): void {
        this.props.addressSearchClear();
    }

    private onSearchAddress(keyword: string): void {
        this.props.addressSearch(keyword);
    }

    private onSelectAddress(gnafAddressDetailPid: string): void {
        this.props.propertyGnafAddressDetail(gnafAddressDetailPid);
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        addressSearchResults: dealPropertyAddressSearchResultsSelector(state),
        addressSearchResultsLoading: dealPropertyAddressSearchResultsLoadingSelector(state),
        gnafAddressDetailLoading: dealPropertyGnafAddressDetailLoadingSelector(state),
        property: dealPropertySelector(state, ownProps.propertyUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        addressSearch: (keyword: string) => dispatch(dealAddressSearchAction(keyword)),
        addressSearchClear: () => dispatch(dealAddressSearchResultsClearAction()),
        propertyDelete: () => dispatch(dealPropertyDeleteAction(ownProps.dealUuid, ownProps.propertyUuid)),
        propertyGet: () => dispatch(dealPropertyGetAction(ownProps.dealUuid, ownProps.propertyUuid)),
        propertyGnafAddressDetail: (gnafAddressDetailPid: string) => dispatch(dealPropertyGnafAddressDetailAction(ownProps.propertyUuid, gnafAddressDetailPid)),
        propertyValueSet: (key: keyof IProperty, value: any) => dispatch(dealPropertyValueSetAction(ownProps.dealUuid, ownProps.propertyUuid, key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PropertyEditModal);
