import { Spin, Typography } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import _ from 'lodash';
import React, { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IAccount from '~Api/Investor/IAccount';
import { investorAccountsIncomeTrustListAction } from '~Investors/actions';
import { investorIncomeTrustAccountsSelector } from '~Investors/selectors';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';
import { currencyFormatter } from '~utilities/formatters';
import IInvestorAccountIncomeTrust from '~Api/IncomeTrust/IInvestorAccountIncomeTrust';
import IncomeTrustClassTypeEnum from '~Api/IncomeTrust/IncomeTrustClassTypeEnum';

export default function Investors(): ReactElement {
    const accounts: IDictionary<IAccount> = useSelector(investorIncomeTrustAccountsSelector);

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        dispatch(investorAccountsIncomeTrustListAction());
    }, []);

    const summary: (accounts: readonly IAccount[]) => JSX.Element = useCallback((accounts: readonly IAccount[]) => {
        let cashBalanceTotal: number = 0;
        const classTotals: IDictionary<number> = {
            [IncomeTrustClassTypeEnum.ClassC]: 0,
            [IncomeTrustClassTypeEnum.ClassD]: 0,
        };
        let investedTotal: number = 0;

        _.forEach(accounts, (account: IAccount) => {
            cashBalanceTotal += account.balance;
            investedTotal += account.investedAmountCurrent;

            _.forEach(account.incomeTrusts, (incomeTrust: IInvestorAccountIncomeTrust) => {
                classTotals[incomeTrust.classType] += incomeTrust.principalBalance;
            });
        });

        return (
            <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>&nbsp;</Table.Summary.Cell>
                <Table.Summary.Cell index={2}>{currencyFormatter.format(cashBalanceTotal)}</Table.Summary.Cell>
                <Table.Summary.Cell index={3}>{currencyFormatter.format(classTotals[IncomeTrustClassTypeEnum.ClassC])}</Table.Summary.Cell>
                <Table.Summary.Cell index={4}>{currencyFormatter.format(classTotals[IncomeTrustClassTypeEnum.ClassD])}</Table.Summary.Cell>
                <Table.Summary.Cell index={5}>{currencyFormatter.format(investedTotal)}</Table.Summary.Cell>
            </Table.Summary.Row>
        );
    }, []);

    if (!accounts) {
        return (
            <Layout section='investors'>
                <Typography.Title level={2}>Investors</Typography.Title>
                <Spin />
            </Layout>
        );
    }

    const columns: ColumnsType<IAccount> = [
        {
            defaultSortOrder: 'ascend',
            render: (account: IAccount) => <Link to={`/investors/${account.investorUuid}/accounts/${account.uuid}`}>{account.investorName}</Link>,
            sorter: (a: IAccount, b: IAccount) => a.investorName.localeCompare(b.investorName),
            title: 'Investor',
        },
        {
            dataIndex: 'code',
            title: 'Code',
            width: '10%',
        },
        {
            dataIndex: 'balance',
            render: (balance: number): string => balance ? currencyFormatter.format(balance) : '-',
            sorter: (a: IAccount, b: IAccount): number => a.balance > b.balance ? 1 : -1,
            title: 'Cash Balance',
            width: '12%',
        },
        {
            filters: [
                {
                    text: 'Active',
                    value: true,
                },
                {
                    text: 'Non Active',
                    value: false,
                },
            ],
            onFilter: (value: boolean | number | string, account: IAccount) => {
                const incomeTrust: IInvestorAccountIncomeTrust = _.find(account.incomeTrusts, { classType: IncomeTrustClassTypeEnum.ClassC });
                if (!incomeTrust) {
                    return false;
                }

                return value === incomeTrust.principalBalance > 0;
            },
            render: (account: IAccount) => {
                const incomeTrust: IInvestorAccountIncomeTrust = _.find(account.incomeTrusts, { classType: IncomeTrustClassTypeEnum.ClassC });

                return incomeTrust?.principalBalance ? currencyFormatter.format(incomeTrust.principalBalance) : '-';
            },
            title: 'Class C',
            width: '12%',
        },
        {
            filters: [
                {
                    text: 'Active',
                    value: true,
                },
                {
                    text: 'Non Active',
                    value: false,
                },
            ],
            onFilter: (value: boolean | number | string, account: IAccount) => {
                const incomeTrust: IInvestorAccountIncomeTrust = _.find(account.incomeTrusts, { classType: IncomeTrustClassTypeEnum.ClassD });
                if (!incomeTrust) {
                    return false;
                }

                return value === incomeTrust.principalBalance > 0;
            },
            render: (account: IAccount) => {
                const incomeTrust: IInvestorAccountIncomeTrust = _.find(account.incomeTrusts, { classType: IncomeTrustClassTypeEnum.ClassD });

                return incomeTrust?.principalBalance ? currencyFormatter.format(incomeTrust.principalBalance) : '-';
            },
            title: 'Class D',
            width: '12%',
        },
        {
            filters: [
                {
                    text: 'Active',
                    value: true,
                },
                {
                    text: 'Non Active',
                    value: false,
                },
            ],
            onFilter: (value: boolean | number | string, account: IAccount) => value === account.investedAmountCurrent > 0,
            render: (account: IAccount): string => (account.investedAmountCurrent ? currencyFormatter.format(account.investedAmountCurrent) : '-'),
            title: 'Total Invested',
            width: '12%',
        },
    ];

    return (
        <Layout section='investors'>
            <Typography.Title level={2}>Investors</Typography.Title>
            <Table
                columns={columns}
                dataSource={_.values(accounts)}
                pagination={false}
                rowKey='uuid'
                size='middle'
                summary={summary}
            />
        </Layout>
    );
}
