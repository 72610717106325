import IAddressSearchResult from './IAddressSearchResult';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseAddressSearchResult(raw: any): IAddressSearchResult {
    return {
        gnafAddressDetailPid: raw.gnafAddressDetailPid,
        longFormat: raw.longFormat,
    };
}

