import { Modal, Space, Spin, Table, Typography } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import {  useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IWarehouse from '~Api/Warehouse/IWarehouse';
import IWarehouseLoanTransaction from '~Api/Warehouse/IWarehouseLoanTransaction';
import { IDictionary } from '~utilities/IDictionary';
import { warehousesListAction } from '~Warehouses/actions';
import { warehousesSelector } from '~Warehouses/selectors';
import { warehouseMovementsListAction } from './actions';
import Layout from './Layout';
import { warehouseMovementsSelector } from './selectors';
import { currencyFormatter } from '~utilities/formatters';
import { FilterValue } from 'antd/lib/table/interface';
import DatePicker from '~UI/DatePicker';

interface IWarehouseMovement {
    date: string;
    loanCode?: string;
    loanUuid?: string;
    transactions: IWarehouseLoanTransaction[];
    uniqueId: string;
    warehouseUuid?: string;
}

interface IDay {
    date: string;
    movementCount: number;
    primaryRowUniqueId?: string;
}

export default function WarehouseMovements(): ReactElement {
    const [ endDate, setEndDate ] = useState<string>(dayjs().format('YYYY-MM-DD'));
    const [ modalIsOpen, setModalIsOpen ] = useState<boolean>(false);
    const [ modalUniqueId, setModalUniqueId ] = useState<string>(null);
    const [ startDate, setStartDate ] = useState<string>(dayjs().subtract(30, 'days').format('YYYY-MM-DD'));
    const [ warehouseUuidFilter, setWarehouseUuidFilter ] = useState<string[]>(null);

    const transactions: IDictionary<IWarehouseLoanTransaction> = useSelector(warehouseMovementsSelector);
    const warehouses: IDictionary<IWarehouse> = useSelector(warehousesSelector);

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!warehouses) {
            dispatch(warehousesListAction());
        }
    }, [
        dispatch,
        warehouses,
    ]);

    // We only want this to run on first load, not each time the date range changes
    useEffect(() => {
        dispatch(warehouseMovementsListAction(startDate, endDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dispatch,
    ]);

    const onClickTransactions: (uniqueId: string) => void = useCallback((uniqueId: string) => {
        setModalUniqueId(uniqueId);
        setModalIsOpen(true);
    }, []);

    const onCloseModal: () => void = useCallback(() => {
        setModalIsOpen(false);
    }, []);

    const onChangeTable: (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: any) => void = useCallback((pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: any) => {
        setWarehouseUuidFilter(filters.warehouseUuid as string[]);
    }, []);

    const onChangeDateRange: (dateRange: [Dayjs, Dayjs]) => void = useCallback((dateRange: [Dayjs, Dayjs]) => {
        const newStartDate: string = dateRange[0].format('YYYY-MM-DD');
        const newEndDate: string = dateRange[1].format('YYYY-MM-DD');

        setStartDate(newStartDate);
        setEndDate(newEndDate);

        dispatch(warehouseMovementsListAction(newStartDate, newEndDate));
    }, [
        dispatch,
    ]);

    const datePicker: ReactElement = (
        <DatePicker.RangePicker
            picker='date'
            format='DD/MM/YYYY'
            onChange={onChangeDateRange}
            value={[startDate ? dayjs(startDate) : null, endDate ? dayjs(endDate) : null]}
        />
    );

    if (!warehouses) {
        return (
            <Layout section='warehouse-movements'>
                <Space className='actions'>
                    {datePicker}
                </Space>
                <Typography.Title level={2}>Warehouse Movements</Typography.Title>
                <Spin/>
            </Layout>
        );
    }

    const days: IDictionary<IDay> = {};
    const warehouseMovements: IWarehouseMovement[] = [];

    const endDateDayjs: Dayjs = dayjs(endDate);
    let loopDateDayJs: Dayjs = dayjs(startDate);

    while (loopDateDayJs <= endDateDayjs) {
        const loopDateFormatted: string = loopDateDayJs.format('YYYY-MM-DD');
        days[loopDateFormatted] = {
            date: loopDateFormatted,
            movementCount: 0,
        };
        warehouseMovements.push({
            date: loopDateFormatted,
            transactions: [],
            uniqueId: loopDateFormatted,
        });

        loopDateDayJs = loopDateDayJs.add(1, 'day');
    }

    const dayWarehouseMovements: IDictionary<IWarehouseMovement> = {};

    _.each(transactions, (warehouseLoanTransaction: IWarehouseLoanTransaction) => {
        if (warehouseUuidFilter && !warehouseUuidFilter.includes(warehouseLoanTransaction.warehouseUuid)) {
            return;
        }

        const transactionTimeDaysjs: Dayjs = dayjs(warehouseLoanTransaction.transactionTime);
        const transactionDate: string = transactionTimeDaysjs.format('YYYY-MM-DD');

        const uniqueId: string = `${transactionDate}|${warehouseLoanTransaction.warehouseLoanUuid}`;

        if (!dayWarehouseMovements[uniqueId]) {
            dayWarehouseMovements[uniqueId] = {
                date: transactionDate,
                loanCode: warehouseLoanTransaction.loanCode,
                loanUuid: warehouseLoanTransaction.loanUuid,
                transactions: [],
                uniqueId,
                warehouseUuid: warehouseLoanTransaction.warehouseUuid,
            };
        }

        dayWarehouseMovements[uniqueId].transactions.push(warehouseLoanTransaction);
    });

    _.each(dayWarehouseMovements, (dayWarehouseLoan: IWarehouseMovement) => {
        const day: IDay = days[dayWarehouseLoan.date];
        if (!day.primaryRowUniqueId) {
            day.primaryRowUniqueId = dayWarehouseLoan.uniqueId;

            const initialWarehouseMovement: IWarehouseMovement = _.find(warehouseMovements, (warehouseMovement: IWarehouseMovement) => warehouseMovement.date === dayWarehouseLoan.date);
            initialWarehouseMovement.loanCode = dayWarehouseLoan.loanCode;
            initialWarehouseMovement.loanUuid = dayWarehouseLoan.loanUuid;
            initialWarehouseMovement.transactions = dayWarehouseLoan.transactions;
            initialWarehouseMovement.uniqueId = dayWarehouseLoan.uniqueId;
            initialWarehouseMovement.warehouseUuid = dayWarehouseLoan.warehouseUuid;
        } else {
            warehouseMovements.push(dayWarehouseLoan);
        }

        day.movementCount++;
    });

    const columns: ColumnsType<IWarehouseMovement> = [
        {
            className: 'date',
            render: (warehouseMovement: IWarehouseMovement) => {
                if (!warehouseMovement.warehouseUuid) {
                    return dayjs(warehouseMovement.date).format('D/M');
                }

                const day: IDay = days[warehouseMovement.date];
                return {
                    children: dayjs(warehouseMovement.date).format('D/M'),
                    props: {
                        // When the rowSpan of the cell is set to zero, it essentially hides the cell.
                        rowSpan: (day.primaryRowUniqueId === warehouseMovement.uniqueId) ? day.movementCount : 0,
                    },
                };
            },
            title: 'Date',
            width: '5%',
        },
        {
            className: 'day',
            render: (warehouseMovement: IWarehouseMovement) => {
                if (!warehouseMovement.warehouseUuid) {
                    return dayjs(warehouseMovement.date).format('dddd');
                }

                const day: IDay = days[warehouseMovement.date];
                return {
                    children: dayjs(warehouseMovement.date).format('dddd'),
                    props: {
                        rowSpan: (day.primaryRowUniqueId === warehouseMovement.uniqueId) ? day.movementCount : 0,
                    },
                };
            },
            title: 'Day',
            width: '10%',
        },
        {
            dataIndex: 'warehouseUuid',
            filteredValue: warehouseUuidFilter,
            filters: _.sortBy(warehouses, 'name').map((warehouse: IWarehouse) => ({
                text: warehouse.name,
                value: warehouse.uuid,
            })),
            render: (warehouseUuid: string) => {
                if (!warehouseUuid) {
                    return '-';
                }

                return <Link to={`/warehouses/${warehouseUuid}`}>{warehouses[warehouseUuid].name}</Link>;
            },
            title: 'Warehouse',
            width: '25%',
        },
        {
            render: (warehouseMovement: IWarehouseMovement) => {
                if (!warehouseMovement.loanUuid) {
                    return '-';
                }

                return <Link to={`/loans/${warehouseMovement.loanUuid}`}>{warehouseMovement.loanCode}</Link>;
            },
            title: 'Loan',
        },
        {
            dataIndex: 'transactions',
            render: (loopTransactions: IWarehouseLoanTransaction[], warehouseMovement: IWarehouseMovement) => {
                if (loopTransactions.length === 0) {
                    return '-';
                }

                const netPrincipal: number = _.sumBy(_.values(loopTransactions), (loopTransaction: IWarehouseLoanTransaction) => loopTransaction.amountPrincipal);

                let modalBlock: JSX.Element = null;

                if (modalUniqueId === warehouseMovement.uniqueId) {
                    const modalColumns: ColumnsType<IWarehouseLoanTransaction> = [
                        {
                            dataIndex: 'description',
                            title: 'Description',
                        },
                        {
                            dataIndex: 'amountPrincipal',
                            render: (amountPrincipal: number) => currencyFormatter.format(amountPrincipal),
                            title: 'Amount',
                            width: '20%',
                        },
                    ];

                    modalBlock = (
                        <Modal
                            footer={false}
                            onCancel={onCloseModal}
                            open={modalIsOpen}
                            title='Investment Details'
                            width={1000}
                        >
                            <Table
                                columns={modalColumns}
                                dataSource={loopTransactions}
                                rowKey='uuid'
                                pagination={false}
                                size='middle'
                            />
                        </Modal>
                    );
                }

                const onClick: () => void = () => onClickTransactions(warehouseMovement.uniqueId);

                return (
                    <>
                        <a onClick={onClick}>{currencyFormatter.format(netPrincipal)}</a>
                        {modalBlock}
                    </>
                );
            },
            title: 'Net Principal',
            width: '15%',
        },
    ];

    return (
        <Layout section='warehouse-movements'>
            <Space className='actions'>
                {datePicker}
            </Space>
            <Typography.Title level={2}>Warehouse Movements</Typography.Title>
            <Table
                columns={columns}
                dataSource={_.values(_.orderBy(warehouseMovements, ['date'], ['desc']))}
                loading={null === transactions}
                rowKey='uniqueId'
                onChange={onChangeTable}
                pagination={false}
                size='middle'
            />
        </Layout>
    );
}
