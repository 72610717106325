import { all, call, put, select, takeEvery, takeLeading } from '@redux-saga/core/effects';
import { notification } from 'antd';
import dayjs from 'dayjs';
import history from '~history';
import { IFetchResponse } from '~utilities/fetch';
import { renderNotificationLoadingIcon } from '~utilities/utils';
import {
    IAuthJokeGetAction,
    IAuthLoginAction,
    IAuthLogoutAction,
    IAuthMagicLinkCreateAction,
    IAuthMagicLinkRedeemAction,
    authJokeSetAction,
    authLoginInProgressSetAction,
} from './actions';
import ActionsEnum from './ActionsEnum';
import {
    authJokeRequest,
    authMagicLinkCreateRequest,
    authMagicLinkRedeemRequest,
    authWebauthnLoginRequest,
    authWebauthnOptionsRequest,
} from './requests';
import {
    IAssertionPublicKeyCredential,
    IPreparedAssertionPublicKeyCredential,
    prepareAssertionPublicKeyCredentials,
    preparePublicKeyCredentialRequestOptions,
} from '~utilities/webauthn';
import { verifyToken } from '~utilities/jwt';
import { authPreviousPathSelector } from './selectors';
import IToken from './IToken';
import Cookies from 'js-cookie';

export function doLogin(token: string): IToken {
    const decodedToken: IToken = verifyToken(token);

    localStorage.setItem('token', token);
    localStorage.setItem('decodedToken', JSON.stringify(decodedToken));

    Cookies.set('token', token, {
        domain: process.env.TOKEN_COOKIE_DOMAIN,
    });

    return decodedToken;
}

export function doLogout(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('decodedToken');

    Cookies.remove('token');
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* jokeGet(action: IAuthJokeGetAction): Iterator<unknown> {
    try {
        const jokeGetResponse: IFetchResponse = yield call(authJokeRequest);
        const joke: string = (jokeGetResponse.body && jokeGetResponse.body.joke) || null;
        yield put(authJokeSetAction(joke));
    } catch (e: unknown) {
        yield put(authJokeSetAction('No dad jokes for you :('));
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* logout(action: IAuthLogoutAction): Iterator<unknown> {
    doLogout();

    history.push('/login');
}

function* login(action: IAuthLoginAction): Iterator<unknown> {
    yield put(authLoginInProgressSetAction(true));

    const authWebauthnOptionsResponse: IFetchResponse = yield call(authWebauthnOptionsRequest, action.email);
    const publicKey: PublicKeyCredentialRequestOptions = preparePublicKeyCredentialRequestOptions(authWebauthnOptionsResponse.body);
    const credentials: IAssertionPublicKeyCredential = yield Promise.resolve(navigator.credentials.get({ publicKey }));
    const publicKeyCredential: IPreparedAssertionPublicKeyCredential = prepareAssertionPublicKeyCredentials(credentials);
    const authWebauthnLoginResponse: IFetchResponse = yield call(authWebauthnLoginRequest, publicKeyCredential);
    if (authWebauthnLoginResponse.status === 200) {
        const token: string = authWebauthnLoginResponse.headers.get('Funding-Auth-Token');
        doLogin(token);

        localStorage.setItem('username', action.email);

        const previousPath: string = yield select(authPreviousPathSelector);
        history.push(previousPath || '/');
    }

    yield put(authLoginInProgressSetAction(false));
}

function* magicLinkCreate(action: IAuthMagicLinkCreateAction): Iterator<unknown> {
    history.push('/');

    const key: string = `magicLinkCreate ${dayjs().format()}`;

    notification.open({
        description: 'Requesting magic link...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Account Recovery',
    });

    yield call(authMagicLinkCreateRequest, action.email);

    notification.success({
        description: 'The magic link has been requested.',
        duration: 4.5,
        key,
        message: 'Account Recovery',
    });
}

function* magicLinkRedeem(action: IAuthMagicLinkRedeemAction): Iterator<unknown> {
    const magicLinkRedeemResponse: IFetchResponse = yield call(authMagicLinkRedeemRequest, action.code);

    const token: string = magicLinkRedeemResponse.headers.get('Funding-Auth-Token');
    doLogin(token);

    document.location.href = '/';
}

export function* AuthSagas(): Iterator<unknown> {
    yield all([
        takeLeading(ActionsEnum.JokeGet, jokeGet),

        takeEvery(ActionsEnum.Login, login),
        takeEvery(ActionsEnum.Logout, logout),

        takeEvery(ActionsEnum.MagicLinkCreate, magicLinkCreate),
        takeEvery(ActionsEnum.MagicLinkRedeem, magicLinkRedeem),
    ]);
}
