import { Breadcrumb, Button, Dropdown, Layout, MenuProps, Modal, Popover, Space, Spin, Typography } from 'antd';
import React, { ReactElement, useEffect } from 'react';
import IWarehouse from '~Api/Warehouse/IWarehouse';
import IWarehouseLoan from '~Api/Warehouse/IWarehouseLoan';
import { IDictionary } from '~utilities/IDictionary';
import {
    warehouseProposedSalesPendingSelector,
    warehousesSelector,
} from '~Warehouses/selectors';
import {
    warehouseProposedSaleCancelAction,
    warehouseProposedSaleProcessAction,
    warehouseProposedSalesPendingListAction,
    warehousesListAction,
} from '~Warehouses/actions';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import Table, { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import dayjs from 'dayjs';
import IWarehouseProposedSale from '~Api/Warehouse/IWarehouseProposedSale';
import IWarehouseProposedSaleDestination from '~Api/Warehouse/IWarehouseProposedSaleDestination';
import './warehouses.less';
import { currencyFormatter } from '~utilities/formatters';
import WarehouseTypeEnum from '~Api/Warehouse/WarehouseTypeEnum';
import { WarningOutlined } from '@ant-design/icons';

interface ISourceProposedSale {
    destinationWarehouseCount: number;
    primaryWarehouseProposedSaleDestinationUuid: string;
    saleTotal: number;
    sourceWarehouseLoan: IWarehouseLoan;
    sourceWarehouseUuid: string;
    transactionTime: string;
    uuid: string;
}

export default function ProposedSales(): ReactElement {
    const warehouses: IDictionary<IWarehouse> = useSelector(warehousesSelector);
    const warehouseProposedSales: IDictionary<IWarehouseProposedSale> = useSelector(warehouseProposedSalesPendingSelector);

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!warehouses) {
            dispatch(warehousesListAction());
        }
    }, [
        dispatch,
        warehouses,
    ]);

    useEffect(() => {
        if (!warehouseProposedSales) {
            dispatch(warehouseProposedSalesPendingListAction());
        }
    }, [
        dispatch,
        warehouseProposedSales,
    ]);

    if (!warehouseProposedSales || !warehouses) {
        return (
            <Layout className='warehouses proposed-sales'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to='/warehouses'>Warehouses</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>Proposed Warehouse Sales</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Typography.Title level={2}>Proposed Warehouse Sales</Typography.Title>
                        <Spin />
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }

    const sourceProposedSales: IDictionary<ISourceProposedSale> = {};
    const warehouseProposedSaleDestinations: IDictionary<IWarehouseProposedSaleDestination> = {};

    _.each(_.sortBy(warehouseProposedSales, ['transactionTime']), (warehouseProposedSale: IWarehouseProposedSale) => {
        _.each(warehouseProposedSale.warehouseProposedSaleDestinations, (warehouseProposedSaleDestination: IWarehouseProposedSaleDestination) => {
            warehouseProposedSaleDestinations[warehouseProposedSaleDestination.uuid] = warehouseProposedSaleDestination;

            let sourceProposedSale: ISourceProposedSale = sourceProposedSales[warehouseProposedSaleDestination.warehouseProposedSaleUuid];

            if (!sourceProposedSale) {
                sourceProposedSale = {
                    destinationWarehouseCount: 1,
                    primaryWarehouseProposedSaleDestinationUuid: warehouseProposedSaleDestination.uuid,
                    saleTotal: warehouseProposedSaleDestination.amount,
                    sourceWarehouseLoan: warehouseProposedSale.sourceWarehouseLoan,
                    sourceWarehouseUuid: warehouseProposedSale.sourceWarehouseLoan.warehouseUuid,
                    transactionTime: warehouseProposedSale.transactionTime,
                    uuid: warehouseProposedSale.uuid,
                };
            } else {
                sourceProposedSale.destinationWarehouseCount++;
                sourceProposedSale.saleTotal += warehouseProposedSaleDestination.amount;
            }

            sourceProposedSales[warehouseProposedSaleDestination.warehouseProposedSaleUuid] = sourceProposedSale;
        });
    });

    const columns: ColumnsType<IWarehouseProposedSaleDestination> = [
        {
            className: 'date',
            onCell: (warehouseProposedSaleDestination: IWarehouseProposedSaleDestination) => {
                const sourceProposedSale: ISourceProposedSale = sourceProposedSales[warehouseProposedSaleDestination.warehouseProposedSaleUuid];

                return {
                    rowSpan: (sourceProposedSale.primaryWarehouseProposedSaleDestinationUuid === warehouseProposedSaleDestination.uuid) ? sourceProposedSale.destinationWarehouseCount : 0,
                };
            },
            render: (warehouseProposedSaleDestination: IWarehouseProposedSaleDestination) => {
                const sourceProposedSale: ISourceProposedSale = sourceProposedSales[warehouseProposedSaleDestination.warehouseProposedSaleUuid];

                return dayjs(sourceProposedSale.transactionTime).format('D/M/YY');
            },
            title: 'Date',
            width: '5%',
        },
        {
            className: 'source-warehouse',
            onCell: (warehouseProposedSaleDestination: IWarehouseProposedSaleDestination) => {
                const sourceProposedSale: ISourceProposedSale = sourceProposedSales[warehouseProposedSaleDestination.warehouseProposedSaleUuid];

                return {
                    rowSpan: (sourceProposedSale.primaryWarehouseProposedSaleDestinationUuid === warehouseProposedSaleDestination.uuid) ? sourceProposedSale.destinationWarehouseCount : 0,
                };
            },
            render: (warehouseProposedSaleDestination: IWarehouseProposedSaleDestination) => {
                const sourceProposedSale: ISourceProposedSale = sourceProposedSales[warehouseProposedSaleDestination.warehouseProposedSaleUuid];

                return (
                    <Link to={`/warehouses/${sourceProposedSale.sourceWarehouseUuid}`}>
                        {warehouses[sourceProposedSale.sourceWarehouseUuid].name}
                    </Link>
                );
            },
            title: 'Source Warehouse',
        },
        {
            className: 'loan',
            onCell: (warehouseProposedSaleDestination: IWarehouseProposedSaleDestination) => {
                const sourceProposedSale: ISourceProposedSale = sourceProposedSales[warehouseProposedSaleDestination.warehouseProposedSaleUuid];

                return {
                    rowSpan: (sourceProposedSale.primaryWarehouseProposedSaleDestinationUuid === warehouseProposedSaleDestination.uuid) ? sourceProposedSale.destinationWarehouseCount : 0,
                };
            },
            render: (warehouseProposedSaleDestination: IWarehouseProposedSaleDestination) => {
                const sourceProposedSale: ISourceProposedSale = sourceProposedSales[warehouseProposedSaleDestination.warehouseProposedSaleUuid];

                return (
                    <Link to={`/warehouses/${sourceProposedSale.sourceWarehouseUuid}/loans/${sourceProposedSale.sourceWarehouseLoan.uuid}`}>
                        {sourceProposedSale.sourceWarehouseLoan.loan.code}
                    </Link>
                );
            },
            title: 'Loan',
            width: '10%',
        },
        {
            className: 'current-holding',
            onCell: (warehouseProposedSaleDestination: IWarehouseProposedSaleDestination) => {
                const sourceProposedSale: ISourceProposedSale = sourceProposedSales[warehouseProposedSaleDestination.warehouseProposedSaleUuid];

                return {
                    rowSpan: (sourceProposedSale.primaryWarehouseProposedSaleDestinationUuid === warehouseProposedSaleDestination.uuid) ? sourceProposedSale.destinationWarehouseCount : 0,
                };
            },
            render: (warehouseProposedSaleDestination: IWarehouseProposedSaleDestination) => {
                const sourceProposedSale: ISourceProposedSale = sourceProposedSales[warehouseProposedSaleDestination.warehouseProposedSaleUuid];

                return currencyFormatter.format(sourceProposedSale.sourceWarehouseLoan.balancePrincipal);
            },
            title: 'Current Holding',
            width: '15%',
        },
        {
            className: 'sale-total',
            onCell: (warehouseProposedSaleDestination: IWarehouseProposedSaleDestination) => {
                const sourceProposedSale: ISourceProposedSale = sourceProposedSales[warehouseProposedSaleDestination.warehouseProposedSaleUuid];

                return {
                    rowSpan: (sourceProposedSale.primaryWarehouseProposedSaleDestinationUuid === warehouseProposedSaleDestination.uuid) ? sourceProposedSale.destinationWarehouseCount : 0,
                };
            },
            render: (warehouseProposedSaleDestination: IWarehouseProposedSaleDestination) => {
                const sourceProposedSale: ISourceProposedSale = sourceProposedSales[warehouseProposedSaleDestination.warehouseProposedSaleUuid];

                return currencyFormatter.format(sourceProposedSale.saleTotal * -1);
            },
            title: 'Sale Total',
            width: '15%',
        },
        {
            dataIndex: 'warehouseUuid',
            render: (warehouseUuid: string) => <Link to={`/warehouses/${warehouseUuid}`}>{warehouses[warehouseUuid].name}</Link>,
            title: 'Destination Warehouse',
            width: '15%',
        },
        {
            dataIndex: 'amount',
            render: (amount: number, warehouseProposedSaleDestination: IWarehouseProposedSaleDestination) => {
                const sourceProposedSale: ISourceProposedSale = sourceProposedSales[warehouseProposedSaleDestination.warehouseProposedSaleUuid];

                const destinationWarehouse: IWarehouse = warehouses[warehouseProposedSaleDestination.warehouseUuid];

                let warning: string;

                if (0.0 === sourceProposedSale.sourceWarehouseLoan.loan.amountRemaining) {
                    warning = 'Loan has been discharged';
                } else if ((sourceProposedSale.sourceWarehouseLoan.balancePrincipal * -1) < (sourceProposedSale.saleTotal * -1)) {
                    warning = 'Source warehouse principal balance is insufficient for sale amount';
                } else if ([WarehouseTypeEnum.Fwt1, WarehouseTypeEnum.Fwt2].includes(destinationWarehouse.type) && sourceProposedSale.sourceWarehouseLoan.loan.amountRemaining !== (amount * -1)) {
                    warning = 'Sale is not for the full loan amount';
                }

                if (warning) {
                    return (
                        <Popover content={warning}>
                            <Space>
                                {currencyFormatter.format(amount * -1)}
                                <WarningOutlined/>
                            </Space>
                        </Popover>
                    );
                }

                return currencyFormatter.format(amount * -1);
            },
            title: 'Destination Amount',
            width: '10%',
        },
        {
            className: 'proposed-actions',
            onCell: (warehouseProposedSaleDestination: IWarehouseProposedSaleDestination) => {
                const sourceProposedSale: ISourceProposedSale = sourceProposedSales[warehouseProposedSaleDestination.warehouseProposedSaleUuid];

                return {
                    rowSpan: (sourceProposedSale.primaryWarehouseProposedSaleDestinationUuid === warehouseProposedSaleDestination.uuid) ? sourceProposedSale.destinationWarehouseCount : 0,
                };
            },
            render: (warehouseProposedSaleDestination: IWarehouseProposedSaleDestination) => {
                const sourceProposedSale: ISourceProposedSale = sourceProposedSales[warehouseProposedSaleDestination.warehouseProposedSaleUuid];

                const onClickCancel: () => void = () => {
                    dispatch(warehouseProposedSaleCancelAction(sourceProposedSale.uuid));
                };

                if (0.0 === sourceProposedSale.sourceWarehouseLoan.loan.amountRemaining) {
                    return (
                        <Button danger={true} onClick={onClickCancel}>
                            Cancel
                        </Button>
                    );
                }

                const onClickProcess: () => void = () => {
                    Modal.confirm({
                        content: 'Are you sure you want to process this proposed sale?',
                        okText: 'Confirm',
                        onOk: () => {
                            dispatch(warehouseProposedSaleProcessAction(sourceProposedSale.uuid));
                        },
                        title: 'Process Proposed Sale',
                    });
                };

                const actionsMenu: MenuProps = {
                    items: [
                        ...([WarehouseTypeEnum.Fwt1, WarehouseTypeEnum.Fwt2].includes(warehouses[warehouseProposedSaleDestination.warehouseUuid].type) ? [
                            {
                                key: 'download-offer-to-sell',
                                label: (
                                    <a href={`${process.env.API_HOST}/warehouse-proposed-sales/${sourceProposedSale.uuid}/download-offer-to-sell`} rel='noreferrer' target='_blank'>
                                        Download Offer To Sell
                                    </a>
                                ),
                            },
                        ] : []),
                        {
                            danger: true,
                            key: 'cancel',
                            label: 'Cancel',
                            onClick: onClickCancel,
                        },
                    ],
                };

                const isDisabled: boolean = dayjs(sourceProposedSale.transactionTime).isSameOrAfter(dayjs().endOf('d'));

                return (
                    <Space>
                        <Button type='primary' disabled={isDisabled} onClick={onClickProcess}>
                            Process
                        </Button>
                        <Dropdown.Button menu={actionsMenu} className='secondary-proposed-actions'/>
                    </Space>
                );
            },
            width: '10%',
        },
    ];

    return (
        <Layout className='warehouses proposed-sales'>
            <Breadcrumb className='breadcrumb'>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item><Link to='/warehouses'>Warehouses</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Proposed Warehouse Sales</Breadcrumb.Item>
            </Breadcrumb>
            <Layout className='content-wrapper'>
                <Layout.Content className='content'>
                    <Typography.Title level={2}>Proposed Warehouse Sales</Typography.Title>
                    <Table
                        columns={columns}
                        dataSource={_.values(warehouseProposedSaleDestinations)}
                        pagination={false}
                        rowKey='uuid'
                        size='middle'
                    />
                </Layout.Content>
            </Layout>
        </Layout>
    );
}
