import { CheckOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Modal, Spin } from 'antd';
import _ from 'lodash';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { currentAdministratorSelector } from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import RoleEnum from '~Api/Administrator/RoleEnum';
import IApplication from '~Api/Application/IApplication';
import IBorrower from '~Api/Application/IBorrower';
import ConditionDocumentApprovalStatusEnum from '~Api/Application/ConditionDocumentApprovalStatusEnum';
import ConditionTypeEnum from '~Api/Application/ConditionTypeEnum';
import ICondition from '~Api/Application/ICondition';
import IConditionDocument from '~Api/Application/IConditionDocument';
import IDocument from '~Api/Deal/IDocument';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import { dealDocumentsListAction } from '~Deals/actions';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import {
    dealDocumentsSelector,
} from '~Deals/selectors';
import {
    applicationConditionDocumentApproveAction,
    applicationConditionDocumentDeleteAction,
    applicationConditionDocumentRejectAction,
    applicationConditionDocumentResetApprovalStatusAction,
    applicationConditionDocumentsListAction,
    applicationConditionsListAction,
} from '~Applications/actions';
import {
    applicationConditionDocumentsSelector,
    applicationConditionsSelector,
} from '~Applications/selectors';
import {
    conditionLabels,
    filterApplicationConditionDocuments,
    filterBorrowerConditionDocuments,
    filterCustomConditionDocuments,
    filterPropertyConditionDocuments,
} from '~Applications/utilities';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import { BsCloud, BsCloudCheck, BsCloudSlash } from 'react-icons/bs';

const documentApprovalStatusClassNames: IDictionary<string> = {
    [ConditionDocumentApprovalStatusEnum.Approved]: 'approved',
    [ConditionDocumentApprovalStatusEnum.Legacy]: 'legacy',
    [ConditionDocumentApprovalStatusEnum.Pending]: 'pending',
    [ConditionDocumentApprovalStatusEnum.Rejected]: 'rejected',
};

export default function UploadedConditionDocumentList(props: {
    application: IApplication;
    applicationBorrower?: IBorrower;
    applicationProperty?: IApplicationProperty;
    conditionUuid?: string;
    conditionType: ConditionTypeEnum;
}): ReactElement {
    const {
        application,
        applicationBorrower,
        applicationProperty,
        conditionUuid,
        conditionType,
    } = props;

    const conditionDocuments: IDictionary<IConditionDocument> = useSelector((state: IGlobalState) => applicationConditionDocumentsSelector(state, application.uuid));
    const conditions: IDictionary<ICondition> = useSelector((state: IGlobalState) => applicationConditionsSelector(state, application.uuid));
    const currentAdministrator: IAdministrator = useSelector(currentAdministratorSelector);
    const currentUser: IAuthUser = useSelector(authCurrentUserSelector);
    const documents: IDictionary<IDocument> = useSelector((state: IGlobalState) => dealDocumentsSelector(state, application.dealUuid));

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!conditionDocuments) {
            dispatch(applicationConditionDocumentsListAction(application.uuid));
        }
    }, [
        application.uuid,
        conditionDocuments,
        dispatch,
    ]);

    useEffect(() => {
        if (!conditions) {
            dispatch(applicationConditionsListAction(application.uuid));
        }
    }, [
        application.uuid,
        conditions,
        dispatch,
    ]);

    useEffect(() => {
        if (!documents) {
            dispatch(dealDocumentsListAction(application.dealUuid));
        }
    }, [
        application.dealUuid,
        dispatch,
        documents,
    ]);

    if (!application || !conditionDocuments || !conditions || !currentAdministrator || !documents) {
        return (
            <Spin size='small' />
        );
    }

    const canApproveDocuments: boolean = (
        application.administratorUuid === currentAdministrator.uuid
        || currentAdministrator.role === RoleEnum.SeniorCreditManager
        || currentUser.permissions.includes(PermissionsEnum.ApplicationConditionDocumentApprove)
    );

    let matchedDocuments: IConditionDocument[] = [];
    if (applicationBorrower) {
        matchedDocuments = filterBorrowerConditionDocuments(_.values(conditionDocuments), applicationBorrower, conditionType);
    } else if (applicationProperty) {
        matchedDocuments = filterPropertyConditionDocuments(_.values(conditionDocuments), applicationProperty, conditionType);
    } else if (conditionUuid && conditions && _.size(conditions) > 0) {
        matchedDocuments = filterCustomConditionDocuments(_.values(conditionDocuments), conditions[conditionUuid]);
    } else {
        matchedDocuments = filterApplicationConditionDocuments(_.values(conditionDocuments), application, conditionType);
    }

    const uploadedFiles: JSX.Element[] = matchedDocuments.map((conditionDocument: IConditionDocument) => {
        const isApproved: boolean = [ConditionDocumentApprovalStatusEnum.Approved, ConditionDocumentApprovalStatusEnum.Legacy].includes(conditionDocument.approvalStatus);
        const isPending: boolean = conditionDocument.approvalStatus === ConditionDocumentApprovalStatusEnum.Pending;

        const menu: MenuProps = {
            className: 'uploaded-condition-document-dropdown',
            items: [
                {
                    children: [
                        ...(canApproveDocuments && isPending ? [
                            {
                                icon: <CheckOutlined/>,
                                key: `${conditionDocument.uuid}-approve`,
                                label: 'Approve',
                                onClick: () => {
                                    Modal.confirm({
                                        content: `Are you sure you want to approve this ${conditionLabels[conditionDocument.conditionType] || 'Custom Condition'} document?`,
                                        okText: 'Approve',
                                        onOk: () => {
                                            dispatch(applicationConditionDocumentApproveAction(application.uuid, conditionDocument.uuid));
                                        },
                                        title: 'Approve Document',
                                    });
                                },
                            },
                        ] : []),
                        ...(canApproveDocuments && isPending ? [
                            {
                                icon: <CloseOutlined/>,
                                key: `${conditionDocument.uuid}-reject`,
                                label: 'Reject',
                                onClick: () => {
                                    Modal.confirm({
                                        content: `Are you sure you want to reject this ${conditionLabels[conditionDocument.conditionType] || 'Custom Condition'} document?`,
                                        okText: 'Reject',
                                        onOk: () => {
                                            dispatch(applicationConditionDocumentRejectAction(application.uuid, conditionDocument.uuid));
                                        },
                                        title: 'Reject Document',
                                    });
                                },
                            },
                        ] : []),
                        ...(canApproveDocuments && !isPending ? [
                            {
                                icon: <CloseOutlined/>,
                                key: `${conditionDocument.uuid}-reset`,
                                label: !isApproved ? 'Unreject' : 'Unapprove',
                                onClick: () => {
                                    const actionText: string = [ConditionDocumentApprovalStatusEnum.Approved, ConditionDocumentApprovalStatusEnum.Legacy].includes(conditionDocument.approvalStatus) ? 'Unapprove' : 'Unreject';

                                    Modal.confirm({
                                        content: `Are you sure you want to ${actionText.toLocaleLowerCase()} this ${conditionLabels[conditionDocument.conditionType] || 'Custom Condition'} document?`,
                                        okText: actionText,
                                        onOk: () => {
                                            dispatch(applicationConditionDocumentResetApprovalStatusAction(application.uuid, conditionDocument.uuid));
                                        },
                                        title: `${actionText} Document`,
                                    });
                                },
                            },
                        ] : []),
                        {
                            danger: true,
                            icon: <DeleteOutlined/>,
                            key: `${conditionDocument.uuid}-delete`,
                            label: 'Delete',
                            onClick: () => {
                                Modal.confirm({
                                    content: `Are you sure you want to delete this ${conditionLabels[conditionDocument.conditionType] || 'Custom Condition'} document?`,
                                    okText: 'Delete',
                                    okType: 'danger',
                                    onOk: () => {
                                        dispatch(applicationConditionDocumentDeleteAction(application.uuid, conditionDocument.uuid));
                                    },
                                    title: 'Delete Document',
                                });
                            },
                        },
                    ],
                    label: documents[conditionDocument.documentUuid] ? documents[conditionDocument.documentUuid].filename : '-',
                    type: 'group',
                },
            ],
        };

        return (
            <Dropdown key={conditionDocument.uuid} menu={menu}>
                <Button
                    href={`${process.env.API_HOST}/deal-documents/${conditionDocument.documentUuid}/download`}
                    target='_blank'
                    type='link'
                    className={`document ${documentApprovalStatusClassNames[conditionDocument.approvalStatus]}`}
                >
                    {ConditionDocumentApprovalStatusEnum.Approved === conditionDocument.approvalStatus && <BsCloudCheck size={16} />}
                    {ConditionDocumentApprovalStatusEnum.Rejected === conditionDocument.approvalStatus && <BsCloudSlash size={16} />}
                    {ConditionDocumentApprovalStatusEnum.Pending === conditionDocument.approvalStatus && <BsCloud size={16} />}
                    {ConditionDocumentApprovalStatusEnum.Legacy === conditionDocument.approvalStatus && <BsCloud size={16} />}
                </Button>
            </Dropdown>
        );
    });

    return (
        <div className='uploaded-condition-document-list'>
            {uploadedFiles}
        </div>
    );
}
