import { DownOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Column, ColumnConfig, G2, Pie, Plot } from '@ant-design/plots';
import { IGroup } from '@antv/g2';
import { PieOptions } from '@antv/g2plot';
import { ColumnOptions } from '@antv/g2plot/lib/plots/column';
import { Annotation } from '@antv/g2plot/lib/types/annotation';
import { IG } from '@antv/g2/lib/dependents';
import { Datum, MappingDatum } from '@antv/g2/lib/interface.d';
import { Breadcrumb, Button, Card, Col, Dropdown, Form, Layout, MenuProps, Radio, RadioChangeEvent, Row, Space, Spin, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import referredToLabels from '~Leads/referredToLabels';
import { IGlobalState } from '~reducer';
import { currencyFormatter, percentageFormatter } from '~utilities/formatters';
import { IDictionary } from '~utilities/IDictionary';
import DatePicker from '~UI/DatePicker';
import {
    ILeadsMovementReportListAction,
    leadsMovementReportListAction,
} from './actions';
import './movement-report.less';
import {
    leadsMovementReportEndTimeSelector,
    leadsMovementReportLeadsSelector,
    leadsMovementReportPeriodRangeSelector,
    leadsMovementReportStartTimeSelector,
} from './selectors';
import ApprovalStatusEnum from '~Api/Deal/ApprovalStatusEnum';
import CloseReasonEnum from '~Api/Deal/CloseReasonEnum';
import IMovementReportLead from '~Api/Deal/IMovementReportLead';
import RejectReasonEnum from '~Api/Deal/RejectReasonEnum';
import { CountOrDollarEnum, DirectionEnum, IPieData, PeriodRangeEnum, directionToParameterMap, periodRangeLabels, rangeToParameterMap } from '~utilities/reportUtilities';

const closeReasonLabels: IDictionary<string> = {
    [CloseReasonEnum.LostToOther]: 'Lost To Other',
    [CloseReasonEnum.Stale]: 'Stale',
};

const rejectReasonLabels: IDictionary<string> = {
    [RejectReasonEnum.AssetClass]: 'Asset Class',
    [RejectReasonEnum.Location]: 'Location',
    [RejectReasonEnum.LostToOther]: 'Lost To Other',
    [RejectReasonEnum.Lvr]: 'LVR',
    [RejectReasonEnum.Product]: 'Product',
};

const workflowStatusLabels: IDictionary<string> = {
    [ApprovalStatusEnum.Application]: 'Application',
    [ApprovalStatusEnum.Quote]: 'Quoted',
    [ApprovalStatusEnum.Referred]: 'Referred',
    [ApprovalStatusEnum.Rejected]: 'Rejected',
};

interface IColumnData {
    assignee: string;
    movement: number;
    status: string;
}

interface IPropsSelector {
    endTime: string;
    movementReportLeads: IDictionary<IMovementReportLead>;
    selectedPeriodRange: PeriodRangeEnum,
    startTime: string;
}

interface IPropsDispatch {
    movementReportLeadsList: (startTime: string, endTime: string, periodRange: PeriodRangeEnum) => ILeadsMovementReportListAction
}

interface IState {
    countOrDollar: CountOrDollarEnum;
    removedAssignees: string[];
}

type Props = IPropsSelector & IPropsDispatch;

class MovementReport extends React.Component<Props> {
    public state: IState = {
        countOrDollar: CountOrDollarEnum.Dollar,
        removedAssignees: [],
    };

    constructor(props: Props) {
        super(props);
        this.onChangeCountValueSwitcher = this.onChangeCountValueSwitcher.bind(this);
        this.onChangeDateRange = this.onChangeDateRange.bind(this);
        this.onChangeSelectedPeriodRange = this.onChangeSelectedPeriodRange.bind(this);
        this.onClickPrevious = this.onClickPrevious.bind(this);
        this.onClickNext = this.onClickNext.bind(this);
    }

    public componentDidMount(): void {
        this.props.movementReportLeadsList(
            dayjs().startOf('week').format('YYYY-MM-DD'),
            dayjs().endOf('week').format('YYYY-MM-DD'),
            PeriodRangeEnum.Week
        );
    }

    public render(): JSX.Element {
        const { endTime, movementReportLeads, selectedPeriodRange, startTime } = this.props;
        const { countOrDollar } = this.state;

        const periodRangeMenu: MenuProps = {
            items: [
                {
                    key: 'day',
                    label: 'Day',
                    onClick: () => this.onChangeSelectedPeriodRange(PeriodRangeEnum.Day),
                },
                {
                    key: 'week',
                    label: 'Week',
                    onClick: () => this.onChangeSelectedPeriodRange(PeriodRangeEnum.Week),
                },
                {
                    key: 'month',
                    label: 'Month',
                    onClick: () => this.onChangeSelectedPeriodRange(PeriodRangeEnum.Month),
                },
                {
                    key: 'year',
                    label: 'Year',
                    onClick: () => this.onChangeSelectedPeriodRange(PeriodRangeEnum.Year),
                },
            ],
        };

        const dateRangeSelector: JSX.Element = (
            <Space className='date-picker'>
                <Button.Group>
                    <Button icon={<LeftOutlined />} onClick={this.onClickPrevious} disabled={!movementReportLeads} />
                    <Dropdown menu={periodRangeMenu} disabled={!movementReportLeads}>
                        <Button>
                            <Space>
                                {periodRangeLabels[selectedPeriodRange]}
                                <DownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>
                    <Button icon={<RightOutlined />} onClick={this.onClickNext} disabled={!movementReportLeads} />
                </Button.Group>
                <Form.Item>
                    <DatePicker.RangePicker
                        picker='date'
                        format='DD/MM/YYYY'
                        onChange={this.onChangeDateRange}
                        value={[startTime ? dayjs(startTime) : null, endTime ? dayjs(endTime) : null]}
                        disabled={!movementReportLeads}
                    />
                </Form.Item>
            </Space>
        );

        const countValueSwitcher: JSX.Element = (
            <Radio.Group
                value={countOrDollar}
                onChange={this.onChangeCountValueSwitcher}
                disabled={!movementReportLeads}
            >
                <Radio.Button value={CountOrDollarEnum.Dollar}>Value</Radio.Button>
                <Radio.Button value={CountOrDollarEnum.Count}>Count</Radio.Button>
            </Radio.Group>
        );

        if (!movementReportLeads) {
            return (
                <Layout className='leads-movement-report'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to='/leads'>Leads</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Movement Report</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Space className='actions'>
                                {dateRangeSelector}
                                {countValueSwitcher}
                            </Space>
                            <Typography.Title level={2}>Leads Movement Report</Typography.Title>
                        </Layout.Content>
                    </Layout>
                    <Spin />
                </Layout>
            );
        }

        const closeReasonData: IDictionary<IPieData> = {};
        const referredToData: IDictionary<IPieData> = {};
        const rejectReasonData: IDictionary<IPieData> = {};
        const statusMovementData: IDictionary<IColumnData> = {};
        const quotedByBdmData: IDictionary<IPieData> = {};
        const applicationByBdmData: IDictionary<IPieData> = {};

        // Initialize status movement so that all statuses are present even if there are no leads in that status
        const allStatuses: string[] = [...Object.values(workflowStatusLabels), 'Closed'];
        allStatuses.forEach((status: string) => {
            statusMovementData[`initial-${status}`] = {
                assignee: 'Unassigned',
                movement: 0,
                status: status,
            };
        });

        _.forEach(movementReportLeads, (lead: IMovementReportLead) => {
            if (!lead.dealWorkflowTimeUuid) {
                statusMovementData[lead.dealUuid] = {
                    assignee: null === lead.administratorName ? 'Unassigned' : lead.administratorName,
                    movement: CountOrDollarEnum.Count === countOrDollar ? 1 : lead.baseLoanAmount,
                    status: 'Closed',
                };
            } else {
                statusMovementData[lead.dealWorkflowTimeUuid] = {
                    assignee: null === lead.administratorName ? 'Unassigned' : lead.administratorName,
                    movement: CountOrDollarEnum.Count === countOrDollar ? 1 : lead.baseLoanAmount,
                    status: workflowStatusLabels[lead.workflowStatus],
                };
            }

            if (lead.workflowStatus === ApprovalStatusEnum.Referred) {
                if (!referredToData[lead.referredTo]) {
                    referredToData[lead.referredTo] = {
                        type: referredToLabels[lead.referredTo],
                        value: 0,
                    };
                }
                referredToData[lead.referredTo].value += (CountOrDollarEnum.Count === countOrDollar ? 1 : lead.baseLoanAmount);
            }

            if (lead.workflowStatus === ApprovalStatusEnum.Rejected) {
                if (!rejectReasonData[lead.rejectReason]) {
                    rejectReasonData[lead.rejectReason] = {
                        type: rejectReasonLabels[lead.rejectReason],
                        value: 0,
                    };
                }
                rejectReasonData[lead.rejectReason].value += (CountOrDollarEnum.Count === countOrDollar ? 1 : lead.baseLoanAmount);
            }

            if (!lead.dealWorkflowTimeUuid) {
                const closedReasonType: string = lead.closeReason === null ? 'No Reason Stated' : closeReasonLabels[lead.closeReason];
                if (!closeReasonData[lead.closeReason]) {
                    closeReasonData[lead.closeReason] = {
                        type: closedReasonType,
                        value: 0,
                    };
                }
                closeReasonData[lead.closeReason].value += (CountOrDollarEnum.Count === countOrDollar ? 1 : lead.baseLoanAmount);
            }

            const bdmLabel: string = lead.bdmUuid === null ? 'No BDM' : lead.bdmName;
            if (lead.workflowStatus === ApprovalStatusEnum.Quote) {
                if (!quotedByBdmData[lead.bdmUuid]) {
                    quotedByBdmData[lead.bdmUuid] = {
                        type: bdmLabel,
                        value: 0,
                    };
                }
                quotedByBdmData[lead.bdmUuid].value += (CountOrDollarEnum.Count === countOrDollar ? 1 : lead.baseLoanAmount);
            }

            if (lead.workflowStatus === ApprovalStatusEnum.Application) {
                if (!applicationByBdmData[lead.bdmUuid]) {
                    applicationByBdmData[lead.bdmUuid] = {
                        type: bdmLabel,
                        value: 0,
                    };
                }
                applicationByBdmData[lead.bdmUuid].value += (CountOrDollarEnum.Count === countOrDollar ? 1 : lead.baseLoanAmount);
            }
        });

        return (
            <Layout className='leads-movement-report'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to='/leads'>Leads</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>Movement Report</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Space className='actions'>
                            {dateRangeSelector}
                            {countValueSwitcher}
                        </Space>
                        <Typography.Title level={2}>Leads Movement Report</Typography.Title>
                    </Layout.Content>
                </Layout>
                <Row>
                    <Col span={12}>
                        <Card bordered={false} className='chart-left' >
                            <Typography.Title level={4}>Status Movement</Typography.Title>
                            {this.renderColumnChart(_.values(statusMovementData))}
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card bordered={false} className='chart-right'>
                            <Typography.Title level={4}>Referred To</Typography.Title>
                            {referredToData && Object.keys(referredToData).length === 0 && 'No referred leads'}
                            {this.renderPieChart(_.values(referredToData))}
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Card bordered={false} className='chart-left' >
                            <Typography.Title level={4}>Reject Reason</Typography.Title>
                            {rejectReasonData && Object.keys(rejectReasonData).length === 0 && 'No rejected leads'}
                            {this.renderPieChart(_.values(rejectReasonData))}
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card bordered={false} className='chart-right'>
                            <Typography.Title level={4}>Close Reason</Typography.Title>
                            {closeReasonData && Object.keys(closeReasonData).length === 0 && 'No closed leads'}
                            {this.renderPieChart(_.values(closeReasonData))}
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Card bordered={false} className='chart-left' >
                            <Typography.Title level={4}>Quoted By BDM</Typography.Title>
                            {this.renderPieChart(_.values(quotedByBdmData))}
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card bordered={false} className='chart-right'>
                            <Typography.Title level={4}>Application By BDM</Typography.Title>
                            {this.renderPieChart(_.values(applicationByBdmData))}
                        </Card>
                    </Col>
                </Row>
            </Layout>
        );
    }

    private onChangeSelectedPeriodRange(selectedPeriodRange: PeriodRangeEnum): void {
        const updatedEndDate: string = dayjs().endOf(rangeToParameterMap[selectedPeriodRange]).format('YYYY-MM-DD');
        const updatedStartDate: string = dayjs().startOf(rangeToParameterMap[selectedPeriodRange]).format('YYYY-MM-DD');

        this.props.movementReportLeadsList(updatedStartDate, updatedEndDate, selectedPeriodRange);
    }

    private onClickPrevious(): void {
        this.onChangePeriod(DirectionEnum.Previous);
    }

    private onClickNext(): void {
        this.onChangePeriod(DirectionEnum.Next);
    }

    private onChangeDateRange(dateRange: [Dayjs, Dayjs]): void {
        const { selectedPeriodRange } = this.props;
        const updatedEndDate: string = dateRange[1].format('YYYY-MM-DD');
        const updatedStartDate: string = dateRange[0].format('YYYY-MM-DD');
        this.props.movementReportLeadsList(updatedStartDate, updatedEndDate, selectedPeriodRange);
    }

    private onChangePeriod(direction: DirectionEnum): void {
        const { selectedPeriodRange, startTime } = this.props;

        const from: Dayjs = (startTime ? dayjs(startTime) : dayjs());
        const updatedStartDate: Dayjs = from.add(directionToParameterMap[direction], rangeToParameterMap[selectedPeriodRange]).startOf(rangeToParameterMap[selectedPeriodRange]);
        const updatedEndDate: Dayjs = updatedStartDate.endOf(rangeToParameterMap[selectedPeriodRange]);

        this.props.movementReportLeadsList(updatedStartDate.format('YYYY-MM-DD'), updatedEndDate.format('YYYY-MM-DD'), selectedPeriodRange);
    }

    private onChangeCountValueSwitcher(e: RadioChangeEvent): void {
        this.setState({
            countOrDollar: e.target.value,
        });
    }

    private renderColumnChart(data: IColumnData[]): JSX.Element {
        const { removedAssignees } = this.state;
        const originalAssignees: string[] = _.uniq(data.map((d: IColumnData) => d.assignee));
        const selectedData: IColumnData[] = _.isEmpty(removedAssignees) ? data : data.filter((d: IColumnData) => !removedAssignees.includes(d.assignee));

        const annotations: Annotation[] = [];
        _.each(_.groupBy(selectedData, 'status'), (values: IColumnData[], k: string) => {
            const value: number = values.reduce((a: number, b: IColumnData) => a + b.movement, 0);
            annotations.push({
                content: (this.state.countOrDollar === CountOrDollarEnum.Count) ? `${value}` : `${currencyFormatter.format(value)}`,
                offsetY: -10,
                position: [k, value],
                style: {
                    fill: 'rgba(0,0,0,0.85)',
                    fontSize: 12,
                    textAlign: 'center',
                },
                type: 'text',
            });
        });

        const config: ColumnConfig = {
            annotations,
            data: selectedData,
            isStack: true,
            label: {
                content: null,
                layout: [
                    {
                        type: 'interval-adjust-position',
                    },
                    {
                        type: 'adjust-color',
                    },
                ],
                position: 'middle',
            },
            legend: {
                custom: true,
                items: originalAssignees.map((assignee: string) => {
                    return {
                        name: assignee,
                        unchecked: removedAssignees.includes(assignee),
                        value: assignee,
                    };
                }),
            },
            onReady: (plot: Plot<ColumnOptions>) => {
                plot.on('legend-item:click', (evt: any) => {
                    const { removedAssignees: prevRemovedAssignees } = this.state;
                    const filteredAssignees: string[] = _.uniq(evt.view.filteredData.map((d: IColumnData) => d.assignee));
                    const clickedAssignee: string = evt.gEvent.target.attrs.text;
                    const removedAssignees: string[] = _.difference(originalAssignees, filteredAssignees);
                    const updatedRemovedAssignees: string[] = prevRemovedAssignees.includes(clickedAssignee) ? _.without(removedAssignees, clickedAssignee) : removedAssignees;

                    this.setState({
                        removedAssignees: updatedRemovedAssignees,
                    });
                });
            },
            seriesField: 'assignee',
            tooltip: {
                formatter: (data: Datum) => {
                    return {
                        name: data.assignee,
                        value: (this.state.countOrDollar === CountOrDollarEnum.Count) ? data.movement : `${currencyFormatter.format(data.movement)}`,
                    };
                },
            },
            xField: 'status',
            yAxis: {
                label: {
                    formatter: (text: string) => {
                        const value: number = parseFloat(text);
                        return (this.state.countOrDollar === CountOrDollarEnum.Count) ? value.toString() : currencyFormatter.format(value);
                    },
                },
            },
            yField: 'movement',
        };

        return <Column {...config} />;
    }

    private renderPieChart(data: IPieData[]): JSX.Element {
        const G: IG = G2.getEngine('canvas');
        const labelFormatter: (data: Datum, mappingData: MappingDatum) => IGroup = (data: Datum, mappingData: MappingDatum) => {
            const group: IGroup = new G.Group({});
            group.addShape({
                attrs: {
                    fill: mappingData.color,
                    height: 50,
                    r: 5,
                    width: 40,
                    x: 0,
                    y: 0,
                },
                type: 'circle',
            });
            group.addShape({
                attrs: {
                    fill: mappingData.color,
                    text: `${data.type}`,
                    x: 10,
                    y: 8,
                },
                type: 'text',
            });
            group.addShape({
                attrs: {
                    fill: 'rgba(0, 0, 0, 0.65)',
                    fontWeight: 700,
                    text: (this.state.countOrDollar === CountOrDollarEnum.Count)
                        ? `${data.value} - ${percentageFormatter.format(data.percent)}`
                        : `${currencyFormatter.format(data.value)} - ${percentageFormatter.format(data.percent)}`,
                    x: 0,
                    y: 25,
                },
                type: 'text',
            });
            return group;
        };

        const config: PieOptions = {
            angleField: 'value',
            appendPadding: 10,
            colorField: 'type',
            data,
            interactions: [
                {
                    type: 'element-selected',
                },
                {
                    type: 'element-active',
                },
            ],
            label: {
                formatter: labelFormatter,
                labelHeight: 40,
                type: 'spider',
            },
            radius: 0.75,
            tooltip: {
                formatter: (data: Datum) => {
                    return {
                        name: data.type,
                        value: (this.state.countOrDollar === CountOrDollarEnum.Count) ? data.value : `${currencyFormatter.format(data.value)}`,
                    };
                },
            },
        };

        return <Pie {...config} />;
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        endTime: leadsMovementReportEndTimeSelector(state),
        movementReportLeads: leadsMovementReportLeadsSelector(state),
        selectedPeriodRange: leadsMovementReportPeriodRangeSelector(state),
        startTime: leadsMovementReportStartTimeSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        movementReportLeadsList: (startTime: string, endTime: string, periodRange: PeriodRangeEnum) => dispatch(leadsMovementReportListAction(startTime, endTime, periodRange)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MovementReport);
