import { Layout as AntLayout, Breadcrumb, Button, Menu, Modal, PageHeader, Space, Spin } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Dispatch } from 'redux';
import IInvestmentSaleTransfer from '~Api/InvestmentSaleTransfer/IInvestmentSaleTransfer';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import {
    investmentSaleTransferGetAction,
    investmentSaleTransferSendAction,
} from '~InvestmentSaleTransfers/actions';
import { investmentSaleTransferSelector } from '~InvestmentSaleTransfers/selectors';
import { IGlobalState } from '~reducer';
import './investment-sale-transfer.less';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import menuDivider from '~UI/menuDivider';

interface IProps {
    investmentSaleTransferUuid: string;
    breadcrumb?: JSX.Element;
    children: JSX.Element | JSX.Element[];
    section: string;
}

interface IPropsSelector {
    investmentSaleTransfer: IInvestmentSaleTransfer;
    currentUser: IAuthUser;
}

interface IPropsDispatch {
    investmentSaleTransferGet: () => void;
    investmentSaleTransferSend: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Layout extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onClickSend = this.onClickSend.bind(this);
    }

    public componentDidMount(): void {
        const { investmentSaleTransfer } = this.props;

        if (!investmentSaleTransfer) {
            this.props.investmentSaleTransferGet();
        }
    }

    public render(): JSX.Element {
        const { breadcrumb, children, section, investmentSaleTransfer } = this.props;

        if (!investmentSaleTransfer) {
            return (
                <AntLayout className='investment-sale-transfer'>
                    <Spin />
                </AntLayout>
            );
        }

        const breadcrumbLabel: string = `${dayjs(investmentSaleTransfer.createdTime).format('DD/MM/YYYY HH:mm')} ${investmentSaleTransfer.investment ? '- ' + (investmentSaleTransfer.investment.code + ' / ' + investmentSaleTransfer.investment.loanCode)  : ''}`;

        const breadcrumbBlock: JSX.Element = breadcrumb ? (
            <React.Fragment>
                <Breadcrumb.Item><Link to={`/investment-sale-transfers/${investmentSaleTransfer.uuid}`}>{`${breadcrumbLabel}`}</Link></Breadcrumb.Item>
                {breadcrumb}
            </React.Fragment>
        ) : (
            <Breadcrumb.Item>{breadcrumbLabel}</Breadcrumb.Item>
        );

        const extra: JSX.Element = (
            <Space>
                <Button href={`${process.env.API_HOST}/investment-sale-transfers/${investmentSaleTransfer.uuid}/instructions`} target='_blank'>
                    Trustee Instructions
                </Button>
                <Button onClick={this.onClickSend} type={'primary'}>Send</Button>
            </Space>
        );

        const menuItems: ItemType[] = [
            {
                key: 'transactions',
                label: <NavLink to={`/investment-sale-transfers/${investmentSaleTransfer.uuid}/transactions`}>Transactions</NavLink>,
            },
            menuDivider,
            {
                key: 'history',
                label: <NavLink to={`/investment-sale-transfers/${investmentSaleTransfer.uuid}/history`}>History</NavLink>,
            },
        ];

        return (
            <AntLayout className='investment-sale-transfer'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to='/investment-sale-transfers/'>Investment Sale Transfers</Link></Breadcrumb.Item>
                    {breadcrumbBlock}
                </Breadcrumb>
                <PageHeader
                    className='header'
                    extra={extra}
                    ghost={false}
                    title={breadcrumbLabel}
                />
                <AntLayout className='content-wrapper'>
                    <AntLayout.Sider className='menu' theme='light' width={240}>
                        <Menu
                            defaultSelectedKeys={[section]}
                            items={menuItems}
                            mode='inline'
                        />
                    </AntLayout.Sider>
                    <AntLayout.Content className={`content ${section}`}>
                        {children}
                    </AntLayout.Content>
                </AntLayout>
            </AntLayout>
        );
    }

    private onClickSend(): void {
        Modal.confirm({
            content: 'Are you sure you want to send the Trustee Instructions?',
            okText: 'Send',
            onOk: () => {
                this.props.investmentSaleTransferSend();
            },
            title: 'Send',
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        currentUser: authCurrentUserSelector(state),
        investmentSaleTransfer: investmentSaleTransferSelector(state, ownProps.investmentSaleTransferUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        investmentSaleTransferGet: () => dispatch(investmentSaleTransferGetAction(ownProps.investmentSaleTransferUuid)),
        investmentSaleTransferSend: () => dispatch(investmentSaleTransferSendAction(ownProps.investmentSaleTransferUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Layout);