import { Button, Col, Dropdown, Form, Input, MenuProps, Modal, Row, Select, Space, Spin, Typography } from 'antd';
import React from 'react';
import _ from 'lodash';
import objectHash from 'object-hash';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import roundTo from 'round-to';
import { DownOutlined } from '@ant-design/icons';
import ApplicationFeeFormatEnum from '~Api/Deal/ApplicationFeeFormatEnum';
import BrokerageFeeFormatEnum from '~Api/Deal/BrokerageFeeFormatEnum';
import EstablishmentFeeFormatEnum from '~Api/Deal/EstablishmentFeeFormatEnum';
import IDeal from '~Api/Deal/IDeal';
import IPostcodeCategory from '~Api/Deal/IPostcodeCategory';
import IQuote from '~Api/Deal/IQuote';
import MortgageTypeEnum from '~Api/Deal/MortgageTypeEnum';
import PaymentTypeEnum from '~Api/Deal/PaymentTypeEnum';
import constants from '~constants';
import { dealPropertiesPostcodeCategoriesListAction } from '~Deals/actions';
import { dealPropertiesPostcodeCategoriesSelector } from '~Deals/selectors';
import {
    IDealCalculateParameters,
    IDealCalculatedAmounts,
    getDealCalculatedAmounts,
    getDealCalculatedEstablishmentFeePercentage,
    getDealCalculatedInterestRate,
} from '~Deals/utilities';
import {
    leadGetAction,
    leadQuotesAddAction,
    leadValueSetAction,
} from '~Leads/actions';
import { leadSelector } from '~Leads/selectors';
import {
    IDealCalculateValidationErrors,
    validateDealEstablishmentFee,
    validateDealInterestRate,
    validateDealLegalFees,
    validateDealMaximumLvr,
} from '~Leads/validators';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';
import { currencyFormatter, percentageFormatter } from '~utilities/formatters';

const mortgageTypeLabels: IDictionary<string> = {
    [MortgageTypeEnum.FirstMortgage]: 'First Mortgage',
    [MortgageTypeEnum.SecondMortgage]: 'Second Mortgage',
};

interface IState {
    applicationFeeDollars: number;
    applicationFeeFormat: ApplicationFeeFormatEnum;
    applicationFeePercentage: number;
    brokerageFeeDollars: number;
    brokerageFeeFormat: BrokerageFeeFormatEnum;
    brokerageFeePercentage: number;
    calculateParametersHash: string;
    calculatedAmounts: IDealCalculatedAmounts;
    commitmentFee: number;
    dirtyFields: IDictionary<boolean>;
    errors: IDealCalculateValidationErrors;
    establishmentFeeDollars: number;
    establishmentFeeFormat: EstablishmentFeeFormatEnum;
    establishmentFeePercentage: number;
    estimatedOutlays: number;
    interestRate: number;
    isMissingDetailsModalOpen: boolean;
    isQuoteSending: boolean;
    isQuoting: boolean;
    legalFees: number;
    maximumLvr: number;
    mortgageType: MortgageTypeEnum;
    termMonths: number;
}

interface IMatch {
    dealUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    deal: IDeal;
    postcodeCategories: IDictionary<IPostcodeCategory>;
}

interface IPropsDispatch {
    leadGet: () => void;
    leadValueSet: (key: keyof IDeal, value: boolean|number|string) => void;
    postcodeCategoriesList: () => void;
    quoteAdd: (quote: IQuote, sendEmail: boolean, isScenario: boolean) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Overview extends React.Component<Props, IState> {
    public state: IState = {
        applicationFeeDollars: null,
        applicationFeeFormat: ApplicationFeeFormatEnum.Percentage,
        applicationFeePercentage: null,
        brokerageFeeDollars: null,
        brokerageFeeFormat: BrokerageFeeFormatEnum.Percentage,
        brokerageFeePercentage: null,
        calculateParametersHash: null,
        calculatedAmounts: null,
        commitmentFee: null,
        dirtyFields: {},
        errors: {},
        establishmentFeeDollars: null,
        establishmentFeeFormat: EstablishmentFeeFormatEnum.Percentage,
        establishmentFeePercentage: null,
        estimatedOutlays: 600,
        interestRate: null,
        isMissingDetailsModalOpen: true,
        isQuoteSending: false,
        isQuoting: false,
        legalFees: constants.DEAL_LEGAL_FEES_DOLLARS_DEFAULT,
        maximumLvr: 60,
        mortgageType: null,
        termMonths: null,
    };

    private debouncedCalculateDealAmounts: () => void = null;

    constructor(props: Props) {
        super(props);

        this.getCalculatedInterestRate = this.getCalculatedInterestRate.bind(this);
        this.getCalculatedEstablishmentFeePercentage = this.getCalculatedEstablishmentFeePercentage.bind(this);
        this.onClickApplicationFeeFormat = this.onClickApplicationFeeFormat.bind(this);
        this.onClickBrokerageFeeFormat = this.onClickBrokerageFeeFormat.bind(this);
        this.onClickEstablishmentFeeFormat = this.onClickEstablishmentFeeFormat.bind(this);
        this.createQuote = this.createQuote.bind(this);
        this.onClickQuote = this.onClickQuote.bind(this);
        this.onClickQuoteEmail = this.onClickQuoteEmail.bind(this);
        this.onClickScenarioQuote = this.onClickScenarioQuote.bind(this);
        this.onClickScenarioQuoteEmail = this.onClickScenarioQuoteEmail.bind(this);

        this.onChangeApplicationFeePercentage = this.onChangeApplicationFeePercentage.bind(this);
        this.onChangeApplicationFeeDollars = this.onChangeApplicationFeeDollars.bind(this);
        this.onChangeBrokerageFeePercentage = this.onChangeBrokerageFeePercentage.bind(this);
        this.onChangeBrokerageFeeDollars = this.onChangeBrokerageFeeDollars.bind(this);
        this.onChangeCommitmentFee = this.onChangeCommitmentFee.bind(this);
        this.onChangeEstablishmentFeePercentage = this.onChangeEstablishmentFeePercentage.bind(this);
        this.onChangeEstablishmentFeeDollars = this.onChangeEstablishmentFeeDollars.bind(this);
        this.onChangeEstimatedOutlays = this.onChangeEstimatedOutlays.bind(this);
        this.onChangeInterestRate = this.onChangeInterestRate.bind(this);
        this.onChangeLegalFees = this.onChangeLegalFees.bind(this);
        this.onChangeMaximumLvr = this.onChangeMaximumLvr.bind(this);

        this.validateEstablishmentFee = this.validateEstablishmentFee.bind(this);
        this.validateInterestRate = this.validateInterestRate.bind(this);
        this.validateLegalFees = this.validateLegalFees.bind(this);
        this.validateMaximumLvr = this.validateMaximumLvr.bind(this);

        this.setError = this.setError.bind(this);

        this.onChangeMortgageType = this.onChangeMortgageType.bind(this);
        this.onChangeTermMonths = this.onChangeTermMonths.bind(this);
        this.onClickMissingDetailsCancel = this.onClickMissingDetailsCancel.bind(this);
        this.onClickMissingDetailsOk = this.onClickMissingDetailsOk.bind(this);

        this.debouncedCalculateDealAmounts = _.debounce(this.calculateDealAmounts, 250);
    }

    public componentDidMount(): void {
        const { deal, postcodeCategories } = this.props;

        if (!deal) {
            this.props.leadGet();
        }

        if (!postcodeCategories) {
            this.props.postcodeCategoriesList();
        }

        this.calculateDealAmounts();
    }

    public componentDidUpdate(): void {
        this.debouncedCalculateDealAmounts();
    }

    private calculateDealAmounts(): void {
        const { deal, postcodeCategories } = this.props;

        if (!deal || !postcodeCategories) {
            return;
        }

        const calculateParameters: IDealCalculateParameters = _.omit(this.state, ['calculateParametersHash', 'calculatedAmounts']);

        const calculateParametersHash: string = objectHash(calculateParameters);

        if (calculateParametersHash !== this.state.calculateParametersHash) {
            const calculatedAmounts: IDealCalculatedAmounts = getDealCalculatedAmounts({
                ...calculateParameters,
                establishmentFeePercentage: this.getCalculatedEstablishmentFeePercentage(),
                interestRate: this.getCalculatedInterestRate(),
                mortgageType: deal.mortgageType,
                properties: deal.properties,
                requestedPayoutAmount: deal.loanAmount,
                termMonths: deal.termMonths,
            });

            this.setState({
                calculateParametersHash,
                calculatedAmounts,
            });
        }
    }

    public render(): JSX.Element {
        const { deal, match, postcodeCategories } = this.props;
        const {
            applicationFeeDollars,
            applicationFeeFormat,
            applicationFeePercentage,
            calculatedAmounts,
            commitmentFee,
            brokerageFeeDollars,
            brokerageFeeFormat,
            brokerageFeePercentage,
            errors,
            establishmentFeeDollars,
            establishmentFeeFormat,
            estimatedOutlays,
            isMissingDetailsModalOpen,
            isQuoteSending,
            isQuoting,
            legalFees,
            maximumLvr,
            mortgageType,
            termMonths,
        } = this.state;

        if (!calculatedAmounts || !deal || !postcodeCategories) {
            return (
                <Layout dealUuid={match.params.dealUuid} section='calculator'>
                    <Typography.Title level={2}>Calculator</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        if (!deal.termMonths || !deal.mortgageType) {
            const mortgageTypeField: JSX.Element = !deal.mortgageType && (
                <Form.Item label='Mortgage Type' className='mortgage-type'>
                    <Select onChange={this.onChangeMortgageType} value={mortgageType}>
                        <Select.Option value={MortgageTypeEnum.FirstMortgage}>First Mortgage</Select.Option>
                        <Select.Option value={MortgageTypeEnum.SecondMortgage}>Second Mortgage</Select.Option>
                    </Select>
                </Form.Item>
            );

            const termMonthsField: JSX.Element = !deal.termMonths && (
                <Form.Item label='Loan Term' className='term-months'>
                    <Select onChange={this.onChangeTermMonths} value={termMonths}>
                        {deal.termMonths === null && <Select.Option value={null}>None</Select.Option>}
                        {_.times(36, (months: number) => (<Select.Option key={months + 1} value={months + 1}>{months + 1} months</Select.Option>))}
                    </Select>
                </Form.Item>
            );

            return (
                <Layout dealUuid={match.params.dealUuid} section='calculator'>
                    <Typography.Title level={2}>Calculator</Typography.Title>
                    {!deal.mortgageType && <p>Please set the mortgage type</p>}
                    {!deal.termMonths && <p>Please set the loan term months</p>}
                    <Modal
                        destroyOnClose={true}
                        okText='Confirm'
                        onCancel={this.onClickMissingDetailsCancel}
                        onOk={this.onClickMissingDetailsOk}
                        open={isMissingDetailsModalOpen}
                        title='Missing Details'
                        wrapClassName='lead-calculator-missing-details-modal'
                    >
                        {mortgageTypeField}
                        {termMonthsField}
                    </Modal>
                </Layout>
            );
        }

        const {
            applicationFeeTotal,
            brokerageFeeTotal,
            establishmentFeeTotal,
            grossLoanAmount,
            interestPayable,
            lvr,
            netPrepaidBalanceOnSettlement,
            totalCurrentDebt,
            totalValue,
        } = calculatedAmounts;

        if (!totalValue || totalValue === 0) {
            return (
                <Layout dealUuid={match.params.dealUuid} section='calculator'>
                    <Typography.Title level={2}>Calculator</Typography.Title>
                    <p>Please add a property with an estimated value</p>
                </Layout>
            );
        }

        const interestRate: number = this.getCalculatedInterestRate();
        const establishmentFeePercentage: number = this.getCalculatedEstablishmentFeePercentage();

        const defaultInterestRate: number = interestRate ? roundTo(Number(interestRate + 10), 2) : null;

        const monthlyInterestPaymentsNormal: number = Math.ceil(grossLoanAmount * (interestRate / 100) / 12);
        const monthlyInterestPaymentsDefault: number = Math.ceil(grossLoanAmount * (defaultInterestRate / 100) / 12);
        const grossBalanceOnSettlement: number = grossLoanAmount - interestPayable - establishmentFeeTotal - applicationFeeTotal - brokerageFeeTotal - legalFees - estimatedOutlays;

        const totalCostsDollars: number = grossLoanAmount - netPrepaidBalanceOnSettlement;
        const totalCostsPercentage: number = Number((grossLoanAmount - netPrepaidBalanceOnSettlement) / grossLoanAmount * 100);
        const totalCostsPercentagePerAnnum: number = Number((establishmentFeeTotal + applicationFeeTotal + brokerageFeeTotal + legalFees + estimatedOutlays + (monthlyInterestPaymentsNormal * 12)) / grossLoanAmount * 100);

        const establishmentFeeMenu: MenuProps = {
            items: [
                {
                    key: 'establishment-fee-format',
                    label: establishmentFeeFormat === EstablishmentFeeFormatEnum.Percentage ? 'Dollar Value' : 'Percentage',
                    onClick: this.onClickEstablishmentFeeFormat,
                },
            ],
        };

        const establishmentFeeLabel: JSX.Element = (
            <Dropdown menu={establishmentFeeMenu}>
                <a>Establishment Fee <DownOutlined /></a>
            </Dropdown>
        );

        const applicationFeeMenu: MenuProps = {
            items: [
                {
                    key: 'application-fee-format',
                    label: applicationFeeFormat === ApplicationFeeFormatEnum.Percentage ? 'Dollar Value' : 'Percentage',
                    onClick: this.onClickApplicationFeeFormat,
                },
            ],
        };

        const applicationFeeLabel: JSX.Element = (
            <Dropdown menu={applicationFeeMenu}>
                <a>Application Fee <DownOutlined /></a>
            </Dropdown>
        );

        const brokerageFeeMenu: MenuProps = {
            items: [
                {
                    key: 'brokerage-fee-format',
                    label: brokerageFeeFormat === BrokerageFeeFormatEnum.Percentage ? 'Dollar Value' : 'Percentage',
                    onClick: this.onClickBrokerageFeeFormat,
                },
            ],
        };

        const brokerageFeeLabel: JSX.Element = (
            <Dropdown menu={brokerageFeeMenu}>
                <a>Brokerage Fee <DownOutlined /></a>
            </Dropdown>
        );

        const hasErrors: boolean = _.some(errors, (error: string) => !!error);

        const establishmentFeeDollarsMinimumLimit: number = deal.establishmentFeeDollarsMinimumOverride || constants.DEAL_ESTABLISHMENT_FEE_DOLLARS_MINIMUM;
        const establishmentFeePercentageMinimumLimit: number = deal.establishmentFeePercentageMinimumOverride || constants.DEAL_ESTABLISHMENT_FEE_PERCENTAGE_MINIMUM;
        const interestRateMinimumLimit: number = deal.interestRateMinimumOverride || (deal.mortgageType === MortgageTypeEnum.SecondMortgage ? constants.DEAL_INTEREST_RATE_SECOND_MORTGAGE_MINIMUM : constants.DEAL_INTEREST_RATE_MINIMUM);
        const legalFeesDollarsMinimumLimit: number = deal.legalFeesDollarsMinimumOverride ?? constants.DEAL_LEGAL_FEES_DOLLARS_MINIMUM;
        const lvrMaximumLimit: number = deal.lvrMaximumOverride || constants.DEAL_LVR_MAXIMUM;

        return (
            <Layout dealUuid={match.params.dealUuid} section='calculator'>
                <Space className='quote'>
                    <Button className='scenario-quote' disabled={hasErrors || isQuoteSending || isQuoting} loading={isQuoting} onClick={this.onClickScenarioQuote}>Scenario</Button>
                    <Button className='scenario-quote-email' disabled={hasErrors || isQuoteSending || isQuoting} loading={isQuoteSending} onClick={this.onClickScenarioQuoteEmail}>Scenario + Email</Button>
                    <Button className='quote' disabled={hasErrors || isQuoteSending || isQuoting} loading={isQuoting} onClick={this.onClickQuote}>Quote</Button>
                    <Button className='quote-email' disabled={hasErrors || isQuoteSending || isQuoting} loading={isQuoteSending} onClick={this.onClickQuoteEmail} type='primary'>Quote + Email</Button>
                </Space>
                <Typography.Title level={2}>Calculator</Typography.Title>
                <Row>
                    <Col span={12}>
                        <Form.Item className='loan-amount-total' label='Requested Payout Amount'>
                            {currencyFormatter.format(deal.loanAmount)}
                        </Form.Item>
                        <Form.Item className='term-months' label='Term'>
                            {deal.termMonths} months
                        </Form.Item>
                        <Form.Item className='interest-rate' help={errors.interestRate} label='Interest Rate' validateStatus={errors.interestRate && 'error'}>
                            <Space>
                                <Input addonAfter='%' min={interestRateMinimumLimit} onBlur={this.validateInterestRate} onChange={this.onChangeInterestRate} step={0.1} type='number' value={interestRate} />
                                <span>({percentageFormatter.format(defaultInterestRate / 100)} default)</span>
                            </Space>
                        </Form.Item>
                        <Form.Item className='maximum-lvr' help={errors.maximumLvr} label='Maximum LVR' validateStatus={errors.maximumLvr && 'error'}>
                            <Input addonAfter='%' max={lvrMaximumLimit} onBlur={this.validateMaximumLvr} onChange={this.onChangeMaximumLvr} type='number' value={maximumLvr} />
                        </Form.Item>
                        <Form.Item className='commitment-fee' label='Commitment Fee'>
                            <Input addonBefore='$' onChange={this.onChangeCommitmentFee} type='number' value={commitmentFee} />
                        </Form.Item>
                        <Form.Item
                            className={establishmentFeeFormat === EstablishmentFeeFormatEnum.Dollars ? 'establishment-fee-dollars' : 'establishment-fee-percentage'}
                            help={errors.establishmentFee}
                            label={establishmentFeeLabel}
                            validateStatus={errors.establishmentFee && 'error'}
                        >
                            {establishmentFeeFormat === EstablishmentFeeFormatEnum.Dollars && <Space><Input addonBefore='$' min={establishmentFeeDollarsMinimumLimit} onBlur={this.validateEstablishmentFee} onChange={this.onChangeEstablishmentFeeDollars} type='number' value={establishmentFeeDollars} /><span>({percentageFormatter.format(establishmentFeeTotal / grossLoanAmount)})</span></Space>}
                            {establishmentFeeFormat === EstablishmentFeeFormatEnum.Percentage && <Space><Input addonAfter='%' min={establishmentFeePercentageMinimumLimit} onBlur={this.validateEstablishmentFee} onChange={this.onChangeEstablishmentFeePercentage} step={0.1} type='number' value={establishmentFeePercentage} /><span>({currencyFormatter.format(establishmentFeeTotal ? establishmentFeeTotal : Math.ceil(grossLoanAmount * (establishmentFeePercentage / 100)))})</span></Space>}
                        </Form.Item>
                        <Form.Item className={applicationFeeFormat === ApplicationFeeFormatEnum.Dollars ? 'application-fee-dollars' : 'application-fee-percentage'} label={applicationFeeLabel}>
                            {applicationFeeFormat === ApplicationFeeFormatEnum.Dollars && <Space><Input addonBefore='$' addonAfter='+ GST' onChange={this.onChangeApplicationFeeDollars} type='number' value={applicationFeeDollars} /><span>({percentageFormatter.format(applicationFeeTotal / grossLoanAmount)})</span></Space>}
                            {applicationFeeFormat === ApplicationFeeFormatEnum.Percentage && <Space><Input addonAfter='%' onChange={this.onChangeApplicationFeePercentage} step={0.1} type='number' value={applicationFeePercentage} /><span>({currencyFormatter.format(applicationFeeTotal)} + GST)</span></Space>}
                        </Form.Item>
                        <Form.Item className={brokerageFeeFormat === BrokerageFeeFormatEnum.Dollars ? 'brokerage-fee-dollars' : 'brokerage-fee-percentage'} label={brokerageFeeLabel}>
                            {brokerageFeeFormat === BrokerageFeeFormatEnum.Dollars && <Space><Input addonBefore='$' addonAfter='+ GST' onChange={this.onChangeBrokerageFeeDollars} type='number' value={brokerageFeeDollars} /><span>({percentageFormatter.format(brokerageFeeTotal / grossLoanAmount)})</span></Space>}
                            {brokerageFeeFormat === BrokerageFeeFormatEnum.Percentage && <Space><Input addonAfter='%' onChange={this.onChangeBrokerageFeePercentage} step={0.1} type='number' value={brokerageFeePercentage} /><span>({currencyFormatter.format(brokerageFeeTotal)} + GST)</span></Space>}
                        </Form.Item>
                        <Form.Item className='legal-fees' help={errors.legalFees} label='Legal Fees' validateStatus={errors.legalFees && 'error'}>
                            <Space><Input addonBefore='$' addonAfter='+ GST' min={legalFeesDollarsMinimumLimit} onBlur={this.validateLegalFees} onChange={this.onChangeLegalFees} type='number' value={legalFees}/></Space>
                        </Form.Item>
                        <Form.Item className='approximate-outlays' label='Approximate Outlays'>
                            <Input addonBefore='$' onChange={this.onChangeEstimatedOutlays} type='number' value={estimatedOutlays} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item className='mortgage-type' label='Mortgage Type'>
                            {mortgageTypeLabels[deal.mortgageType]}
                        </Form.Item>
                        <Form.Item className='gross-loan-amount' label='Gross Loan Amount'>
                            {currencyFormatter.format(grossLoanAmount)}
                        </Form.Item>
                        <Form.Item className='property-value' label='Property Value'>
                            {currencyFormatter.format(totalValue)}
                        </Form.Item>
                        <Form.Item className='current-debt' label='Current Debt'>
                            {currencyFormatter.format(totalCurrentDebt)}
                        </Form.Item>
                        <Form.Item className='lvr' label='LVR'>
                            {percentageFormatter.format(lvr / 100)}
                        </Form.Item>
                        <Form.Item className='interest-payable' label='Interest Payable'>
                            {currencyFormatter.format(interestPayable)}
                        </Form.Item>
                        <Form.Item className='monthly-interest-payments' label='Monthly Interest Payments'>
                            {currencyFormatter.format(monthlyInterestPaymentsNormal)}  ({currencyFormatter.format(monthlyInterestPaymentsDefault)} default)
                        </Form.Item>
                        <Form.Item className='gross-balance-on-settlement' label='Gross Balance on Settlement'>
                            {currencyFormatter.format(grossBalanceOnSettlement)}
                        </Form.Item>
                        <Form.Item className='net-prepaid-balance-on-settlement' label='Net Balance on Settlement'>
                            {currencyFormatter.format(netPrepaidBalanceOnSettlement)}
                        </Form.Item>
                        <Form.Item className='total-costs-dollars' label='Total Costs'>
                            {currencyFormatter.format(totalCostsDollars)}
                            {' '}({percentageFormatter.format(totalCostsPercentage / 100)}, {percentageFormatter.format(totalCostsPercentagePerAnnum / 100)} pa)
                        </Form.Item>
                    </Col>
                </Row>
            </Layout>
        );
    }

    private getCalculatedInterestRate(): number {
        const { deal, postcodeCategories } = this.props;

        if (this.state.dirtyFields.interestRate) {
            return this.state.interestRate;
        }

        return getDealCalculatedInterestRate({
            ...this.state,
            loanPurpose: deal.loanPurpose,
            mortgageType: deal.mortgageType,
            postcodeCategories,
            properties: deal.properties,
            requestedPayoutAmount: deal.loanAmount,
            termMonths: deal.termMonths,
        });
    }

    private getCalculatedEstablishmentFeePercentage(): number {
        if (this.state.dirtyFields.establishmentFeePercentage) {
            return this.state.establishmentFeePercentage;
        }

        return getDealCalculatedEstablishmentFeePercentage({
            ...this.state,
        });
    }

    private onClickApplicationFeeFormat(): void {
        const { applicationFeeFormat } = this.state;

        this.setState({
            applicationFeeFormat: applicationFeeFormat === ApplicationFeeFormatEnum.Dollars ? ApplicationFeeFormatEnum.Percentage : ApplicationFeeFormatEnum.Dollars,
        });
    }

    private onClickBrokerageFeeFormat(): void {
        const { brokerageFeeFormat } = this.state;

        this.setState({
            brokerageFeeFormat: brokerageFeeFormat === BrokerageFeeFormatEnum.Dollars ? BrokerageFeeFormatEnum.Percentage : BrokerageFeeFormatEnum.Dollars,
        });
    }

    private onClickEstablishmentFeeFormat(): void {
        const { establishmentFeeFormat } = this.state;

        this.setState({
            establishmentFeeFormat: establishmentFeeFormat === EstablishmentFeeFormatEnum.Dollars ? EstablishmentFeeFormatEnum.Percentage : EstablishmentFeeFormatEnum.Dollars,
        });
    }

    private createQuote(sendEmail: boolean, isScenario: boolean): void {
        const { deal } = this.props;
        const {
            applicationFeeFormat,
            applicationFeePercentage,
            brokerageFeeFormat,
            brokerageFeePercentage,
            calculatedAmounts,
            commitmentFee,
            establishmentFeeFormat,
            estimatedOutlays,
            legalFees,
            maximumLvr,
        } = this.state;

        const interestRate: number = this.getCalculatedInterestRate();
        const establishmentFeePercentage: number = this.getCalculatedEstablishmentFeePercentage();

        let valid: boolean = true;

        valid = this.validateEstablishmentFee() && valid;
        valid = this.validateLegalFees() && valid;
        valid = this.validateMaximumLvr() && valid;
        valid = this.validateInterestRate() && valid;

        if (!valid) {
            return;
        }

        this.setState({
            isQuoteSending: sendEmail,
            isQuoting: !sendEmail,
        });

        const {
            applicationFeeTotal,
            brokerageFeeTotal,
            establishmentFeeTotal,
            grossLoanAmount,
            lvr,
            netPrepaidBalanceOnSettlement,
            totalCurrentDebt,
            totalValue,
        } = calculatedAmounts;

        const quote: IQuote = {
            applicationFee: applicationFeeTotal,
            applicationFeeFormat,
            applicationFeePercentage: applicationFeeFormat === ApplicationFeeFormatEnum.Percentage ? applicationFeePercentage : null,
            brokerageFee: brokerageFeeTotal,
            brokerageFeeFormat,
            brokerageFeePercentage: brokerageFeeFormat === BrokerageFeeFormatEnum.Percentage ? brokerageFeePercentage : null,
            commitmentFee,
            currentDebt: totalCurrentDebt,
            dealUuid: deal.uuid,
            establishmentFee: establishmentFeeTotal,
            establishmentFeeFormat,
            establishmentFeePercentage: establishmentFeeFormat === EstablishmentFeeFormatEnum.Percentage ? establishmentFeePercentage : null,
            estimatedOutlays,
            interestRate,
            legalFees,
            loanAmount: grossLoanAmount,
            lvr,
            maximumLvr,
            mortgageType: deal.mortgageType,
            netBalance: netPrepaidBalanceOnSettlement,
            paymentType: PaymentTypeEnum.PrePay,
            propertyValue: totalValue,
            termMonths: deal.termMonths,
        };

        this.props.quoteAdd(quote, sendEmail, isScenario);
    }

    private onClickQuote(): void {
        this.createQuote(false, false);
    }

    private onClickQuoteEmail(): void {
        Modal.confirm({
            content: 'Are you sure you want to send this quote?',
            okText: 'Send',
            onOk: () => {
                this.createQuote(true, false);
            },
            title: 'Send Quote',
        });
    }

    private onClickScenarioQuote(): void {
        this.createQuote(false, true);
    }

    private onClickScenarioQuoteEmail(): void {
        this.createQuote(true, true);
    }

    private onChangeApplicationFeeDollars(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            applicationFeeDollars: event.target.value ? Number(event.target.value) : null,
        });
    }

    private onChangeApplicationFeePercentage(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            applicationFeePercentage: event.target.value ? Number(event.target.value) : null,
        });
    }

    private onChangeBrokerageFeeDollars(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            brokerageFeeDollars: event.target.value ? Number(event.target.value) : null,
        });
    }

    private onChangeBrokerageFeePercentage(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            brokerageFeePercentage: event.target.value ? Number(event.target.value) : null,
        });
    }

    private onChangeCommitmentFee(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            commitmentFee: event.target.value ? Number(event.target.value) : null,
        });
    }

    private onChangeEstablishmentFeeDollars(event: React.ChangeEvent<HTMLInputElement>): void {
        const establishmentFeeDollars: number = Number(event.target.value);

        this.setState({
            establishmentFeeDollars: event.target.value ? establishmentFeeDollars : null,
        });
    }

    private onChangeEstablishmentFeePercentage(event: React.ChangeEvent<HTMLInputElement>): void {
        const establishmentFeePercentage: number = Number(event.target.value);

        this.setState({
            dirtyFields: {
                ...this.state.dirtyFields,
                establishmentFeePercentage: true,
            },
            establishmentFeePercentage: event.target.value ? establishmentFeePercentage : null,
        });
    }

    private onChangeEstimatedOutlays(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            estimatedOutlays: event.target.value ? Number(event.target.value) : null,
        });
    }

    private onChangeInterestRate(event: React.ChangeEvent<HTMLInputElement>): void {
        const interestRate: number = Number(event.target.value);

        this.setState({
            dirtyFields: {
                ...this.state.dirtyFields,
                interestRate: true,
            },
            interestRate: event.target.value ? interestRate : null,
        });
    }

    private onChangeLegalFees(event: React.ChangeEvent<HTMLInputElement>): void {
        const legalFees: number = Number(event.target.value);

        this.setState({
            legalFees: event.target.value ? legalFees : null,
        });
    }

    private onChangeMaximumLvr(event: React.ChangeEvent<HTMLInputElement>): void {
        const maximumLvr: number = Number(event.target.value);

        this.setState({
            maximumLvr: event.target.value ? maximumLvr : null,
        });
    }

    private validateEstablishmentFee(): boolean {
        const { deal } = this.props;
        const { calculatedAmounts } = this.state;

        const {
            establishmentFeeTotal,
            grossLoanAmount,
        } = calculatedAmounts;

        return validateDealEstablishmentFee({
            ...this.state,
            establishmentFeeDollarsMinimumOverride: deal.establishmentFeeDollarsMinimumOverride,
            establishmentFeePercentageMinimumOverride: deal.establishmentFeePercentageMinimumOverride,
            establishmentFeeTotal,
            grossLoanAmount,
            isBroker: (deal.isBroker || !!deal.brokerUuid),
        }, this.setError);
    }

    private validateInterestRate(): boolean {
        const { deal } = this.props;

        const interestRate: number = this.getCalculatedInterestRate();

        return validateDealInterestRate({
            ...this.state,
            interestRate,
            interestRateMinimumOverride: deal.interestRateMinimumOverride,
            mortgageType: deal.mortgageType,
        }, this.setError);
    }

    private validateLegalFees(): boolean {
        const { deal } = this.props;

        return validateDealLegalFees({
            ...this.state,
            legalFeesDollarsMinimumOverride: deal.legalFeesDollarsMinimumOverride,
        }, this.setError);
    }

    private validateMaximumLvr(): boolean {
        const { deal } = this.props;
        const { calculatedAmounts } = this.state;

        const {
            netPrepaidBalanceOnSettlement,
        } = calculatedAmounts;

        return validateDealMaximumLvr({
            ...this.state,
            lvrMaximumOverride: deal.lvrMaximumOverride,
            netPrepaidBalanceOnSettlement,
        }, this.setError);
    }

    private onChangeMortgageType(value: MortgageTypeEnum): void {
        this.setState({
            mortgageType: value,
        });
    }

    private onChangeTermMonths(value: number): void {
        this.setState({
            termMonths: value,
        });
    }

    private onClickMissingDetailsCancel(): void {
        this.setState({
            isMissingDetailsModalOpen: false,
        });
    }

    private onClickMissingDetailsOk(): void {
        const { deal } = this.props;
        const { mortgageType, termMonths } = this.state;

        if (!deal.mortgageType) {
            this.props.leadValueSet('mortgageType', mortgageType);
        }
        if (!deal.termMonths) {
            this.props.leadValueSet('termMonths', termMonths);
        }

        this.onClickMissingDetailsCancel();
    }

    private setError(key: keyof IState['errors'], value: string): void {
        this.setState((previousState: IState) => ({
            errors: {
                ...previousState.errors,
                [key]: value,
            },
        }));
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        deal: leadSelector(state, ownProps.match.params.dealUuid),
        postcodeCategories: dealPropertiesPostcodeCategoriesSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        leadGet: () => dispatch(leadGetAction(ownProps.match.params.dealUuid)),
        leadValueSet: (key: keyof IDeal, value: boolean|number|string) => dispatch(leadValueSetAction(ownProps.match.params.dealUuid, key, value)),
        postcodeCategoriesList: () => dispatch(dealPropertiesPostcodeCategoriesListAction()),
        quoteAdd: (quote: IQuote, sendEmail: boolean, isScenario: boolean) => dispatch(leadQuotesAddAction(ownProps.match.params.dealUuid, quote, sendEmail, isScenario)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Overview);
