import { WarningOutlined } from '@ant-design/icons';
import { Alert, Button, Dropdown, MenuProps, Modal, Space, Spin, Table, Tag, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link, match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import ILoan from '~Api/Loan/ILoan';
import ILoanPayoutFigure from '~Api/Loan/ILoanPayoutFigure';
import LoanPayoutFigureApprovalStatusEnum from '~Api/Loan/LoanPayoutFigureApprovalStatusEnum';
import LoanStatusEnum from '~Api/Loan/LoanStatusEnum';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import {
    loanGetAction,
    loanPayoutFigureApproveAction,
    loanPayoutFigureCloneAction,
    loanPayoutFigureDeleteAction,
    loanPayoutFigureProcessAction,
    loanPayoutFiguresListAction,
} from '~Loans/actions';
import {
    loanPayoutFiguresSelector,
    loanSelector,
} from '~Loans/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import CreateExtensionApplicationModal from './CreateExtensionApplicationModal';
import Layout from './Layout';
import { currencyFormatter } from '~utilities/formatters';
import { ColumnType } from 'antd/lib/table';
import { applicationAssignPayoutFigureAction } from '~Applications/actions';
import WorkflowStatusEnum from '~Api/Application/WorkflowStatusEnum';

interface IState {
    createExtensionApplicationModalIsOpen: boolean;
    extensionApplicationPayoutFigureUuid: string;
}

interface IMatch {
    uuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    currentUser: IAuthUser;
    loan: ILoan;
    payoutFigures: IDictionary<ILoanPayoutFigure>;
}

interface IPropsDispatch {
    approve: (payoutFigureUuid: string) => void;
    assignExtensionApplicationPayoutFigure: (extensionApplicationUuid: string, payoutFigureUuid: string) => void;
    clone: (payoutFigureUuid: string) => void;
    delete: (payoutFigureUuid: string) => void;
    loanGet: () => void;
    payoutFiguresList: () => void;
    processPayout: (payoutFigureUuid: string, force: boolean) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class PayoutFigures extends React.Component<Props, IState> {
    public state: IState = {
        createExtensionApplicationModalIsOpen: false,
        extensionApplicationPayoutFigureUuid: null,
    };

    constructor(props: Props) {
        super(props);

        this.onClickAssignExtensionApplicationPayoutFigure = this.onClickAssignExtensionApplicationPayoutFigure.bind(this);
        this.onClickProcessPayout = this.onClickProcessPayout.bind(this);
        this.onClickApprove = this.onClickApprove.bind(this);
        this.onClickClone = this.onClickClone.bind(this);
        this.onClickCreateExtensionApplication = this.onClickCreateExtensionApplication.bind(this);
        this.onCloseCreateExtensionApplicationModal = this.onCloseCreateExtensionApplicationModal.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
    }

    public componentDidMount(): void {
        this.props.loanGet();
        this.props.payoutFiguresList();
    }

    public render(): JSX.Element {
        const { currentUser, loan, match, payoutFigures } = this.props;
        const { createExtensionApplicationModalIsOpen, extensionApplicationPayoutFigureUuid } = this.state;

        if (!loan || !payoutFigures) {
            return (
                <Layout uuid={match.params.uuid} section='payout-figures'>
                    <Typography.Title level={2}>Payout Figures</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const columns: ColumnType<ILoanPayoutFigure>[] = [
            {
                dataIndex: 'code',
                title: 'Code',
                width: '15%',
            },
            {
                dataIndex: 'dischargeDate',
                render: (dischargeDate: string) => dayjs(dischargeDate).format('DD/MM/YYYY'),
                title: 'Proposed Payout Date',
                width: '20%',
            },
            {
                render: (payoutFigure: ILoanPayoutFigure) => {
                    let payoutFigureWarning: string = null;
                    const isDraft: boolean = payoutFigure.approvalStatus === LoanPayoutFigureApprovalStatusEnum.Draft;

                    if (!isDraft && !!loan.paymentDateNext && dayjs(loan.paymentDateNext) < dayjs(payoutFigure.dischargeDate)) {
                        payoutFigureWarning = 'All due payments have not been made for this loan';
                    } else if (!isDraft && loan.status !== LoanStatusEnum.ClosedObligationsMet && Math.ceil(loan.amountRemaining) !== Math.ceil(payoutFigure.principalAmount)) {
                        payoutFigureWarning = 'Amount remaining for loan does not equal payout figure principal amount';
                    }

                    const payoutFigureBalanceAmount: string = currencyFormatter.format(payoutFigure.balanceAmount);

                    const extensionApplicationTagBlock: JSX.Element = loan.extensionApplication && loan.extensionApplication.payoutFigureUuid && loan.extensionApplication.payoutFigureUuid === payoutFigure.uuid && (
                        <Tag>Extension</Tag>
                    );

                    if (payoutFigureWarning) {
                        return (
                            <Tooltip overlay={payoutFigureWarning}>
                                <Space>
                                    {payoutFigureBalanceAmount}
                                    {extensionApplicationTagBlock}
                                    <WarningOutlined />
                                </Space>
                            </Tooltip>
                        );
                    }

                    return (
                        <Space>
                            {payoutFigureBalanceAmount}
                            {extensionApplicationTagBlock}
                        </Space>
                    );
                },
                title: 'Amount',
            },
            {
                dataIndex: 'createdTime',
                defaultSortOrder: 'descend',
                render: (createdTime: string) => dayjs(createdTime).format('DD/MM/YYYY HH:mm'),
                sorter: (a: ILoanPayoutFigure, b: ILoanPayoutFigure) => dayjs(a.createdTime) > dayjs(b.createdTime) ? 1 : -1,
                title: 'Created',
                width: '20%',
            },
            {
                render: (payoutFigure: ILoanPayoutFigure) => {
                    const isDraft: boolean = payoutFigure.approvalStatus === LoanPayoutFigureApprovalStatusEnum.Draft;
                    const showEditAction: boolean = isDraft && loan.status !== LoanStatusEnum.ClosedObligationsMet;
                    const showCloneAction: boolean = loan.status !== LoanStatusEnum.ClosedObligationsMet;
                    const showApproveAction: boolean = isDraft && currentUser.permissions.includes(PermissionsEnum.LoanPayoutFigureApprove);
                    const showAssignExtensionApplicationPayoutFigure: boolean = loan.extensionApplication && loan.extensionApplication.workflowStatus !== WorkflowStatusEnum.Warehoused && loan.extensionApplication.payoutFigureUuid && loan.extensionApplication.payoutFigureUuid !== payoutFigure.uuid;
                    const showProcessAction: boolean = (
                        currentUser.permissions.includes(PermissionsEnum.LoanPayoutFigureProcess)
                        && !isDraft
                        && loan.status !== LoanStatusEnum.ClosedObligationsMet
                        && (
                            Math.ceil(loan.amountRemaining) === Math.ceil(payoutFigure.principalAmount)
                            || currentUser.permissions.includes(PermissionsEnum.LoanPayoutFigureForceProcess)
                        )
                        && (!loan.paymentDateNext || dayjs(loan.paymentDateNext) > dayjs(payoutFigure.dischargeDate))
                    );
                    const showCreateExtensionAction: boolean = !loan.extensionApplicationUuid && currentUser.permissions.includes(PermissionsEnum.LoanExtend);
                    const showDeleteAction: boolean = !loan.extensionApplication || (loan.extensionApplication && loan.extensionApplication.payoutFigureUuid && loan.extensionApplication.payoutFigureUuid !== payoutFigure.uuid) && loan.status !== LoanStatusEnum.ClosedObligationsMet;

                    if (!showEditAction && !showApproveAction && !showProcessAction && !showCreateExtensionAction && !showDeleteAction) {
                        return (
                            <Button type='primary' href={`${process.env.API_HOST}/loans/${loan.uuid}/payout-figures/${payoutFigure.uuid}/pdf`} target='_blank' rel='noreferrer'>View PDF</Button>
                        );
                    }

                    const payoutFigureActionsMenu: MenuProps = {
                        items: [
                            ...(showEditAction ? [
                                {
                                    key: 'edit',
                                    label: <Link to={`/loans/${loan.uuid}/payout-figures/${payoutFigure.uuid}/edit`}>Edit</Link>,
                                },
                            ] : []),
                            ...(showCloneAction ? [
                                {
                                    key: 'clone',
                                    label: 'Clone',
                                    onClick: () => this.onClickClone(payoutFigure.uuid),
                                },
                            ] : []),
                            ...(showAssignExtensionApplicationPayoutFigure ? [
                                {
                                    key: 'assign',
                                    label: 'Use for Extension Application',
                                    onClick: () => this.onClickAssignExtensionApplicationPayoutFigure(loan.extensionApplication.uuid, payoutFigure.uuid),
                                },
                            ] : []),
                            ...(showApproveAction ? [
                                {
                                    key: 'approve',
                                    label: 'Approve',
                                    onClick: () => this.onClickApprove(payoutFigure.uuid),
                                },
                            ] : []),
                            ...(showProcessAction ? [
                                {
                                    danger: Math.ceil(loan.amountRemaining) !== Math.ceil(payoutFigure.principalAmount),
                                    key: 'process',
                                    label: 'Process',
                                    onClick: () => this.onClickProcessPayout(payoutFigure.uuid),
                                },
                            ] : []),
                            ...(showCreateExtensionAction ? [
                                {
                                    key: 'create-extension',
                                    label: 'Create Extension',
                                    onClick: () => this.onClickCreateExtensionApplication(payoutFigure.uuid),
                                },
                            ] : []),
                            ...(showDeleteAction ? [
                                {
                                    danger: true,
                                    key: 'delete',
                                    label: 'Delete',
                                    onClick: () => this.onClickDelete(payoutFigure.uuid),
                                },
                            ] : []),
                        ],
                    };

                    const createExtensionApplicationModalBlock: JSX.Element = payoutFigure.uuid === extensionApplicationPayoutFigureUuid && (
                        <CreateExtensionApplicationModal
                            isOpen={createExtensionApplicationModalIsOpen}
                            onClose={this.onCloseCreateExtensionApplicationModal}
                            loanPayoutFigureUuid={payoutFigure.uuid}
                        />
                    );

                    return (
                        <Space>
                            <Dropdown.Button menu={payoutFigureActionsMenu} type='primary'>
                                <a href={`${process.env.API_HOST}/loans/${loan.uuid}/payout-figures/${payoutFigure.uuid}/pdf`} target='_blank' rel='noreferrer'>View PDF</a>
                            </Dropdown.Button>
                            {createExtensionApplicationModalBlock}
                        </Space>
                    );
                },
                width: '15%',
            },
        ];

        return (
            <Layout uuid={match.params.uuid} section='payout-figures'>
                <Space className='actions'>
                    <Link to={`/loans/${loan.uuid}/payout-figures/add`}><Button className='create'>Create Payout Figure</Button></Link>
                </Space>
                <Typography.Title level={2}>Payout Figures</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={_.values(payoutFigures)}
                    rowKey='uuid'
                    pagination={false}
                    size='middle'
                />
            </Layout>
        );
    }

    private onClickAssignExtensionApplicationPayoutFigure(applicationUuid:string, payoutFigureUuid: string): void {
        Modal.confirm({
            cancelText: 'No',
            content: 'Are you sure you want to use this payout figure for the extension application?',
            okText: 'Yes',
            okType: 'primary',
            onOk: () => {
                this.props.assignExtensionApplicationPayoutFigure(applicationUuid, payoutFigureUuid);
            },
            title: 'Use Payout Figure for Extension Application',
        });
    }

    private onClickProcessPayout(payoutFigureUuid: string): void {
        const { loan, payoutFigures } = this.props;

        const payoutFigure: ILoanPayoutFigure = payoutFigures[payoutFigureUuid];

        let payoutFigureWarning: string = null;
        if (!!loan.paymentDateNext && dayjs(loan.paymentDateNext) < dayjs(payoutFigure.dischargeDate)) {
            payoutFigureWarning = 'All due payments have not been made for this loan';
        } else if (Math.ceil(loan.amountRemaining) !== Math.ceil(payoutFigure.principalAmount)) {
            payoutFigureWarning = 'Amount remaining for loan does not equal payout figure principal amount';
        }

        Modal.confirm({
            content: (
                <Space direction='vertical'>
                    {payoutFigureWarning && <Alert message={payoutFigureWarning} type='error' />}
                    <span>Are you sure you want to process the payout figure of {currencyFormatter.format(payoutFigure.balanceAmount)}?</span>
                </Space>
            ),
            okText: 'Process',
            okType: payoutFigureWarning ? 'danger' : 'primary',
            onOk: () => {
                this.props.processPayout(payoutFigureUuid, !!payoutFigureWarning);
            },
            title: 'Process Payout',
        });
    }

    private onClickApprove(payoutFigureUuid: string): void {
        const { payoutFigures } = this.props;

        Modal.confirm({
            content: `Are you sure you want to approve this draft payout figure of ${currencyFormatter.format(payoutFigures[payoutFigureUuid].balanceAmount)}?`,
            okText: 'Approve',
            onOk: () => {
                this.props.approve(payoutFigureUuid);
            },
            title: 'Approve Payout Figure',
        });
    }

    private onClickClone(payoutFigureUuid: string): void {
        Modal.confirm({
            content: 'Clone this payout figure?',
            okText: 'Clone',
            onOk: () => {
                this.props.clone(payoutFigureUuid);
            },
            title: 'Clone Payout Figure',
        });
    }

    private onClickCreateExtensionApplication(payoutFigureUuid: string): void {
        this.setState({
            createExtensionApplicationModalIsOpen: true,
            extensionApplicationPayoutFigureUuid: payoutFigureUuid,
        });
    }

    private onCloseCreateExtensionApplicationModal(): void {
        this.setState({
            createExtensionApplicationModalIsOpen: false,
        });
    }

    private onClickDelete(payoutFigureUuid: string): void {
        const { payoutFigures } = this.props;

        Modal.confirm({
            content: `Are you sure you want to delete this payout figure of ${currencyFormatter.format(payoutFigures[payoutFigureUuid].balanceAmount)}?`,
            okText: 'Delete',
            onOk: () => {
                this.props.delete(payoutFigureUuid);
            },
            title: 'Delete Payout Figure',
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        currentUser: authCurrentUserSelector(state),
        loan: loanSelector(state, ownProps.match.params.uuid),
        payoutFigures: loanPayoutFiguresSelector(state, ownProps.match.params.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        approve: (payoutFigureUuid: string) => dispatch(loanPayoutFigureApproveAction(payoutFigureUuid)),
        assignExtensionApplicationPayoutFigure: (extensionApplicationUuid:string, payoutFigureUuid: string) => dispatch(applicationAssignPayoutFigureAction(extensionApplicationUuid, payoutFigureUuid)),
        clone: (payoutFigureUuid: string) => dispatch(loanPayoutFigureCloneAction(payoutFigureUuid)),
        delete: (payoutFigureUuid: string) => dispatch(loanPayoutFigureDeleteAction(payoutFigureUuid)),
        loanGet: () => dispatch(loanGetAction(ownProps.match.params.uuid)),
        payoutFiguresList: () => dispatch(loanPayoutFiguresListAction(ownProps.match.params.uuid)),
        processPayout: (payoutFigureUuid: string, force: boolean) => dispatch(loanPayoutFigureProcessAction(payoutFigureUuid, force)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PayoutFigures);
