import { Button, Typography } from 'antd';
import React, { ReactElement } from 'react';
import Layout from './Layout';

export default function WarehousesFwt2PoolCut(): ReactElement {
    return (
        <Layout section='warehouse-fwt2-pool-cut'>
            <Typography.Title level={2}>Warehouses FWT2 Pool Cut Report</Typography.Title>
            <Button
                href={`${process.env.API_HOST}/warehouse-reports/download-fwt2-pool-cut-report`}
                target='_blank'
                type='primary'
            >
                Download
            </Button>
        </Layout>
    );
}
