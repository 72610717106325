import { FilterFilled, FilterTwoTone } from '@ant-design/icons';
import { Breadcrumb, Button, Card, Form, Layout, Modal, Space, Spin, Tree, Typography } from 'antd';
import _ from 'lodash';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { administratorsListAction } from '~Administrators/actions';
import {
    administratorsSelector,
    currentAdministratorSelector,
} from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import RoleEnum from '~Api/Administrator/RoleEnum';
import ApprovalStatusEnum from '~Api/Deal/ApprovalStatusEnum';
import IDeal from '~Api/Deal/IDeal';
import { IDictionary } from '~utilities/IDictionary';
import ITreeStructureGroup from '~utilities/ITreeStructureGroup';
import DealCard from './Lead/Card';
import LeadAddModal from './LeadAddModal';
import './leads.less';
import Search from './Search';
import CheckedKeys from '~utilities/CheckedKeys';
import { currencyFormatter } from '~utilities/formatters';
import { leadsBoardListAction } from './actions';
import { leadsBoardSelector } from './selectors';
import ModalStateEnum from '~UI/ModalStateEnum';

enum DashboardColumnTypeEnum {
    Draft = 'DRAFT',
    Pending = 'PENDING',
    InitialCall = 'INITIAL_CALL',
    Uncontactable = 'UNCONTACTABLE',
    Quote = 'QUOTE',
    FollowUp = 'FOLLOW_UP',
}

enum AssigneeTreeGroupTypeEnum {
    Bdms = 'BDMS',
    CreditTeam = 'CREDIT_TEAM',
    Unassigned = 'UNASSIGNED',
}

enum ChannelTreeGroupTypeEnum {
    Broker = 'BROKER',
    Direct = 'DIRECT',
}

const unassignedBdmKey: string = 'UNASSIGNED';

const statusNames: IDictionary<string> = {
    [DashboardColumnTypeEnum.Draft]: 'Draft',
    [DashboardColumnTypeEnum.Pending]: 'New',
    [DashboardColumnTypeEnum.InitialCall]: 'Initial Call',
    [DashboardColumnTypeEnum.Uncontactable]: 'Uncontactable',
    [DashboardColumnTypeEnum.Quote]: 'Quoted',
    [DashboardColumnTypeEnum.FollowUp]: 'Follow Up',
};

const approvalStatusMap: IDictionary<DashboardColumnTypeEnum> = {
    [ApprovalStatusEnum.Draft]: DashboardColumnTypeEnum.Draft,
    [ApprovalStatusEnum.Pending]: DashboardColumnTypeEnum.Pending,
    [ApprovalStatusEnum.InitialCall]: DashboardColumnTypeEnum.InitialCall,
    [ApprovalStatusEnum.UncontactableFollowUp1]: DashboardColumnTypeEnum.Uncontactable,
    [ApprovalStatusEnum.UncontactableFollowUp2]: DashboardColumnTypeEnum.Uncontactable,
    [ApprovalStatusEnum.UncontactableFollowUp3]: DashboardColumnTypeEnum.Uncontactable,
    [ApprovalStatusEnum.Quote]: DashboardColumnTypeEnum.Quote,
    [ApprovalStatusEnum.FollowUp1]: DashboardColumnTypeEnum.FollowUp,
    [ApprovalStatusEnum.FollowUp2]: DashboardColumnTypeEnum.FollowUp,
    [ApprovalStatusEnum.FollowUp3]: DashboardColumnTypeEnum.FollowUp,
};

export default function Dashboard(): ReactElement {
    const administrators: IDictionary<IAdministrator> = useSelector(administratorsSelector);
    const currentAdministrator: IAdministrator = useSelector(currentAdministratorSelector);
    const deals: IDictionary<IDeal> = useSelector(leadsBoardSelector);

    const [ assigneeFilter, setAssigneeFilter ] = useState<string[]>([]);
    const [ bdmFilter, setBdmFilter ] = useState<string[]>([]);
    const [ channelFilter, setChannelFilter ] = useState<string[]>([]);
    const [ isAddModalOpen, setIsAddModalOpen ] = useState<ModalStateEnum>(ModalStateEnum.Off);
    const [ isChangeFiltersModalOpen, setIsChangeFiltersModalOpen ] = useState<boolean>(false);

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        dispatch(leadsBoardListAction());

        const refreshInterval: NodeJS.Timeout = setInterval(() => {
            dispatch(leadsBoardListAction());
        }, 5 * 60 * 1000);

        return () => {
            clearInterval(refreshInterval);
        };
    }, [
        dispatch,
    ]);

    useEffect(() => {
        if (!administrators) {
            dispatch(administratorsListAction());
        }
    }, [
        administrators,
        dispatch,
    ]);

    const onClickChangeFilters: () => void = useCallback(() => {
        setIsChangeFiltersModalOpen(true);
    }, []);

    const onCancelChangeFilters: () => void = useCallback(() => {
        setIsChangeFiltersModalOpen(false);
    }, []);

    const onChangeAssigneeFilter: (checkedKeys: CheckedKeys) => void = useCallback((checkedKeys: CheckedKeys) => {
        setAssigneeFilter(checkedKeys as string[]);
    }, []);

    const onChangeBdmFilter: (checkedKeys: CheckedKeys) => void = useCallback((checkedKeys: CheckedKeys) => {
        setBdmFilter(checkedKeys as string[]);
    }, []);

    const onChangeChannelFilter: (checkedKeys: CheckedKeys) => void = useCallback((checkedKeys: CheckedKeys) => {
        setChannelFilter(checkedKeys as string[]);
    }, []);

    const onClickAddLead: () => void = useCallback(() => {
        setIsAddModalOpen(ModalStateEnum.Open);
    }, []);

    const onCancelAddLead: () => void = useCallback(() => {
        setIsAddModalOpen(ModalStateEnum.Closed);
    }, []);

    const filtered: boolean = assigneeFilter.length > 0 || bdmFilter.length > 0 || channelFilter.length > 0;

    const filterButton: JSX.Element = ![
        RoleEnum.BusinessDevelopmentManager,
        RoleEnum.InternalBusinessDevelopmentManager,
        RoleEnum.CreditManager,
    ].includes(currentAdministrator.role) && (
        <Button disabled={!deals} onClick={onClickChangeFilters} icon={filtered ? <FilterTwoTone/> : <FilterFilled/>} />
    );

    const addModalBlock: JSX.Element = ModalStateEnum.Off !== isAddModalOpen && (
        <LeadAddModal
            isOpen={ModalStateEnum.Open === isAddModalOpen}
            onCancel={onCancelAddLead}
        />
    );

    if (!administrators || !currentAdministrator || !deals) {
        return (
            <Layout className='leads'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Leads</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Space className='actions'>
                            <Search/>
                            {filterButton}
                            <Link to='/leads/list'><Button>List View</Button></Link>
                            <Button className='add-lead' onClick={onClickAddLead} type='primary'>Add Lead</Button>
                        </Space>
                        <Typography.Title level={2}>Leads</Typography.Title>
                        <Spin/>
                    </Layout.Content>
                </Layout>

                {addModalBlock}
            </Layout>
        );
    }

    const columns: IDictionary<IDeal[]> = {};
    const statusAmounts: IDictionary<number> = {};

    const visibleAssignees: IDictionary<IAdministrator> = {};
    const visibleBdms: IDictionary<IAdministrator> = {};

    _.sortBy(deals, ['followUpTime']).forEach((deal: IDeal) => {
        if (deal.closedTime) {
            return;
        }

        const approvalStatus: DashboardColumnTypeEnum = approvalStatusMap[deal.approvalStatus];

        if (!approvalStatus) {
            return;
        }

        if ([
            RoleEnum.BusinessDevelopmentManager,
            RoleEnum.CreditManager,
            RoleEnum.InternalBusinessDevelopmentManager,
        ].includes(currentAdministrator.role) && deal.administratorUuid !== currentAdministrator.uuid) {
            return;
        }

        const assignee: IAdministrator = administrators[deal.administratorUuid];
        const bdm: IAdministrator = administrators[deal.bdmUuid];

        if (deal.administratorUuid && !visibleAssignees[assignee.uuid]) {
            visibleAssignees[assignee.uuid] = assignee;
        }

        if (deal.bdmUuid && !visibleBdms[bdm.uuid]) {
            visibleBdms[bdm.uuid] = bdm;
        }

        if (assigneeFilter.length > 0) {
            let shouldShow: boolean = false;
            if (deal.administratorUuid) {
                if (assigneeFilter.includes(AssigneeTreeGroupTypeEnum.Bdms) && [
                    RoleEnum.BusinessDevelopmentManager,
                    RoleEnum.InternalBusinessDevelopmentManager,
                    RoleEnum.SeniorBusinessDevelopmentManager,
                ].includes(assignee.role)) {
                    shouldShow = true;
                } else if (assigneeFilter.includes(AssigneeTreeGroupTypeEnum.CreditTeam) && [
                    RoleEnum.CreditManager,
                    RoleEnum.SeniorCreditManager,
                ].includes(assignee.role)) {
                    shouldShow = true;
                } else if (assigneeFilter.includes(assignee.uuid)) {
                    shouldShow = true;
                }
            } else if (assigneeFilter.includes(AssigneeTreeGroupTypeEnum.Unassigned)) {
                shouldShow = true;
            }
            if (!shouldShow) {
                return;
            }
        }

        if (bdmFilter.length > 0) {
            let shouldShow: boolean = false;
            if (!deal.bdmUuid && bdmFilter.includes(unassignedBdmKey)) {
                shouldShow = true;
            } else if (deal.bdmUuid && bdmFilter.includes(bdm.uuid)) {
                shouldShow = true;
            }
            if (!shouldShow) {
                return;
            }
        }

        if (channelFilter.length > 0) {
            let shouldShow: boolean = false;
            if (channelFilter.includes(ChannelTreeGroupTypeEnum.Broker) && (deal.brokerUuid || deal.isBroker)) {
                shouldShow = true;
            }
            if (channelFilter.includes(ChannelTreeGroupTypeEnum.Direct) && !deal.brokerUuid && !deal.isBroker) {
                shouldShow = true;
            }
            if (!shouldShow) {
                return;
            }
        }

        if (!columns[approvalStatus]) {
            columns[approvalStatus] = [];
        }

        columns[approvalStatus].push(deal);

        if (!statusAmounts[approvalStatus]) {
            statusAmounts[approvalStatus] = 0;
        }

        statusAmounts[approvalStatus] += deal.baseLoanAmount;
    });

    const columnsBlock: JSX.Element[] = _.keys(statusNames).map((status: string) => {
        const amountBlock: JSX.Element = (
            <div className='amount'>
                {columns[status] ? columns[status].length : 0} - {currencyFormatter.format(statusAmounts[status] || 0)}
            </div>
        );

        return (
            <Card key={status} title={<>{statusNames[status]}{amountBlock}</>}>
                <Space direction='vertical'>
                    {columns[status] && columns[status].map((deal: IDeal) => <DealCard deal={deal} key={deal.uuid} />)}
                </Space>
            </Card>
        );
    });

    const assigneeBdmsGroup: ITreeStructureGroup = {
        children: [],
        key: AssigneeTreeGroupTypeEnum.Bdms,
        title: 'BDMs',
        value: AssigneeTreeGroupTypeEnum.Bdms,
    };

    const assigneeCreditTeamGroup: ITreeStructureGroup = {
        children: [],
        key: AssigneeTreeGroupTypeEnum.CreditTeam,
        title: 'Credit Team',
        value: AssigneeTreeGroupTypeEnum.CreditTeam,
    };

    _.forEach(_.sortBy(visibleAssignees, ['name']), (loopAdmin: IAdministrator) => {
        switch (loopAdmin.role) {
            case RoleEnum.BusinessDevelopmentManager:
            case RoleEnum.InternalBusinessDevelopmentManager:
            case RoleEnum.SeniorBusinessDevelopmentManager:
                assigneeBdmsGroup.children.push({
                    key: loopAdmin.uuid,
                    title: loopAdmin.name,
                    value: loopAdmin.uuid,
                });
                break;
            case RoleEnum.CreditManager:
            case RoleEnum.SeniorCreditManager:
                assigneeCreditTeamGroup.children.push({
                    key: loopAdmin.uuid,
                    title: loopAdmin.name,
                    value: loopAdmin.uuid,
                });
                break;
        }
    });

    const assigneeTreeData: ITreeStructureGroup[] = [
        {
            children: [],
            key: AssigneeTreeGroupTypeEnum.Unassigned,
            title: 'Unassigned',
            value: AssigneeTreeGroupTypeEnum.Unassigned,
        },
        assigneeBdmsGroup,
        assigneeCreditTeamGroup,
    ];

    const bdmTreeData: ITreeStructureGroup[] = [
        {
            children: [],
            key: unassignedBdmKey,
            title: 'Unassigned',
            value: unassignedBdmKey,
        },
    ];

    _.forEach(_.sortBy(visibleBdms, ['name']), (loopAdmin: IAdministrator) => {
        if ([
            RoleEnum.BusinessDevelopmentManager,
            RoleEnum.InternalBusinessDevelopmentManager,
            RoleEnum.SeniorBusinessDevelopmentManager,
        ].includes(loopAdmin.role)) {
            bdmTreeData.push({
                children: [],
                key: loopAdmin.uuid,
                title: loopAdmin.name,
                value: loopAdmin.uuid,
            });
        }
    });

    const channelTreeData: ITreeStructureGroup[] = [
        {
            children: [],
            key: ChannelTreeGroupTypeEnum.Broker,
            title: 'Broker',
            value: ChannelTreeGroupTypeEnum.Broker,
        },
        {
            children: [],
            key: ChannelTreeGroupTypeEnum.Direct,
            title: 'Direct',
            value: ChannelTreeGroupTypeEnum.Direct,
        },
    ];

    return (
        <Layout className='leads'>
            <Breadcrumb className='breadcrumb'>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Leads</Breadcrumb.Item>
            </Breadcrumb>
            <Layout className='content-wrapper'>
                <Layout.Content className='content'>
                    <Space className='actions'>
                        <Search/>
                        {filterButton}
                        <Link to='/leads/list'><Button>List View</Button></Link>
                        <Button className='add-lead' onClick={onClickAddLead} type='primary'>Add Lead</Button>
                    </Space>
                    <Typography.Title level={2}>Leads</Typography.Title>
                    <Space align='start' className='columns'>
                        {columnsBlock}
                    </Space>
                </Layout.Content>
            </Layout>

            {addModalBlock}

            <Modal
                footer={<Button className='close' onClick={onCancelChangeFilters}>Close</Button>}
                onCancel={onCancelChangeFilters}
                open={isChangeFiltersModalOpen}
                title='Change Filters'
                wrapClassName='leads-dashboard-change-filters-modal'
            >
                <Form.Item label='Assignees'>
                    <Tree
                        checkable={true}
                        treeData={assigneeTreeData}
                        checkedKeys={assigneeFilter}
                        onCheck={onChangeAssigneeFilter}
                        defaultExpandAll={true}
                        selectable={false}
                    />
                </Form.Item>
                <Form.Item label='BDMs'>
                    <Tree
                        checkable={true}
                        treeData={bdmTreeData}
                        checkedKeys={bdmFilter}
                        onCheck={onChangeBdmFilter}
                        defaultExpandAll={true}
                        selectable={false}
                    />
                </Form.Item>
                <Form.Item label='Channel'>
                    <Tree
                        checkable={true}
                        treeData={channelTreeData}
                        checkedKeys={channelFilter}
                        onCheck={onChangeChannelFilter}
                        defaultExpandAll={true}
                        selectable={false}
                    />
                </Form.Item>
            </Modal>
        </Layout>
    );
}
