import { all, call, debounce, put, select, takeEvery, takeLatest } from '@redux-saga/core/effects';
import { notification } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import IApplication from '~Api/Application/IApplication';
import { parseApplication } from '~Api/Application/parsers';
import { applicationGetRequest } from '~Api/Application/requests';
import IDeal from '~Api/Deal/IDeal';
import IDocument from '~Api/Loan/IDocument';
import IHistory from '~Api/Loan/IHistory';
import ILoan from '~Api/Loan/ILoan';
import ILoanFee from '~Api/Loan/ILoanFee';
import ILoanGracePeriod from '~Api/Loan/ILoanGracePeriod';
import ILoanNote from '~Api/Loan/ILoanNote';
import ILoanPayoutFigure from '~Api/Loan/ILoanPayoutFigure';
import ILoanPayoutFigureItem from '~Api/Loan/ILoanPayoutFigureItem';
import ILoanPayoutFigureSection from '~Api/Loan/ILoanPayoutFigureSection';
import ILoanTransaction from '~Api/Loan/ILoanTransaction';
import {
    parseLoan,
    parseLoanDocument,
    parseLoanFee,
    parseLoanGracePeriod,
    parseLoanHistory,
    parseLoanNote,
    parseLoanPayoutFigure,
    parseLoanPayoutFigureItem,
    parseLoanPayoutFigureSection,
    parseLoanTransaction,
} from '~Api/Loan/parsers';
import {
    loanActiveRequest,
    loanAssignRequest,
    loanDischargeDateRequest,
    loanDischargeRequest,
    loanDocumentDeleteRequest,
    loanDocumentsAddRequest,
    loanDocumentsListRequest,
    loanDrawdownRequest,
    loanExtensionRequest,
    loanFeeDeleteRequest,
    loanFeeUpdateRequest,
    loanFeesAddRequest,
    loanFeesListRequest,
    loanGetRequest,
    loanGracePeriodRequest,
    loanGracePeriodsAddRequest,
    loanGracePeriodsListRequest,
    loanHistoriesListRequest,
    loanNotesAddRequest,
    loanNotesListRequest,
    loanPayoutFigureApproveRequest,
    loanPayoutFigureCloneRequest,
    loanPayoutFigureDeleteRequest,
    loanPayoutFigureGetRequest,
    loanPayoutFigureItemAddRequest,
    loanPayoutFigureItemDeleteRequest,
    loanPayoutFigureItemUpdateRequest,
    loanPayoutFigureProcessRequest,
    loanPayoutFigureSectionAddRequest,
    loanPayoutFigureSectionDeleteRequest,
    loanPayoutFigureUpdateRequest,
    loanPayoutFiguresAddRequest,
    loanPayoutFiguresListRequest,
    loanPrincipalReductionRequest,
    loanRecordDrawdownRequest,
    loanRecordNextPaymentRequest,
    loanRecoveryRequest,
    loanSendMaturationReminderRequest,
    loanSkipMaturationReminderRequest,
    loanTransactionsListRequest,
    loanUpdateRequest,
    loanWarehouseLoansListRequest,
    loansDashboardRequest,
    loansDischargeForecastRequest,
    loansDrawdownsRequest,
    loansListRequest,
    loansPaginatedListRequest,
    loansSearchRequest,
} from '~Api/Loan/requests';
import IWarehouseLoan from '~Api/Warehouse/IWarehouseLoan';
import { parseWarehouseLoan } from '~Api/Warehouse/parsers';
import { applicationSetAction } from '~Applications/actions';
import { applicationSelector } from '~Applications/selectors';
import history from '~history';
import { leadSelector } from '~Leads/selectors';
import { IFetchResponse } from '~utilities/fetch';
import { renderNotificationLoadingIcon } from '~utilities/utils';
import {
    ILoanActiveAction,
    ILoanAssignAction,
    ILoanDischargeAction,
    ILoanDischargeDateAction,
    ILoanDocumentDeleteAction,
    ILoanDocumentsAddAction,
    ILoanDocumentsListAction,
    ILoanDrawdownAction,
    ILoanFeeDeleteAction,
    ILoanFeeSendAction,
    ILoanFeeValueSetAction,
    ILoanFeesAddAction,
    ILoanFeesListAction,
    ILoanGetAction,
    ILoanGracePeriodAction,
    ILoanGracePeriodsAddAction,
    ILoanGracePeriodsListAction,
    ILoanHistoriesListAction,
    ILoanNotesAddAction,
    ILoanNotesListAction,
    ILoanPayoutFigureApproveAction,
    ILoanPayoutFigureCloneAction,
    ILoanPayoutFigureDeleteAction,
    ILoanPayoutFigureGetAction,
    ILoanPayoutFigureGetWithLoanAndApplicationAction,
    ILoanPayoutFigureItemAddAction,
    ILoanPayoutFigureItemDeleteAction,
    ILoanPayoutFigureItemSendAction,
    ILoanPayoutFigureItemValueSetAction,
    ILoanPayoutFigureProcessAction,
    ILoanPayoutFigureSectionAddAction,
    ILoanPayoutFigureSectionDeleteAction,
    ILoanPayoutFigureSendAction,
    ILoanPayoutFigureValueSetAction,
    ILoanPayoutFiguresAddAction,
    ILoanPayoutFiguresListAction,
    ILoanPrincipalReductionAction,
    ILoanRecordDrawdownAction,
    ILoanRecordNextPaymentAction,
    ILoanRecoveryAction,
    ILoanSendAction,
    ILoanSendMaturationReminderAction,
    ILoanSkipMaturationReminderAction,
    ILoanTransactionsListAction,
    ILoanValueSetAction,
    ILoanWarehouseLoansListAction,
    ILoansDashboardListAction,
    ILoansDischargeForecastListAction,
    ILoansListAction,
    ILoansPaginatedListAction,
    ILoansSearchAction,
    loanDocumentRemoveAction,
    loanDocumentSetAction,
    loanDocumentsSetAction,
    loanFeeRemoveAction,
    loanFeeSendAction,
    loanFeeSetAction,
    loanFeesSetAction,
    loanGetAction,
    loanGracePeriodSetAction,
    loanGracePeriodsSetAction,
    loanHistoriesListAction,
    loanHistoriesSetAction,
    loanNoteSetAction,
    loanNotesSetAction,
    loanPayoutFigureItemSendAction,
    loanPayoutFigureItemSetAction,
    loanPayoutFigureRemoveAction,
    loanPayoutFigureSectionSetAction,
    loanPayoutFigureSendAction,
    loanPayoutFigureSetAction,
    loanPayoutFiguresSetAction,
    loanSendAction,
    loanSetAction,
    loanTransactionsListAction,
    loanTransactionsSetAction,
    loanValueSetAction,
    loanWarehouseLoansSetAction,
    loansDashboardSetAction,
    loansDischargeForecastSetAction,
    loansDrawdownsSetAction,
    loansPaginatedSetAction,
    loansSearchResultsSetAction,
    loansSetAction,
} from './actions';
import LoansActionsEnum from './ActionsEnum';
import {
    loanFeeSelector,
    loanPayoutFigureItemSelector,
    loanPayoutFigureSelector,
    loanSelector,
} from './selectors';

function* loanActive(action: ILoanActiveAction): Iterator<unknown> {
    const rawLoan: IFetchResponse = yield call(loanActiveRequest, action.uuid);
    const loan: ILoan = parseLoan(rawLoan.body);
    yield put(loanSetAction(loan));
}

function* loanAssign(action: ILoanAssignAction): Iterator<unknown> {
    const key: string = `loanAssign ${action.loanUuid}`;
    const message: string = 'Assign Loan';

    notification.open({
        description: 'Assigning loan...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const loanAssignResponse: IFetchResponse = yield call(loanAssignRequest, action.loanUuid, action.administratorUuid);
    if (loanAssignResponse.status === 422) {
        notification.error({
            description: `There was a problem assigning the loan: ${_.values(loanAssignResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const loan: ILoan = parseLoan(loanAssignResponse.body);
        yield put(loanSetAction(loan));

        notification.success({
            description: 'The loan has been assigned.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* loanDischarge(action: ILoanDischargeAction): Iterator<unknown> {
    const rawLoan: IFetchResponse = yield call(loanDischargeRequest, action.uuid, action.dischargeDate);
    const loan: ILoan = parseLoan(rawLoan.body);
    yield put(loanSetAction(loan));
}

function* loanDischargeDate(action: ILoanDischargeDateAction): Iterator<unknown> {
    const key: string = `loanDischargeDate ${dayjs().format()}`;

    notification.open({
        description: 'Changing discharge date...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Change Discharge Date',
    });

    yield call(loanDischargeDateRequest, action.uuid, action.dischargeDate);

    notification.success({
        description: 'The discharge date has been changed.',
        duration: 4.5,
        key,
        message: 'Change Discharge Date',
    });
}

function* loanDocumentsAdd(action: ILoanDocumentsAddAction): Iterator<unknown> {
    const key: string = `loanDocumentsAdd ${dayjs().format()}`;

    notification.open({
        description: 'Adding document...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Add Document',
    });

    const loanDocumentsAddResponse: IFetchResponse = yield call(loanDocumentsAddRequest, action.loanUuid, action.file);
    const document: IDocument = parseLoanDocument(loanDocumentsAddResponse.body);
    yield put(loanDocumentSetAction(document));

    notification.success({
        description: 'The document has been added.',
        duration: 4.5,
        key,
        message: 'Add Document',
    });
}

function* loanDocumentsList(action: ILoanDocumentsListAction): Iterator<unknown> {
    const loanDocumentsListResponse: IFetchResponse = yield call(loanDocumentsListRequest, action.loanUuid);
    const documents: IDocument[] = yield Promise.all(loanDocumentsListResponse.body.map(parseLoanDocument));
    yield put(loanDocumentsSetAction(action.loanUuid, documents));
}

function* loanDocumentDelete(action: ILoanDocumentDeleteAction): Iterator<unknown> {
    const key: string = `loanDocumentDelete ${action.documentUuid}`;
    const message: string = 'Delete Loan Document';

    notification.open({
        description: 'Deleting loan document...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const loanDocumentDeleteResponse: IFetchResponse = yield call(loanDocumentDeleteRequest, action.documentUuid);

    if (loanDocumentDeleteResponse.status === 422) {
        notification.error({
            description: `There was a problem deleting the loan document: ${_.values(loanDocumentDeleteResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        yield put(loanDocumentRemoveAction(action.documentUuid));
        notification.success({
            description: 'The loan document has been deleted.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* loanDrawdown(action: ILoanDrawdownAction): Iterator<unknown> {
    const rawLoan: IFetchResponse = yield call(loanDrawdownRequest, action.uuid);
    const loan: ILoan = parseLoan(rawLoan.body);
    yield put(loanSetAction(loan));
}

function* loanExtension(action: ILoanActiveAction): Iterator<unknown> {
    const rawLoan: IFetchResponse = yield call(loanExtensionRequest, action.uuid);
    const loan: ILoan = parseLoan(rawLoan.body);
    yield put(loanSetAction(loan));
}

function* loanFeesAdd(action: ILoanFeesAddAction): Iterator<unknown> {
    const key: string = `loanFeesAdd ${action.loanUuid}`;
    const message: string = 'Add Fee/Outlay';

    notification.open({
        description: 'Adding fee/outlay...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const loanFeesAddResponse: IFetchResponse = yield call(loanFeesAddRequest, action.loanUuid, action.fee);
    if (loanFeesAddResponse.status === 422) {
        notification.error({
            description: `There was a problem adding the fee/outlay: ${_.values(loanFeesAddResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const loanFee: ILoanFee = parseLoanFee(loanFeesAddResponse.body);
        yield put(loanFeeSetAction(action.loanUuid, loanFee));

        yield put(loanHistoriesListAction(action.loanUuid));

        notification.success({
            description: 'The fee/outlay has been added.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* loanFeeDelete(action: ILoanFeeDeleteAction): Iterator<unknown> {
    const key: string = `loanFeeDelete ${action.feeUuid}`;

    notification.open({
        description: 'Deleting fee/outlay...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Delete Fee/Outlay',
    });

    yield call(loanFeeDeleteRequest, action.feeUuid);
    yield put(loanFeeRemoveAction(action.loanUuid, action.feeUuid));

    notification.success({
        description: 'The fee/outlay has been deleted.',
        duration: 4.5,
        key,
        message: 'Delete Fee/Outlay',
    });
}

function* loanFeeSend(action: ILoanFeeSendAction): Iterator<unknown> {
    const loanFee: ILoanFee = yield select(loanFeeSelector, action.loanUuid, action.feeUuid);
    yield call(loanFeeUpdateRequest, loanFee);
}

function* loanFeeValueSet(action: ILoanFeeValueSetAction): Iterator<unknown> {
    yield put(loanFeeSendAction(action.loanUuid, action.feeUuid));
}

function* loanFeesList(action: ILoanFeesListAction): Iterator<unknown> {
    const rawFees: IFetchResponse = yield call(loanFeesListRequest, action.loanUuid);
    const fees: ILoanFee[] = yield Promise.all(rawFees.body.map(parseLoanFee));
    yield put(loanFeesSetAction(action.loanUuid, fees));
}

function* loanGet(action: ILoanGetAction): Iterator<unknown> {
    const rawLoan: IFetchResponse = yield call(loanGetRequest, action.uuid);
    const loan: ILoan = parseLoan(rawLoan.body);
    yield put(loanSetAction(loan));
}

function* loanGracePeriod(action: ILoanGracePeriodAction): Iterator<unknown> {
    const rawLoan: IFetchResponse = yield call(loanGracePeriodRequest, action.uuid);
    const loan: ILoan = parseLoan(rawLoan.body);
    yield put(loanSetAction(loan));
}

function* loanGracePeriodsAdd(action: ILoanGracePeriodsAddAction): Iterator<unknown> {
    const key: string = `loanGracePeriodsAdd ${action.loanUuid}`;
    const message: string = 'Add Grace Period';

    notification.open({
        description: 'Adding grace period...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const loanGracePeriodsAddResponse: IFetchResponse = yield call(loanGracePeriodsAddRequest, action.loanUuid, action.loanGracePeriod);
    if (loanGracePeriodsAddResponse.status === 422) {
        notification.error({
            description: `There was a problem adding the grace period: ${_.values(loanGracePeriodsAddResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const responseLoanGracePeriod: ILoanGracePeriod = parseLoanGracePeriod(loanGracePeriodsAddResponse.body);
        yield put(loanGracePeriodSetAction(responseLoanGracePeriod));

        const loanGetResponse: IFetchResponse = yield call(loanGetRequest, action.loanUuid);
        yield put(loanSetAction(parseLoan(loanGetResponse.body)));

        notification.success({
            description: 'The grace period has been added.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* loanGracePeriodsList(action: ILoanGracePeriodsListAction): Iterator<unknown> {
    const loanGracePeriodsListResponse: IFetchResponse = yield call(loanGracePeriodsListRequest, action.loanUuid);
    const loanGracePeriods: ILoanGracePeriod[] = yield Promise.all(loanGracePeriodsListResponse.body.map(parseLoanGracePeriod));
    yield put(loanGracePeriodsSetAction(action.loanUuid, loanGracePeriods));
}

function* loanHistoriesList(action: ILoanHistoriesListAction): Iterator<unknown> {
    const rawHistories: IFetchResponse = yield call(loanHistoriesListRequest, action.uuid);
    const histories: IHistory[] = yield Promise.all(rawHistories.body.map(parseLoanHistory));
    yield put(loanHistoriesSetAction(action.uuid, histories));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* loansDashboardList(action: ILoansDashboardListAction): Iterator<unknown> {
    const loansDashboardResponse: IFetchResponse = yield call(loansDashboardRequest);
    const loans: ILoan[] = yield Promise.all(loansDashboardResponse.body.map(parseLoan));
    yield put(loansDashboardSetAction(loans));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* loansDischargeForecastList(action: ILoansDischargeForecastListAction): Iterator<unknown> {
    const loansDischargeForecastResponse: IFetchResponse = yield call(loansDischargeForecastRequest);
    const loans: ILoan[] = yield Promise.all(loansDischargeForecastResponse.body.map(parseLoan));
    yield put(loansDischargeForecastSetAction(loans));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* loansDrawdownsList(action: ILoansDashboardListAction): Iterator<unknown> {
    const loansDrawdownsResponse: IFetchResponse = yield call(loansDrawdownsRequest);
    const loans: ILoan[] = yield Promise.all(loansDrawdownsResponse.body.map(parseLoan));
    yield put(loansDrawdownsSetAction(loans));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* loansList(action: ILoansListAction): Iterator<unknown> {
    const rawLoans: IFetchResponse = yield call(loansListRequest);
    const loans: ILoan[] = yield Promise.all(rawLoans.body.map(parseLoan));
    yield put(loansSetAction(loans));
}

function* loansPaginatedList(action: ILoansPaginatedListAction): Iterator<unknown> {
    const loansListResponse: IFetchResponse = yield call(
        loansPaginatedListRequest,
        action.page,
        action.perPage,
        action.orderBy,
        action.order,
        action.statuses,
        action.administratorUuids,
        action.code,
        action.name,
    );
    const loansTotalCount: number = parseInt(loansListResponse.headers.get('X-Pagination-Total-Records'), 10);
    const loans: ILoan[] = yield Promise.all(loansListResponse.body.map(parseLoan));

    yield put(loansPaginatedSetAction(loans, loansTotalCount, action.page, action.perPage));
}

function* loansSearch(action: ILoansSearchAction): Iterator<unknown> {
    const loansSearchResponse: IFetchResponse = yield call(loansSearchRequest, action.keyword);
    const loans: ILoan[] = yield Promise.all(loansSearchResponse.body.map(parseLoan));
    yield put(loansSearchResultsSetAction(loans));
}

function* loanNotesAdd(action: ILoanNotesAddAction): Iterator<unknown> {
    const key: string = `loanNotesAdd ${dayjs().format()}`;

    notification.open({
        description: 'Adding note...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Add Note',
    });

    const rawNote: IFetchResponse = yield call(loanNotesAddRequest, action.loanUuid, {
        note: action.note,
    });
    const note: ILoanNote = parseLoanNote(rawNote.body);

    yield put(loanNoteSetAction(action.loanUuid, note));

    notification.success({
        description: 'The note has been added.',
        duration: 4.5,
        key,
        message: 'Add Note',
    });
}

function* loanNotesList(action: ILoanNotesListAction): Iterator<unknown> {
    const rawNotes: IFetchResponse = yield call(loanNotesListRequest, action.uuid);
    const notes: ILoanNote[] = yield Promise.all(rawNotes.body.map(parseLoanNote));
    yield put(loanNotesSetAction(action.uuid, notes));
}

function* loanPayoutFigureApprove(action: ILoanPayoutFigureApproveAction): Iterator<unknown> {
    const key: string = `loanPayoutFigureApprove ${action.payoutFigureUuid}`;
    const message: string = 'Approve Draft Payout Figure';

    notification.open({
        description: 'Approving draft payout figure...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const payoutFigureApproveResponse: IFetchResponse = yield call(loanPayoutFigureApproveRequest, action.payoutFigureUuid);
    if (payoutFigureApproveResponse.status === 422) {
        notification.error({
            description: `There was a problem approving the payout figure: ${_.values(payoutFigureApproveResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const loanPayoutFigure: ILoanPayoutFigure = parseLoanPayoutFigure(payoutFigureApproveResponse.body);
        yield put(loanPayoutFigureSetAction(loanPayoutFigure));

        notification.success({
            description: 'The payout figure has been approved.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* loanPayoutFigureClone(action: ILoanPayoutFigureCloneAction): Iterator<unknown> {
    const key: string = `loanPayoutFigureClone ${action.payoutFigureUuid}`;
    const message: string = 'Clone Payout Figure';

    notification.open({
        description: 'Cloning payout figure...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const payoutFigureCloneResponse: IFetchResponse = yield call(loanPayoutFigureCloneRequest, action.payoutFigureUuid);
    if (payoutFigureCloneResponse.status === 422) {
        notification.error({
            description: `There was a problem cloning the payout figure: ${_.values(payoutFigureCloneResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const loanPayoutFigure: ILoanPayoutFigure = parseLoanPayoutFigure(payoutFigureCloneResponse.body);
        yield put(loanPayoutFigureSetAction(loanPayoutFigure));

        // Redirect to the new loan payout figure
        history.push(`/loans/${loanPayoutFigure.loanUuid}/payout-figures/${loanPayoutFigure.uuid}/edit`);

        notification.success({
            description: 'The payout figure has been cloned.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* loanPayoutFigureDelete(action: ILoanPayoutFigureDeleteAction): Iterator<unknown> {
    const key: string = `loanPayoutFigureDelete ${action.payoutFigureUuid}`;
    const message: string = 'Delete Draft Payout Figure';

    notification.open({
        description: 'Deleting draft payout figure...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const payoutFigureDeleteResponse: IFetchResponse = yield call(loanPayoutFigureDeleteRequest, action.payoutFigureUuid);
    if (payoutFigureDeleteResponse.status === 422) {
        notification.error({
            description: `There was a problem deleting the payout figure: ${_.values(payoutFigureDeleteResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        yield put(loanPayoutFigureRemoveAction(action.payoutFigureUuid));
        notification.success({
            description: 'The payout figure has been deleted.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* loanPayoutFigureGet(action: ILoanPayoutFigureGetAction): Iterator<unknown> {
    const rawPayoutFigure: IFetchResponse = yield call(loanPayoutFigureGetRequest, action.payoutFigureUuid);
    const payoutFigure: ILoanPayoutFigure = parseLoanPayoutFigure(rawPayoutFigure.body);
    yield put(loanPayoutFigureSetAction(payoutFigure));
}

function* loanPayoutFigureGetWithLoanAndApplication(action: ILoanPayoutFigureGetWithLoanAndApplicationAction): Iterator<unknown> {
    let loanPayoutFigure: ILoanPayoutFigure = yield select(loanPayoutFigureSelector, action.loanPayoutFigureUuid);
    if (!loanPayoutFigure) {
        const rawLoanPayoutFigure: IFetchResponse = yield call(loanPayoutFigureGetRequest, action.loanPayoutFigureUuid);
        loanPayoutFigure = parseLoanPayoutFigure(rawLoanPayoutFigure.body);
        yield put(loanPayoutFigureSetAction(loanPayoutFigure));
    }

    let loan: ILoan = yield select(loanSelector, loanPayoutFigure.loanUuid);
    if (!loan) {
        const rawLoan: IFetchResponse = yield call(loanGetRequest, loanPayoutFigure.loanUuid);
        loan = parseLoan(rawLoan.body);
        yield put(loanSetAction(loan));
    }

    let application: IApplication = yield select(applicationSelector, loan.applicationUuid);
    if (!application) {
        const applicationGetResponse: IFetchResponse = yield call(applicationGetRequest, loan.applicationUuid);
        application = parseApplication(applicationGetResponse.body);
        yield put(applicationSetAction(application));
    }
}

function* loanPayoutFigureProcess(action: ILoanPayoutFigureProcessAction): Iterator<unknown> {
    const key: string = `loanPayoutFigureProcess ${dayjs().format()}`;
    const message: string = 'Process Payout Figure';

    notification.open({
        description: 'Processing payout figure...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const payoutFigureProcessResponse: IFetchResponse = yield call(loanPayoutFigureProcessRequest, action.payoutFigureUuid, action.force);
    if (payoutFigureProcessResponse.status === 422) {
        notification.error({
            description: `There was a problem processing the payout figure: ${_.values(payoutFigureProcessResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const loanPayoutFigure: ILoanPayoutFigure = parseLoanPayoutFigure(payoutFigureProcessResponse.body);
        yield put(loanGetAction(loanPayoutFigure.loanUuid));
        yield put(loanTransactionsListAction(loanPayoutFigure.loanUuid));

        notification.success({
            description: 'The payout figure has been processed.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* loanPayoutFigureValueSet(action: ILoanPayoutFigureValueSetAction): Iterator<unknown> {
    yield put(loanPayoutFigureSendAction(action.loanPayoutFigureUuid));
}

function* loanPayoutFigureSend(action: ILoanPayoutFigureSendAction): Iterator<unknown> {
    const loanPayoutFigure: ILoanPayoutFigure = yield select(loanPayoutFigureSelector, action.loanPayoutFigureUuid);
    yield call(loanPayoutFigureUpdateRequest, loanPayoutFigure);
}

function* loanPayoutFigureItemAdd(action: ILoanPayoutFigureItemAddAction): Iterator<unknown> {
    const loanPayoutFigureItemAddResponse: IFetchResponse = yield call(loanPayoutFigureItemAddRequest, action.loanPayoutFigureItem);
    const loanPayoutFigureItem: ILoanPayoutFigureItem = parseLoanPayoutFigureItem(loanPayoutFigureItemAddResponse.body);
    yield put(loanPayoutFigureItemSetAction(loanPayoutFigureItem));
}

function* loanPayoutFigureItemDelete(action: ILoanPayoutFigureItemDeleteAction): Iterator<unknown> {
    yield call(loanPayoutFigureItemDeleteRequest, action.itemUuid);
}

function* loanPayoutFigureItemSend(action: ILoanPayoutFigureItemSendAction): Iterator<unknown> {
    const item: ILoanPayoutFigureItem = yield select(loanPayoutFigureItemSelector, action.itemUuid);
    yield call(loanPayoutFigureItemUpdateRequest, item);
}

function* loanPayoutFigureItemValueSet(action: ILoanPayoutFigureItemValueSetAction): Iterator<unknown> {
    yield put(loanPayoutFigureItemSendAction(action.itemUuid));
}

function* loanPayoutFigureSectionAdd(action: ILoanPayoutFigureSectionAddAction): Iterator<unknown> {
    const loanPayoutFigureSectionAddResponse: IFetchResponse = yield call(loanPayoutFigureSectionAddRequest, action.loanPayoutFigureSection);
    const loanPayoutFigureSection: ILoanPayoutFigureSection = parseLoanPayoutFigureSection(loanPayoutFigureSectionAddResponse.body);
    yield put(loanPayoutFigureSectionSetAction(loanPayoutFigureSection));
}

function* loanPayoutFigureSectionDelete(action: ILoanPayoutFigureSectionDeleteAction): Iterator<unknown> {
    yield call(loanPayoutFigureSectionDeleteRequest, action.sectionUuid);
}

function* loanPayoutFiguresAdd(action: ILoanPayoutFiguresAddAction): Iterator<unknown> {
    const key: string = `loanPayoutFiguresAdd ${dayjs().format()}`;
    const message: string = 'Create Payout Figure';

    notification.open({
        description: 'Creating payout figure...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const payoutFiguresAddResponse: IFetchResponse = yield call(loanPayoutFiguresAddRequest, action.payoutFigure);
    if (payoutFiguresAddResponse.status === 422) {
        notification.error({
            description: `There was a problem creating the payout figure: ${_.values(payoutFiguresAddResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const payoutFigure: ILoanPayoutFigure = parseLoanPayoutFigure(payoutFiguresAddResponse.body);
        yield put(loanPayoutFigureSetAction(payoutFigure));

        history.push(`/loans/${payoutFigure.loanUuid}/payout-figures`);

        notification.success({
            description: 'The payout figure has been created.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* loanPayoutFiguresList(action: ILoanPayoutFiguresListAction): Iterator<unknown> {
    const payoutFiguresResponse: IFetchResponse = yield call(loanPayoutFiguresListRequest, action.loanUuid);
    const payoutFigures: ILoanPayoutFigure[] = yield Promise.all(payoutFiguresResponse.body.map(parseLoanPayoutFigure));
    yield put(loanPayoutFiguresSetAction(action.loanUuid, payoutFigures));
}

function* loanPrincipalReduction(action: ILoanPrincipalReductionAction): Iterator<unknown> {
    const key: string = `loanPrincipalReduction ${action.loanUuid}`;
    const message: string = 'Principal Reduction';

    notification.open({
        description: 'Recording principal reduction...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const principalReductionResponse: IFetchResponse = yield call(loanPrincipalReductionRequest, action.loanUuid, action.amount, action.transactionDate);
    if (principalReductionResponse.status === 422) {
        notification.error({
            description: `There was a problem recording the principal reduction: ${_.values(principalReductionResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const loan: ILoan = parseLoan(principalReductionResponse.body);
        yield put(loanSetAction(loan));

        notification.success({
            description: 'The principal reduction has been recorded.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* loanRecordDrawdown(action: ILoanRecordDrawdownAction): Iterator<unknown> {
    const key: string = `loanRecordDrawdown ${action.loanUuid}`;
    const message: string = 'Record Drawdown';

    notification.open({
        description: 'Recording drawdown...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const recordDrawdownResponse: IFetchResponse = yield call(loanRecordDrawdownRequest, action.loanUuid, action.retainedContingency, action.retainedFunds, action.transactionDate);
    const loan: ILoan = parseLoan(recordDrawdownResponse.body);
    yield put(loanSetAction(loan));

    notification.success({
        description: 'The drawdown has been recorded.',
        duration: 4.5,
        key,
        message,
    });
}

function* loanRecordNextPayment(action: ILoanRecordNextPaymentAction): Iterator<unknown> {
    const key: string = `loanRecordNextPayment ${action.uuid}`;
    const message: string = 'Record Next Payment';

    notification.open({
        description: 'Recording payment...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message,
    });

    const loan: ILoan = yield select(loanSelector, action.uuid);

    const loanRecordNextPaymentResponse: IFetchResponse = yield call(loanRecordNextPaymentRequest, loan, action.force);
    if (loanRecordNextPaymentResponse.status === 422) {
        notification.error({
            description: `There was a problem recording the next payment: ${_.values(loanRecordNextPaymentResponse.body)[0]}.`,
            duration: 0,
            key,
            message,
        });
    } else {
        const afterLoan: ILoan = parseLoan(loanRecordNextPaymentResponse.body);
        yield put(loanSetAction(afterLoan));

        yield put(loanTransactionsListAction(action.uuid));

        notification.success({
            description: 'The next payment has been recorded.',
            duration: 4.5,
            key,
            message,
        });
    }
}

function* loanRecovery(action: ILoanRecoveryAction): Iterator<unknown> {
    const loanRecoveryResponse: IFetchResponse = yield call(loanRecoveryRequest, action.loanUuid);
    const loan: ILoan = parseLoan(loanRecoveryResponse.body);
    yield put(loanSetAction(loan));
}

function* loanSend(action: ILoanSendAction): Iterator<unknown> {
    const loan: ILoan = yield select(loanSelector, action.loanUuid);
    yield call(loanUpdateRequest, loan);
}

function* loanSendMaturationReminder(action: ILoanSendMaturationReminderAction): Iterator<unknown> {
    const key: string = `loanSendMaturationReminder ${action.uuid}`;

    notification.open({
        description: 'Sending reminder...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Send Maturation Reminder',
    });

    const rawLoan: IFetchResponse = yield call(loanSendMaturationReminderRequest, action.uuid, action.status);
    const loan: ILoan = parseLoan(rawLoan.body);
    yield put(loanSetAction(loan));

    yield put(loanHistoriesListAction(action.uuid));

    notification.success({
        description: 'The reminder has been sent.',
        duration: 4.5,
        key,
        message: 'Send Maturation Reminder',
    });
}

function* loanSkipMaturationReminder(action: ILoanSkipMaturationReminderAction): Iterator<unknown> {
    const key: string = `loanSkipMaturationReminder ${action.uuid}`;

    notification.open({
        description: 'Skipping reminder...',
        duration: 0,
        icon: renderNotificationLoadingIcon(),
        key,
        message: 'Skip Maturation Reminder',
    });

    const rawLoan: IFetchResponse = yield call(loanSkipMaturationReminderRequest, action.uuid, action.status);
    const loan: ILoan = parseLoan(rawLoan.body);
    yield put(loanSetAction(loan));

    yield put(loanHistoriesListAction(action.uuid));

    notification.success({
        description: 'The reminder has been skipped.',
        duration: 4.5,
        key,
        message: 'Skip Maturation Reminder',
    });
}

function* loanTransactionsList(action: ILoanTransactionsListAction): Iterator<unknown> {
    const loanTransactionsListResponse: IFetchResponse = yield call(loanTransactionsListRequest, action.loanUuid);
    const loanTransactions: ILoanTransaction[] = yield Promise.all(loanTransactionsListResponse.body.map(parseLoanTransaction));
    yield put(loanTransactionsSetAction(action.loanUuid, loanTransactions));
}

function* loanValueSet(action: ILoanValueSetAction): Iterator<unknown> {
    if (action.key === 'applicationUuid') {
        const loan: ILoan = yield select(loanSelector, action.uuid);
        if (action.value) {
            const application: IApplication = yield select(applicationSelector, action.value as string);
            const deal: IDeal = yield select(leadSelector, application.dealUuid);
            yield put(loanValueSetAction(loan.uuid, 'code', deal.code));
        } else {
            yield put(loanValueSetAction(loan.uuid, 'code', null));
        }
    }

    yield put(loanSendAction(action.uuid));
}

function* loanWarehouseLoansList(action: ILoanWarehouseLoansListAction): Iterator<unknown> {
    const rawWarehouseLoans: IFetchResponse = yield call(loanWarehouseLoansListRequest, action.loanUuid);
    const warehouseLoans: IWarehouseLoan[] = yield Promise.all(rawWarehouseLoans.body.map(parseWarehouseLoan));
    yield put(loanWarehouseLoansSetAction(action.loanUuid, warehouseLoans));
}

export function* LoansSagas(): Iterator<unknown> {
    yield all([
        takeEvery(LoansActionsEnum.LoanAssign, loanAssign),

        takeEvery(LoansActionsEnum.LoanDocumentsAdd, loanDocumentsAdd),
        takeEvery(LoansActionsEnum.LoanDocumentsList, loanDocumentsList),

        debounce(20, LoansActionsEnum.LoanDocumentDelete, loanDocumentDelete),

        debounce(20, LoansActionsEnum.LoanFeesAdd, loanFeesAdd),
        takeEvery(LoansActionsEnum.LoanFeesList, loanFeesList),

        takeEvery(LoansActionsEnum.LoanFeeDelete, loanFeeDelete),
        debounce(500, LoansActionsEnum.LoanFeeSend, loanFeeSend),
        takeEvery(LoansActionsEnum.LoanFeeValueSet, loanFeeValueSet),

        takeEvery(LoansActionsEnum.LoanGracePeriodsAdd, loanGracePeriodsAdd),
        takeEvery(LoansActionsEnum.LoanGracePeriodsList, loanGracePeriodsList),

        takeEvery(LoansActionsEnum.LoanActive, loanActive),
        takeEvery(LoansActionsEnum.LoanDischarge, loanDischarge),
        takeEvery(LoansActionsEnum.LoanDischargeDate, loanDischargeDate),
        takeEvery(LoansActionsEnum.LoanDrawdown, loanDrawdown),
        takeEvery(LoansActionsEnum.LoanExtension, loanExtension),
        debounce(20, LoansActionsEnum.LoanGet, loanGet),
        takeEvery(LoansActionsEnum.LoanGracePeriod, loanGracePeriod),
        takeEvery(LoansActionsEnum.LoanHistoriesList, loanHistoriesList),
        takeEvery(LoansActionsEnum.LoanPrincipalReduction, loanPrincipalReduction),
        debounce(500, LoansActionsEnum.LoanRecordDrawdown, loanRecordDrawdown),
        debounce(20, LoansActionsEnum.LoanRecordNextPayment, loanRecordNextPayment),
        takeEvery(LoansActionsEnum.LoanRecovery, loanRecovery),
        debounce(500, LoansActionsEnum.LoanSend, loanSend),
        debounce(20, LoansActionsEnum.LoanSendMaturationReminder, loanSendMaturationReminder),
        debounce(20, LoansActionsEnum.LoanSkipMaturationReminder, loanSkipMaturationReminder),
        takeEvery(LoansActionsEnum.LoanTransactionsList, loanTransactionsList),
        takeEvery(LoansActionsEnum.LoanValueSet, loanValueSet),

        takeEvery(LoansActionsEnum.LoanNotesAdd, loanNotesAdd),
        takeEvery(LoansActionsEnum.LoanNotesList, loanNotesList),

        takeEvery(LoansActionsEnum.LoanPayoutFigureApprove, loanPayoutFigureApprove),
        takeEvery(LoansActionsEnum.LoanPayoutFigureClone, loanPayoutFigureClone),
        takeEvery(LoansActionsEnum.LoanPayoutFigureDelete, loanPayoutFigureDelete),
        debounce(20, LoansActionsEnum.LoanPayoutFigureGet, loanPayoutFigureGet),
        takeEvery(LoansActionsEnum.LoanPayoutFigureGetWithLoanAndApplication, loanPayoutFigureGetWithLoanAndApplication),
        takeEvery(LoansActionsEnum.LoanPayoutFigureProcess, loanPayoutFigureProcess),

        debounce(500, LoansActionsEnum.LoanPayoutFigureSend, loanPayoutFigureSend),
        takeEvery(LoansActionsEnum.LoanPayoutFigureValueSet, loanPayoutFigureValueSet),

        takeEvery(LoansActionsEnum.LoanPayoutFigureItemAdd, loanPayoutFigureItemAdd),
        takeEvery(LoansActionsEnum.LoanPayoutFigureItemDelete, loanPayoutFigureItemDelete),
        debounce(500, LoansActionsEnum.LoanPayoutFigureItemSend, loanPayoutFigureItemSend),
        takeEvery(LoansActionsEnum.LoanPayoutFigureItemValueSet, loanPayoutFigureItemValueSet),

        takeEvery(LoansActionsEnum.LoanPayoutFigureSectionAdd, loanPayoutFigureSectionAdd),
        takeEvery(LoansActionsEnum.LoanPayoutFigureSectionDelete, loanPayoutFigureSectionDelete),

        debounce(20, LoansActionsEnum.LoanPayoutFiguresAdd, loanPayoutFiguresAdd),
        takeEvery(LoansActionsEnum.LoanPayoutFiguresList, loanPayoutFiguresList),

        takeEvery(LoansActionsEnum.LoanWarehouseLoansList, loanWarehouseLoansList),

        takeLatest(LoansActionsEnum.LoansDashboardList, loansDashboardList),
        takeLatest(LoansActionsEnum.LoansDischargeForecastList, loansDischargeForecastList),
        takeLatest(LoansActionsEnum.LoansDrawdownsList, loansDrawdownsList),
        debounce(20, LoansActionsEnum.LoansList, loansList),
        takeEvery(LoansActionsEnum.LoansPaginatedList, loansPaginatedList),

        takeLatest(LoansActionsEnum.LoansSearch, loansSearch),
    ]);
}
