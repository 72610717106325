import _ from 'lodash';
import IApplicationWarehouse from '~Api/Application/IApplicationWarehouse';
import ApplicationFeeFormatEnum from '~Api/Deal/ApplicationFeeFormatEnum';
import BrokerageFeeFormatEnum from '~Api/Deal/BrokerageFeeFormatEnum';
import EstablishmentFeeFormatEnum from '~Api/Deal/EstablishmentFeeFormatEnum';
import IProperty from '~Api/Deal/IProperty';
import { parseDeal, parseDealBorrower, parseDealDocument, parseDealProperty } from '~Api/Deal/parsers';
import BrokerCommissionFormatEnum from './BrokerCommissionFormatEnum';
import CloseReasonEnum from './CloseReasonEnum';
import CodeTypeEnum from './CodeTypeEnum';
import ConditionalApprovalStatusEnum from './ConditionalApprovalStatusEnum';
import DisbursalTypeEnum from './DisbursalTypeEnum';
import ExtensionTypeEnum from './ExtensionTypeEnum';
import IApplication from './IApplication';
import IApplicationDisbursement from './IApplicationDisbursement';
import IApplicationFee from './IApplicationFee';
import IApplicationProperty from './IApplicationProperty';
import IApplicationPropertyValuer from './IApplicationPropertyValuer';
import IBorrower from './IBorrower';
import IConditionalCondition from './IConditionalCondition';
import IConditional from './IConditional';
import IFormal from './IFormal';
import IHistory from './IHistory';
import INote from './INote';
import InterestPaymentMethodEnum from './InterestPaymentMethodEnum';
import ISettlementReportApplication from './ISettlementReportApplication';
import LoanPurposeEnum from './LoanPurposeEnum';
import MortgageTypeEnum from './MortgageTypeEnum';
import PartnerCommissionFormatEnum from './PartnerCommissionFormatEnum';
import RepaymentStrategyEnum from './RepaymentStrategyEnum';
import WorkflowStatusEnum from './WorkflowStatusEnum';
import ILoanPayoutFigure from '~Api/Loan/ILoanPayoutFigure';
import { parseLoanPayoutFigure } from '~Api/Loan/parsers';
import ICondition from './ICondition';
import IConditionDocument from './IConditionDocument';
import IRfiItem from './IRfiItem';
import { parseValuer } from '~Valuers/parsers';
import DischargeInterestTypeEnum from './DischargeInterestTypeEnum';
import { IStandardCondition } from './IStandardCondition';
import { IStandardConditionGroup } from './IStandardConditionGroup';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseApplication(raw: any): IApplication {
    const primaryBorrowerUuid: string = raw.primaryBorrowerUuid;
    const borrowers: IBorrower[] = raw.borrowers.map(parseApplicationBorrower);
    const primaryBorrower: IBorrower = _.find(borrowers, { uuid: primaryBorrowerUuid }) as IBorrower;
    const payoutFigure: ILoanPayoutFigure = raw.payoutFigure && parseLoanPayoutFigure(raw.payoutFigure);

    return {
        administratorUuid: raw.administratorUuid,
        applicationFee: raw.applicationFee ? Number(raw.applicationFee) : null,
        applicationFeeFormat: raw.applicationFeeFormat as ApplicationFeeFormatEnum,
        applicationFeePercentage: raw.applicationFeePercentage,
        borrowers,
        brokerCommissionDollars: raw.brokerCommissionDollars,
        brokerCommissionFormat: raw.brokerCommissionFormat as BrokerCommissionFormatEnum,
        brokerCommissionPercentage: raw.brokerCommissionPercentage,
        brokerageFee: raw.brokerageFee ? Number(raw.brokerageFee) : null,
        brokerageFeeFormat: raw.brokerageFeeFormat as BrokerageFeeFormatEnum,
        brokerageFeePercentage: raw.brokerageFeePercentage,
        closeReason: raw.closeReason as CloseReasonEnum,
        code: raw.code,
        codeType: raw.codeType as CodeTypeEnum,
        commitmentFee: raw.commitmentFee ? Number(raw.commitmentFee) : null,
        createdTime: raw.createdTime,
        creditNotes: raw.creditNotes,
        deal: parseDeal(raw.deal),
        dealUuid: raw.dealUuid,
        disbursalType: raw.disbursalType as DisbursalTypeEnum,
        dischargeInterestType: raw.dischargeInterestType as DischargeInterestTypeEnum,
        establishmentFee: raw.establishmentFee ? Number(raw.establishmentFee) : null,
        establishmentFeeFormat: raw.establishmentFeeFormat as EstablishmentFeeFormatEnum,
        establishmentFeePercentage: raw.establishmentFeePercentage,
        estimatedOutlays: raw.estimatedOutlays ? Number(raw.estimatedOutlays) : null,
        extensionNumber: raw.extensionNumber ? Number(raw.extensionNumber) : null,
        extensionType: raw.extensionType as ExtensionTypeEnum,
        formattedName: primaryBorrower ? primaryBorrower.dealBorrower.formattedName : '-',
        interestPaymentMethod: raw.interestPaymentMethod as InterestPaymentMethodEnum,
        interestRate: Number(raw.interestRate),
        interestRateDefault: Number(raw.interestRateDefault),
        internalComments: raw.internalComments,
        isExtension: raw.isExtension,
        legacy: raw.legacy,
        legalFees: raw.legalFees ? Number(raw.legalFees) : null,
        loanAmount: Number(raw.loanAmount),
        loanPurpose: raw.loanPurpose as LoanPurposeEnum,
        loanSynopsis: raw.loanSynopsis,
        loanUuid: raw.loanUuid,
        lvr: Number(raw.lvr),
        maximumLvr: raw.maximumLvr,
        modifiedTime: raw.modifiedTime,
        mortgageType: raw.mortgageType as MortgageTypeEnum,
        partnerCommissionDollars: raw.partnerCommissionDollars,
        partnerCommissionFormat: raw.partnerCommissionFormat as PartnerCommissionFormatEnum,
        partnerCommissionPercentage: raw.partnerCommissionPercentage,
        payoutFigure,
        payoutFigureUuid: payoutFigure && payoutFigure.uuid,
        previousLoanUuid: raw.previousLoanUuid,
        primaryBorrowerUuid,
        properties: raw.properties.map(parseApplicationProperty),
        repaymentStrategy: raw.repaymentStrategy as RepaymentStrategyEnum,
        secondaryRepaymentStrategy: raw.secondaryRepaymentStrategy as RepaymentStrategyEnum,
        secondsInStatusCurrent: raw.secondsInStatusCurrent,
        secondsInStatusTotal: raw.secondsInStatusTotal,
        settlementDate: raw.settlementDate,
        solicitorInstructionsSentTime: raw.solicitorInstructionsSentTime,
        solicitorUuid: raw.solicitorUuid,
        termMonths: raw.termMonths,
        termMonthsMinimum: raw.termMonthsMinimum,
        uuid: raw.uuid,
        workflowStatus: raw.workflowStatus as WorkflowStatusEnum,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseApplicationBorrower(raw: any): IBorrower {
    return {
        createdTime: raw.createdTime,
        creditScore: raw.creditScore ? Number(raw.creditScore) : null,
        dealBorrower: parseDealBorrower(raw.dealBorrower),
        dealBorrowerUuid: raw.dealBorrowerUuid,
        email: raw.email,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseApplicationCondition(raw: any): ICondition {
    return {
        createdTime: raw.createdTime,
        name: raw.name,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseApplicationConditionDocument(raw: any): IConditionDocument {
    return {
        applicationPropertyUuid: raw.applicationPropertyUuid,
        applicationUuid: raw.applicationUuid,
        approvalStatus: raw.approvalStatus,
        borrowerUuid: raw.applicationBorrowerUuid,
        conditionType: raw.conditionType,
        conditionUuid: raw.applicationConditionUuid || raw.dealConditionUuid,
        createdTime: raw.createdTime,
        dealPropertyUuid: raw.dealPropertyUuid,
        dealUuid: raw.dealUuid,
        document: raw.document && parseDealDocument(raw.document),
        documentUuid: raw.documentUuid,
        solicitorAccess: raw.solicitorAccess,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseApplicationConditional(raw: any): IConditional {
    const conditions: IConditionalCondition[] = raw.conditions.map(parseApplicationConditionalCondition);

    return {
        administratorUuid: raw.administratorUuid,
        applicationFee: Number(raw.applicationFee),
        applicationUuid: raw.applicationUuid,
        approvalStatus: raw.approvalStatus as ConditionalApprovalStatusEnum,
        brokerageFee: Number(raw.brokerageFee),
        code: raw.code,
        codeType: raw.codeType as CodeTypeEnum,
        commitmentFee: Number(raw.commitmentFee),
        conditions,
        createdTime: raw.createdTime,
        establishmentFee: Number(raw.establishmentFee),
        interestRate: Number(raw.interestRate),
        legalFees: Number(raw.legalFees),
        loanAmount: Number(raw.loanAmount),
        lvr: Number(raw.lvr),
        mortgageType: raw.mortgageType as MortgageTypeEnum,
        termMonths: raw.termMonths,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseApplicationConditionalCondition(raw: any): IConditionalCondition {
    return {
        name: raw.name,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseApplicationConditionalsStandardConditions(raw: any): IStandardConditionGroup[] {
    return _.map(raw, (categorisedStandardConditions: IStandardCondition[], heading: string) => {
        return ({
            conditions: _.map(categorisedStandardConditions, (standardCondition: IStandardCondition) => {
                return {
                    hint: standardCondition.hint,
                    label: standardCondition.label,
                };
            }),
            heading,
        });
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseApplicationDisbursement(raw: any): IApplicationDisbursement {
    return {
        amount: raw.amount,
        applicationUuid: raw.applicationUuid,
        createdTime: raw.createdTime,
        description: raw.description,
        payeeName: raw.payeeName,
        type: raw.type,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseApplicationFee(raw: any): IApplicationFee {
    return {
        amount: raw.amount,
        applicationUuid: raw.applicationUuid,
        createdTime: raw.createdTime,
        description: raw.description,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseApplicationFormal(raw: any): IFormal {
    return {
        administratorUuid: raw.administratorUuid,
        applicationFee: Number(raw.applicationFee),
        brokerageFee: Number(raw.brokerageFee),
        code: raw.code,
        codeType: raw.codeType as CodeTypeEnum,
        commitmentFee: Number(raw.commitmentFee),
        createdTime: raw.createdTime,
        establishmentFee: Number(raw.establishmentFee),
        interestRate: Number(raw.interestRate),
        legalFees: Number(raw.legalFees),
        loanAmount: Number(raw.loanAmount),
        lvr: Number(raw.lvr),
        mortgageType: raw.mortgageType as MortgageTypeEnum,
        termMonths: raw.termMonths,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseApplicationHistory(raw: any): IHistory {
    return {
        createdTime: raw.createdTime,
        description: raw.description,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseApplicationNote(raw: any): INote {
    return {
        administratorUuid: raw.administratorUuid,
        createdTime: raw.createdTime,
        note: raw.note,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseApplicationProperty(raw: any): IApplicationProperty {
    const dealProperty: IProperty = parseDealProperty(raw.dealProperty);

    return {
        application: raw.application && parseApplication(raw.application),
        applicationUuid: raw.applicationUuid,
        createdTime: raw.createdTime,
        dealProperty,
        dealPropertyUuid: raw.dealPropertyUuid,
        uuid: raw.uuid,
        valuationDueDate: raw.valuationDueDate,
        valuationInspectionDate: raw.valuationInspectionDate,
        valuationReceivedDate: raw.valuationReceivedDate,
        valuationValue: raw.valuationValue,
        valuers: raw.valuers && raw.valuers.map(parseApplicationPropertyValuer),
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseApplicationPropertyValuer(raw: any): IApplicationPropertyValuer {
    return {
        applicationProperty: raw.applicationProperty && parseApplicationProperty(raw.applicationProperty),
        applicationPropertyUuid: raw.applicationPropertyUuid,
        invoiceStatus: raw.invoiceStatus,
        quoteAmount: raw.quoteAmount,
        quoteReceivedTime: raw.quoteReceivedTime,
        quoteRequestedTime: raw.quoteRequestedTime,
        quoteTimeFrameDays: raw.quoteTimeFrameDays,
        status: raw.status,
        uuid: raw.uuid,
        valuationOrderedTime: raw.valuationOrderedTime,
        valuationReceivedTime: raw.valuationReceivedTime,
        valuer: raw.valuer && parseValuer(raw.valuer),
        valuerUuid: raw.valuerUuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseApplicationRfiItem(raw: any): IRfiItem {
    return {
        applicationBorrowerUuid: raw.applicationBorrowerUuid,
        applicationConditionUuid: raw.applicationConditionUuid,
        applicationPropertyUuid: raw.applicationPropertyUuid,
        applicationUuid: raw.applicationUuid,
        conditionType: raw.conditionType,
        uuid: raw.uuid,
        workflowStatus: raw.workflowStatus,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseApplicationWarehouse(raw: any): IApplicationWarehouse {
    return {
        amount: raw.amount,
        application: raw.application ? parseApplication(raw.application) : null,
        applicationUuid: raw.applicationUuid,
        uuid: raw.uuid,
        warehouseUuid: raw.warehouseUuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseSettlementReportApplication(raw: any): ISettlementReportApplication {
    return {
        aggregatorName: raw.aggregatorName,
        aggregatorUuid: raw.aggregatorUuid,
        applicationUuid: raw.applicationUuid,
        bdmName: raw.bdmName,
        bdmUuid: raw.bdmUuid,
        brokerFirstName: raw.brokerFirstName,
        brokerLastName: raw.brokerLastName,
        brokerUuid: raw.brokerUuid,
        dealUuid: raw.dealUuid,
        loanAmount: raw.loanAmount,
        loanPurpose: raw.loanPurpose,
        lvr: raw.lvr,
        propertyLocation: raw.propertyLocation,
        propertyType: raw.propertyType,
    };
}
