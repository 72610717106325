import { Card as AntCard, Badge, Space, Tag } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React, { ReactElement } from 'react';
import { FaCity, FaHome, FaHorse } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { currentAdministratorSelector } from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import RoleEnum from '~Api/Administrator/RoleEnum';
import ApprovalStatusEnum from '~Api/Deal/ApprovalStatusEnum';
import IDeal from '~Api/Deal/IDeal';
import IProperty from '~Api/Deal/IProperty';
import ZoneTypeEnum from '~Api/Deal/ZoneTypeEnum';
import AssigneeSelector from '~Deals/AssigneeSelector';
import FollowUpTime from '~Deals/FollowUpTime';
import { currencyFormatter } from '~utilities/formatters';

interface IProps {
    deal: IDeal;
}

export default function Card(props: IProps): ReactElement {
    const { deal } = props;

    const currentAdministrator: IAdministrator = useSelector(currentAdministratorSelector);

    const icons: JSX.Element[] = deal.properties.map((leadProperty: IProperty) => {
        switch (leadProperty.zoneType) {
            case ZoneTypeEnum.CommercialIndustrial:
            case ZoneTypeEnum.CommercialLand:
            case ZoneTypeEnum.CommercialOffice:
            case ZoneTypeEnum.CommercialRetail:
                return <FaCity key={leadProperty.uuid} />;
            case ZoneTypeEnum.ResidentialHouse:
            case ZoneTypeEnum.ResidentialLand:
            case ZoneTypeEnum.ResidentialTownhouse:
            case ZoneTypeEnum.ResidentialUnit:
                return <FaHome key={leadProperty.uuid} />;
            case ZoneTypeEnum.RuralLand:
            case ZoneTypeEnum.RuralResidential:
                return <FaHorse key={leadProperty.uuid} />;
        }
    });

    let followUpTag: JSX.Element = null;
    switch (deal.approvalStatus) {
        case ApprovalStatusEnum.UncontactableFollowUp1:
        case ApprovalStatusEnum.FollowUp1:
            followUpTag = <Tag color='green'>Follow Up 1</Tag>;
            break;
        case ApprovalStatusEnum.UncontactableFollowUp2:
        case ApprovalStatusEnum.FollowUp2:
            followUpTag = <Tag color='orange'>Follow Up 2</Tag>;
            break;
        case ApprovalStatusEnum.UncontactableFollowUp3:
        case ApprovalStatusEnum.FollowUp3:
            followUpTag = <Tag color='red'>Follow Up 3</Tag>;
            break;
    }

    const now: Dayjs = dayjs();
    const cardClassNames: string[] = [];
    if (deal.followUpTime) {
        const followUpTimeDayjs: Dayjs = dayjs(deal.followUpTime);

        if (followUpTimeDayjs < now) {
            cardClassNames.push('follow-up-time-expired');
        } else if (followUpTimeDayjs.format('YYYY-MM-DD') === now.format('YYYY-MM-DD')) {
            cardClassNames.push('follow-up-time-today');
        }
    }

    const daysCurrent: number = Math.ceil(deal.secondsInStatusCurrent / (60 * 60 * 24));
    const daysTotal: number = Math.ceil(deal.secondsInStatusTotal / (60 * 60 * 24));

    let timeInStatusClass: string = 'default';
    if (daysCurrent >= 21) {
        timeInStatusClass = 'danger';
    } else if (daysCurrent >= 14) {
        timeInStatusClass = 'warning';
    }

    let daysInCurrentStatus: string = `${daysCurrent}`;
    if (deal.secondsInStatusCurrent !== deal.secondsInStatusTotal) {
        daysInCurrentStatus += ` / ${daysTotal}`;
    }

    const lvrFormatter: Intl.NumberFormat = Intl.NumberFormat('en-AU', {
        style: 'percent',
    });

    const totalValue: number = _.sumBy(deal.properties, (leadProperty: IProperty) => leadProperty.estimatedValue);
    const totalCurrentDebt: number = _.sumBy(deal.properties, (leadProperty: IProperty) => leadProperty.currentDebt);
    const lvr: number = (deal.loanAmount + totalCurrentDebt) / totalValue;

    return (
        <AntCard className={cardClassNames.join(' ')} key={deal.uuid}>
            <Badge className={`time-in-status ${timeInStatusClass}`} count={daysInCurrentStatus} showZero={true} />
            <div className='code'>{deal.code}</div>
            <Link className='name' to={`/leads/${deal.uuid}`}>{deal.formattedName || '-'}</Link>
            <div className='amount'>{currencyFormatter.format(deal.baseLoanAmount)}</div>
            <div className='lvr'>{lvrFormatter.format(lvr)} LVR</div>
            <div className='property-type'><Space>{icons}</Space></div>
            {![RoleEnum.BusinessDevelopmentManager, RoleEnum.InternalBusinessDevelopmentManager].includes(currentAdministrator.role) && <div className='assignee'><AssigneeSelector deal={deal} /></div>}
            <div className='follow-up-time'>Follow Up <FollowUpTime dealUuid={deal.uuid} /></div>
            {followUpTag}
        </AntCard>
    );
}
