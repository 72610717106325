import _ from 'lodash';
import IAddressSearchResult from '~Api/AddressSearch/IAddressSearchResult';
import IBorrower from '~Api/Application/IBorrower';
import IDealBorrower from '~Api/Deal/IDealBorrower';
import IDocument from '~Api/Deal/IDocument';
import IHistory from '~Api/Deal/IHistory';
import INote from '~Api/Deal/INote';
import IPostcodeCategory from '~Api/Deal/IPostcodeCategory';
import IProperty from '~Api/Deal/IProperty';
import ISpPostcodeCategory from '~Api/Deal/ISpPostcodeCategory';
import { applicationBorrowerSelector } from '~Applications/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { keyMap } from '~utilities/utils';

export function applicationBorrowerDealBorrowerSelector(state: IGlobalState, applicationUuid: string, applicationBorrowerUuid: string): IDealBorrower {
    const applicationBorrower: IBorrower = applicationBorrowerSelector(state, applicationUuid, applicationBorrowerUuid);

    if (!applicationBorrower) {
        return null;
    }

    return state.deals.borrowers[applicationBorrower.dealBorrowerUuid];
}

export function dealBorrowerSelector(state: IGlobalState, dealBorrowerUuid: string): IDealBorrower {
    return state.deals.borrowers[dealBorrowerUuid];
}

export function dealDocumentsSelector(state: IGlobalState, dealUuid: string): IDictionary<IDocument> {
    if (!state.deals.dealDocumentUuids[dealUuid]) {
        return null;
    }

    return keyMap(state.deals.dealDocumentUuids[dealUuid], 'uuid', (uuid: string) => dealDocumentSelector(state, uuid));
}

export function dealHistoriesSelector(state: IGlobalState, dealUuid: string): IHistory[] {
    return state.deals.histories[dealUuid] && _.values(state.deals.histories[dealUuid]);
}

export function dealNotesSelector(state: IGlobalState, dealUuid: string): INote[] {
    return state.deals.notes[dealUuid] && _.values(state.deals.notes[dealUuid]);
}

export function dealPropertiesSelector(state: IGlobalState, dealUuid: string): IDictionary<IProperty> {
    if (!state.deals.properties || !state.deals.dealPropertyUuids || !state.deals.dealPropertyUuids[dealUuid]) {
        return null;
    }

    const dealProperties: IDictionary<IProperty> = {};
    _.each(state.deals.dealPropertyUuids[dealUuid], (dealPropertyUuid: string) => {
        dealProperties[dealPropertyUuid] = state.deals.properties[dealPropertyUuid];
    });

    return dealProperties;
}

export function dealPropertySelector(state: IGlobalState, propertyUuid: string): IProperty {
    if (!state.deals.properties || !state.deals.properties[propertyUuid]) {
        return null;
    }

    return state.deals.properties[propertyUuid];
}

export function dealPropertyAddressSearchResultsSelector(state: IGlobalState): IAddressSearchResult[] {
    return state.deals.addressSearchResults;
}

export function dealPropertyAddressSearchResultsLoadingSelector(state: IGlobalState): boolean {
    return state.deals.addressSearchResultsLoading;
}

export function dealPropertyGnafAddressDetailLoadingSelector(state: IGlobalState): boolean {
    return state.deals.gnafAddressDetailLoading;
}

export function dealPropertiesPostcodeCategoriesSelector(state: IGlobalState): IDictionary<IPostcodeCategory> {
    return state.deals.postcodeCategories;
}

export function dealPropertiesSpPostcodeCategoriesSelector(state: IGlobalState): IDictionary<ISpPostcodeCategory> {
    return state.deals.spPostcodeCategories;
}

export function dealDocumentSelector(state: IGlobalState, documentUuid: string): IDocument {
    return state.deals.documents[documentUuid];
}
