import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button, Spin, Table, Tooltip, Typography, Upload } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IDocument from '~Api/Investor/IDocument';
import IInvestor from '~Api/Investor/IInvestor';
import {
    investorDocumentsAddAction,
    investorDocumentsListAction,
    investorGetAction,
} from '~Investors/actions';
import {
    investorDocumentsSelector,
    investorSelector,
} from '~Investors/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';
import { ColumnsType } from 'antd/lib/table';

interface IMatch {
    uuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    documents: IDocument[];
    investor: IInvestor;
}

interface IPropsDispatch {
    documentsAdd: (file: File) => void;
    documentsList: () => void;
    investorGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Documents extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.beforeUpload = this.beforeUpload.bind(this);
    }

    public componentDidMount() {
        const { documents, investor } = this.props;

        if (!documents) {
            this.props.documentsList();
        }

        if (!investor) {
            this.props.investorGet();
        }
    }

    public render(): JSX.Element {
        const { documents, investor, match } = this.props;

        if (!documents || !investor) {
            return (
                <Layout uuid={match.params.uuid} section='documents'>
                    <Spin/>
                </Layout>
            );
        }

        const columns: ColumnsType<IDocument> = [
            {
                dataIndex: 'filename',
                title: 'Filename',
            },
            {
                render: (document: IDocument) => {
                    return (
                        <React.Fragment>
                            <Tooltip title='Download'>
                                <Button
                                    type='link'
                                    href={`${process.env.API_HOST}/investor-documents/${document.uuid}/download`}
                                    target='_blank'
                                >
                                    <CloudDownloadOutlined/>
                                </Button>
                            </Tooltip>
                        </React.Fragment>
                    );
                },
                title: 'Actions',
                width: '15%',
            },
        ];

        return (
            <Layout uuid={match.params.uuid} section='documents'>
                <Upload className='add-document' beforeUpload={this.beforeUpload}>
                    <Button>Add Document</Button>
                </Upload>
                <Typography.Title level={2}>Documents</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={documents}
                    pagination={false}
                    rowKey='uuid'
                />
            </Layout>
        );
    }

    private beforeUpload(file: File): boolean {
        this.props.documentsAdd(file);

        return false;
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        documents: investorDocumentsSelector(state, ownProps.match.params.uuid),
        investor: investorSelector(state, ownProps.match.params.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        documentsAdd: (file: File) => dispatch(investorDocumentsAddAction(ownProps.match.params.uuid, file)),
        documentsList: () => dispatch(investorDocumentsListAction(ownProps.match.params.uuid)),
        investorGet: () => dispatch(investorGetAction(ownProps.match.params.uuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Documents);
