import { CheckOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Button, Descriptions, Dropdown, MenuProps, Modal, Space, Spin, Table, Tag, Typography, message } from 'antd';
import { ColumnType } from 'antd/lib/table';
import dayjs from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IDeal from '~Api/Deal/IDeal';
import IQuote from '~Api/Deal/IQuote';
import MortgageTypeEnum from '~Api/Deal/MortgageTypeEnum';
import { authCurrentUserSelector } from '~Auth/selectors';
import {
    leadGetAction,
    leadQuoteCreateApplicationAction,
    leadQuoteSendAction,
    leadQuotesListAction,
} from '~Leads/actions';
import {
    leadQuotesSelector,
    leadSelector,
} from '~Leads/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';
import SendModal from './SendModal';
import ApprovalStatusEnum from '~Api/Deal/QuoteApprovalStatusEnum';
import DealApprovalStatusEnum from '~Api/Deal/ApprovalStatusEnum';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import IAuthUser from '~Auth/IAuthUser';
import {
    dealQuoteApproveAction,
    dealQuoteConvertScenarioAction,
} from '~Deals/actions';
import QuoteRejectModal from './QuoteRejectModal';
import { IDictionary } from '~utilities/IDictionary';

const mortgageTypeLabels: IDictionary<string> = {
    [MortgageTypeEnum.FirstMortgage]: 'First Mortgage',
    [MortgageTypeEnum.SecondMortgage]: 'Second Mortgage',
};

interface IState {
    pendingApplication: string;
    rejectQuoteUuid: string;
    sendQuoteUuid: string;
}

interface IMatch {
    dealUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    currentUser: IAuthUser;
    deal: IDeal;
    quotes: IQuote[];
}

interface IPropsDispatch {
    leadQuoteCreateApplication: (quoteUuid: string) => void;
    leadQuotesList: () => void;
    leadGet: () => void;
    quoteApprove: (quoteUuid: string) => void;
    quoteConvertScenario: (quoteUuid: string, sendEmail: boolean) => void;
    quoteSend: (quoteUuid: string) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Quotes extends React.Component<Props, IState> {
    public state: IState = {
        pendingApplication: null,
        rejectQuoteUuid: null,
        sendQuoteUuid: null,
    };

    constructor(props: Props) {
        super(props);

        this.onCreateApplication = this.onCreateApplication.bind(this);
        this.onCancelSendQuote = this.onCancelSendQuote.bind(this);
        this.copyApplicationLinkToClipboard = this.copyApplicationLinkToClipboard.bind(this);
        this.onClickApprove = this.onClickApprove.bind(this);
        this.onClickConvertScenario = this.onClickConvertScenario.bind(this);
        this.onClickConvertAndSendScenario = this.onClickConvertAndSendScenario.bind(this);
        this.onClickReject = this.onClickReject.bind(this);
        this.onCancelRejectQuote = this.onCancelRejectQuote.bind(this);
    }

    public componentDidMount() {
        const { quotes, deal } = this.props;

        if (!deal) {
            this.props.leadGet();
        }

        if (!quotes) {
            this.props.leadQuotesList();
        }
    }

    private copyApplicationLinkToClipboard(linkCode: string) {
        navigator.clipboard.writeText(`${process.env.PUBLIC_FRONTEND_HOST}/quote/${linkCode}`);
        message.success('Application Link Copied');
    }

    public render(): JSX.Element {
        const { currentUser, deal, match, quotes } = this.props;
        const { pendingApplication, rejectQuoteUuid, sendQuoteUuid } = this.state;

        if (!deal || !quotes) {
            return (
                <Layout dealUuid={match.params.dealUuid} section='quotes'>
                    <Spin/>
                </Layout>
            );
        }

        const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const percentageFormatter: Intl.NumberFormat = Intl.NumberFormat('en-AU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            style: 'percent',
        });

        const columns: ColumnType<IQuote>[] = [
            {
                dataIndex: 'createdTime',
                defaultSortOrder: 'descend',
                render: (createdTime: string) => dayjs(createdTime).format('DD/MM/YYYY HH:mm'),
                sorter: (a: IQuote, b: IQuote) => dayjs(a.createdTime) > dayjs(b.createdTime) ? 1 : -1,
                title: 'Date',
                width: '15%',
            },
            {
                render: (quote: IQuote) => {
                    let statusTag: JSX.Element = null;

                    switch (quote.approvalStatus) {
                        case ApprovalStatusEnum.Approved:
                            statusTag = <Tag color='green'>Approved</Tag>;
                            break;
                        case ApprovalStatusEnum.Draft:
                            statusTag = <Tag color='orange'>Draft</Tag>;
                            break;
                        case ApprovalStatusEnum.Rejected:
                            statusTag = <Tag color='red'>Rejected</Tag>;
                            break;
                    }

                    return (
                        <Space>{quote.code} {statusTag}</Space>
                    );
                },
                title: 'Code',
            },
            {
                dataIndex: 'loanAmount',
                render: (loanAmount: number) => currencyFormatter.format(loanAmount),
                title: 'Loan Amount',
                width: '15%',
            },
            {
                dataIndex: 'netBalance',
                render: (netBalance: number) => currencyFormatter.format(netBalance),
                title: 'Net Balance',
                width: '15%',
            },
            {
                dataIndex: 'termMonths',
                render: (termMonths: number) => `${termMonths} months`,
                title: 'Term',
                width: '15%',
            },
            {
                dataIndex: 'interestRate',
                render: (interestRate: number) => percentageFormatter.format(interestRate / 100),
                title: 'Interest Rate',
                width: '5%',
            },
            {
                render: (quote: IQuote) => {
                    const onClickConvertAndSendScenario: () => void = () => this.onClickConvertAndSendScenario(quote.uuid);

                    const scenarioMenuItems: MenuProps = {
                        items: [
                            {
                                key: 'approve',
                                label: 'Approve',
                                onClick: () => this.onClickApprove(quote.uuid),
                            },
                            {
                                key: 'reject',
                                label: 'Reject',
                                onClick: () => this.onClickReject(quote.uuid),
                            },
                        ],
                    };

                    const quoteMenuItems: MenuProps = {
                        items: [
                            {
                                key: 'send',
                                label: 'Send',
                                onClick: () => this.onSendQuote(quote.uuid),
                            },
                            {
                                key: 'send-to',
                                label: 'Send To',
                                onClick: () => this.setState({ sendQuoteUuid: quote.uuid }),
                            },
                            {
                                key: 'copy-application-link',
                                label: 'Copy Application Link',
                                onClick: () => this.copyApplicationLinkToClipboard(quote.linkCode),
                            },
                            ...(!deal.applicationUuid ? [
                                {
                                    disabled: !!pendingApplication,
                                    key: 'create-application',
                                    label: 'Create Application',
                                    onClick: () => this.onCreateApplication(quote.uuid),
                                },
                            ] : []),
                        ],
                    };

                    const convertMenuItems: MenuProps = {
                        items: [
                            {
                                key: 'convert',
                                label: 'Convert',
                                onClick: () => this.onClickConvertScenario(quote.uuid),
                            },
                        ],
                    };

                    const icon: JSX.Element = deal.quoteUuid === quote.uuid ? <CheckOutlined/> : <EllipsisOutlined/>;

                    const viewPdfLink: JSX.Element = <a href={`${process.env.API_HOST}/deal-quotes/${quote.uuid}/pdf`} target='_blank' rel='noreferrer'>View PDF</a>;

                    const draftButton: JSX.Element = quote.approvalStatus === ApprovalStatusEnum.Draft && (currentUser.permissions.includes(PermissionsEnum.DealQuoteApproval) ? <Dropdown.Button icon={icon} menu={scenarioMenuItems}>{viewPdfLink}</Dropdown.Button> : <Button>{viewPdfLink}</Button>);

                    const convertButton: JSX.Element = quote.approvalStatus === ApprovalStatusEnum.Approved && [DealApprovalStatusEnum.InitialCall, DealApprovalStatusEnum.UncontactableFollowUp1, DealApprovalStatusEnum.UncontactableFollowUp2, DealApprovalStatusEnum.UncontactableFollowUp3, DealApprovalStatusEnum.Quote, DealApprovalStatusEnum.FollowUp1, DealApprovalStatusEnum.FollowUp2, DealApprovalStatusEnum.FollowUp3].includes(deal.approvalStatus) && <Dropdown.Button icon={icon} menu={convertMenuItems} onClick={onClickConvertAndSendScenario} type='primary'>Convert + Email</Dropdown.Button>;

                    return (
                        <Space>
                            {draftButton}
                            {quote.approvalStatus === ApprovalStatusEnum.Rejected && <Button type='dashed'>{viewPdfLink}</Button>}
                            {convertButton}
                            {(quote.approvalStatus === null || quote.approvalStatus === ApprovalStatusEnum.Quote) && <Dropdown.Button  icon={icon} menu={quoteMenuItems}>{viewPdfLink}</Dropdown.Button>}
                            <SendModal
                                isOpen={sendQuoteUuid === quote.uuid}
                                deal={deal}
                                onCancel={this.onCancelSendQuote}
                                quote={quote}
                            />
                            <QuoteRejectModal
                                isOpen={rejectQuoteUuid === quote.uuid}
                                onCancel={this.onCancelRejectQuote}
                                quoteUuid={rejectQuoteUuid}
                            />
                        </Space>
                    );
                },
                width: '10%',
            },
        ];

        return (
            <Layout dealUuid={match.params.dealUuid} section='quotes'>
                <Typography.Title level={2}>Quotes</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={quotes}
                    expandedRowRender={this.expandedRowRender}
                    pagination={false}
                    rowKey='uuid'
                />
            </Layout>
        );
    }

    private expandedRowRender(quote: IQuote): JSX.Element {
        const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const percentageFormatter: Intl.NumberFormat = Intl.NumberFormat('en-AU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            style: 'percent',
        });

        return (
            <Descriptions column={4}>
                <Descriptions.Item label='Property Value'>{currencyFormatter.format(quote.propertyValue)}</Descriptions.Item>
                <Descriptions.Item label='Current Debt'>{currencyFormatter.format(quote.currentDebt)}</Descriptions.Item>
                <Descriptions.Item label='LVR'>{percentageFormatter.format(quote.lvr / 100)}</Descriptions.Item>
                <Descriptions.Item label='Mortgage Type'>{quote.mortgageType ? mortgageTypeLabels[quote.mortgageType] : '-'}</Descriptions.Item>
                <Descriptions.Item label='Commitment Fee'>{currencyFormatter.format(quote.commitmentFee)}</Descriptions.Item>
                <Descriptions.Item label='Establishment Fee'>{currencyFormatter.format(quote.establishmentFee)}</Descriptions.Item>
                <Descriptions.Item label='Application Fee'>{currencyFormatter.format(quote.applicationFee)} + GST</Descriptions.Item>
                <Descriptions.Item label='Brokerage Fee'>{currencyFormatter.format(quote.brokerageFee)} + GST</Descriptions.Item>
                <Descriptions.Item label='Legal Fees'>{currencyFormatter.format(quote.legalFees)} + GST</Descriptions.Item>
                <Descriptions.Item label='Estimated Outlays'>{currencyFormatter.format(quote.estimatedOutlays)}</Descriptions.Item>
            </Descriptions>
        );
    }

    private onCreateApplication(quoteUuid: string) {
        Modal.confirm({
            content: 'Are you sure you want to create an application from this quote?',
            okText: 'Create',
            onOk: () => {
                this.setState({
                    pendingApplication: quoteUuid,
                });
                this.props.leadQuoteCreateApplication(quoteUuid);
            },
            title: 'Create Application',
        });
    }

    private onSendQuote(quoteUuid: string) {
        Modal.confirm({
            content: 'Are you sure you want to send this quote?',
            okText: 'Send',
            onOk: () => {
                this.props.quoteSend(quoteUuid);
            },
            title: 'Send Quote',
        });
    }

    private onCancelSendQuote() {
        this.setState({
            sendQuoteUuid: null,
        });
    }

    private onClickApprove(quoteUuid: string) {
        Modal.confirm({
            content: 'Are you sure you want to approve this quote?',
            okText: 'Approve',
            onOk: () => {
                this.props.quoteApprove(quoteUuid);
            },
            title: 'Approve Quote',
        });
    }

    private onClickConvertScenario(quoteUuid: string) {
        this.props.quoteConvertScenario(quoteUuid, false);
    }

    private onClickConvertAndSendScenario(quoteUuid: string) {
        this.props.quoteConvertScenario(quoteUuid, true);
    }

    private onClickReject(quoteUuid: string) {
        this.setState({
            rejectQuoteUuid: quoteUuid,
        });
    }

    private onCancelRejectQuote() {
        this.setState({
            rejectQuoteUuid: null,
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        currentUser: authCurrentUserSelector(state),
        deal: leadSelector(state, ownProps.match.params.dealUuid),
        quotes: leadQuotesSelector(state, ownProps.match.params.dealUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        leadGet: () => dispatch(leadGetAction(ownProps.match.params.dealUuid)),
        leadQuoteCreateApplication: (quoteUuid: string) => dispatch(leadQuoteCreateApplicationAction(ownProps.match.params.dealUuid, quoteUuid)),
        leadQuotesList: () => dispatch(leadQuotesListAction(ownProps.match.params.dealUuid)),
        quoteApprove: (quoteUuid: string) => dispatch(dealQuoteApproveAction(quoteUuid)),
        quoteConvertScenario: (quoteUuid: string, sendEmail: boolean) => dispatch(dealQuoteConvertScenarioAction(quoteUuid, sendEmail)),
        quoteSend: (quoteUuid: string) => dispatch(leadQuoteSendAction(ownProps.match.params.dealUuid, quoteUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Quotes);
