import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import Layout from '~Layout';
import ApplicationsTimeInStatusReport from '~Reports/ApplicationsTimeInStatusReport';
import LeadsExport from '~Reports/LeadsExport';
import LeadsTimeInStatusReport from '~Reports/LeadsTimeInStatusReport';
import BrokersExport from './BrokersExport';
import InvestorsFinRegister from './InvestorsFinRegister';
import InvestorsFitCashTransactions from './InvestorsFitCashTransactions';
import InvestorsFitInvestmentBalances from './InvestorsFitInvestmentBalances';
import InvestorsTfn from './InvestorsTfn';
import List from './List';
import LoansBorrowers from './LoansBorrowers';
import LoansExport from './LoansExport';
import LoansPayments from './LoansPayments';
import LoansPoolCut from './LoansPoolCut';
import LoansProperties from './LoansProperties';
import WarehousesLoanBalances from './WarehousesLoanBalances';
import InvestorsFitCashBalances from './InvestorsFitCashBalances';
import InvestmentsExport from './InvestmentsExport';
import WarehousesFwt2PoolCut from './WarehouseFwt2PoolCut';
import LoansDrawdownBalances from './LoansDrawdownBalances';

export default function Router(): ReactElement {
    const currentUser: IAuthUser = useSelector(authCurrentUserSelector);

    return (
        <Layout topSection='reports'>
            <Switch>
                {currentUser.permissions.includes(PermissionsEnum.Reports) && <Route exact={true} path='/reports' component={List} />}
                {currentUser.permissions.includes(PermissionsEnum.ReportsApplicationsTimeInStatus) && <Route exact={true} path='/reports/applications-time-in-status' component={ApplicationsTimeInStatusReport} />}
                {currentUser.permissions.includes(PermissionsEnum.ReportsBrokersExport) && <Route exact={true} path='/reports/brokers-export' component={BrokersExport} />}
                {currentUser.permissions.includes(PermissionsEnum.ReportsInvestmentsExport) && <Route exact={true} path='/reports/investments-export' component={InvestmentsExport} />}
                {currentUser.permissions.includes(PermissionsEnum.ReportsInvestorsFinRegister) && <Route exact={true} path='/reports/investors-fin-register' component={InvestorsFinRegister} />}
                {currentUser.permissions.includes(PermissionsEnum.ReportsInvestorsFitCashBalances) && <Route exact={true} path='/reports/investors-fit-cash-balances' component={InvestorsFitCashBalances} />}
                {currentUser.permissions.includes(PermissionsEnum.ReportsInvestorsFitCashTransactions) && <Route exact={true} path='/reports/investors-fit-cash-transactions' component={InvestorsFitCashTransactions} />}
                {currentUser.permissions.includes(PermissionsEnum.ReportsInvestorsFitInvestmentBalances) && <Route exact={true} path='/reports/investors-fit-investment-balances' component={InvestorsFitInvestmentBalances} />}
                {currentUser.permissions.includes(PermissionsEnum.ReportsInvestorsTfn) && <Route exact={true} path='/reports/investors-tfn' component={InvestorsTfn} />}
                {currentUser.permissions.includes(PermissionsEnum.ReportsLeadsExport) && <Route exact={true} path='/reports/leads-export' component={LeadsExport} />}
                {currentUser.permissions.includes(PermissionsEnum.ReportsLeadsTimeInStatus) && <Route exact={true} path='/reports/leads-time-in-status' component={LeadsTimeInStatusReport} />}
                {currentUser.permissions.includes(PermissionsEnum.ReportsLoansExport) && <Route exact={true} path='/reports/loans-export' component={LoansExport} />}
                {currentUser.permissions.includes(PermissionsEnum.ReportsLoansBorrowers) && <Route exact={true} path='/reports/loans-borrower-export' component={LoansBorrowers} />}
                {currentUser.permissions.includes(PermissionsEnum.ReportsLoansDrawdownBalances) && <Route exact={true} path='/reports/loans-drawdown-balances' component={LoansDrawdownBalances} />}
                {currentUser.permissions.includes(PermissionsEnum.ReportsLoansPayments) && <Route exact={true} path='/reports/loans-payments' component={LoansPayments} />}
                {currentUser.permissions.includes(PermissionsEnum.ReportsLoansPoolCut) && <Route exact={true} path='/reports/loans-pool-cut' component={LoansPoolCut} />}
                {currentUser.permissions.includes(PermissionsEnum.ReportsLoansProperties) && <Route exact={true} path='/reports/loans-property-export' component={LoansProperties} />}
                {currentUser.permissions.includes(PermissionsEnum.ReportsWarehouseFwt2PoolCut) && <Route exact={true} path='/reports/warehouses-fwt2-pool-cut' component={WarehousesFwt2PoolCut} />}
                {currentUser.permissions.includes(PermissionsEnum.ReportsWarehouseLoanBalances) && <Route exact={true} path='/reports/warehouses-loan-balances' component={WarehousesLoanBalances} />}
            </Switch>
        </Layout>
    );
}
