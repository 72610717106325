import { Button, Modal, Space, Spin, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import _ from 'lodash';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import IAnnualStatement from '~Api/Investor/IAnnualStatement';
import IInvestor from '~Api/Investor/IInvestor';
import { authCurrentUserSelector } from '~Auth/selectors';
import {
    investorAccountAnnualStatementsListAction,
    investorAnnualStatementDeleteAction,
    investorGetAction,
} from '~Investors/actions';
import {
    investorAccountAnnualStatementsSelector,
    investorSelector,
} from '~Investors/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import AnnualStatementAddModal from './AnnualStatementAddModal';
import Layout from './Layout';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import IAuthUser from '~Auth/IAuthUser';

export default function AnnualStatements(): ReactElement {
    const { accountUuid, investorUuid } = useParams<{ accountUuid: string, investorUuid: string }>();

    const [ showAddStatementModal, setShowAddStatementModal ] = useState<boolean>(false);

    const annualStatements: IDictionary<IAnnualStatement> = useSelector((state: IGlobalState) => investorAccountAnnualStatementsSelector(state, accountUuid));
    const currentUser: IAuthUser = useSelector(authCurrentUserSelector);
    const investor: IInvestor = useSelector((state: IGlobalState) => investorSelector(state, investorUuid));

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!investor) {
            dispatch(investorGetAction(investorUuid));
        }
    }, [
        dispatch,
        investor,
        investorUuid,
    ]);

    useEffect(() => {
        dispatch(investorAccountAnnualStatementsListAction(accountUuid));
    }, [
        accountUuid,
        dispatch,
    ]);

    const onClickAddStatement: () => void = useCallback(() => {
        setShowAddStatementModal(true);
    }, []);

    const onClickAddStatementCancel: () => void = useCallback(() => {
        setShowAddStatementModal(false);
    }, []);

    if (!annualStatements || !investor) {
        return (
            <Layout uuid={investorUuid} section={`annual-statements-${accountUuid}`} sectionClass='annual-statements'>
                <Typography.Title level={2}>Annual Statements</Typography.Title>
                <Spin/>
            </Layout>
        );
    }

    const columns: ColumnsType<IAnnualStatement> = [
        {
            dataIndex: 'year',
            sorter: (a: IAnnualStatement, b: IAnnualStatement) => a.year.localeCompare(b.year),
            title: 'Year',
        },
        {
            render: (statement: IAnnualStatement) => {
                const onClickDelete: () => void = () => {
                    Modal.confirm({
                        content: `Are you sure you want to delete the statement for ${statement.year}?`,
                        okText: 'Delete',
                        okType: 'danger',
                        onOk: () => {
                            dispatch(investorAnnualStatementDeleteAction(statement.uuid));
                        },
                        title: 'Delete',
                    });
                };

                return (
                    <Space>
                        <Button href={`${process.env.API_HOST}/investor-annual-statements/${statement.uuid}/download`} target='_blank'>Download</Button>
                        {currentUser.permissions.includes(PermissionsEnum.Accounts) && <Button onClick={onClickDelete} danger={true}>Delete</Button>}
                    </Space>
                );
            },
            width: '10%',
        },
    ];

    return (
        <Layout uuid={investorUuid} section={`annual-statements-${accountUuid}`} sectionClass='annual-statements'>
            <Space className='actions'>
                {currentUser.permissions.includes(PermissionsEnum.Accounts) && <Button onClick={onClickAddStatement}>Upload Statement</Button>}
                {currentUser.permissions.includes(PermissionsEnum.Accounts) && <Button href={`${process.env.API_HOST}/investor-accounts/${accountUuid}/draft-annual-statement`} target='_blank'>Download Draft</Button>}
            </Space>
            <Typography.Title level={2}>Annual Statements</Typography.Title>
            <Table
                columns={columns}
                dataSource={_.values(annualStatements)}
                pagination={false}
                rowKey='uuid'
                size='middle'
            />
            <AnnualStatementAddModal
                accountUuid={accountUuid}
                investorUuid={investorUuid}
                isOpen={showAddStatementModal}
                onClose={onClickAddStatementCancel}
            />
        </Layout>
    );
}
